import React, { useState, useEffect } from "react";
import Tabs from "./tabs";
import PersonalDetailsForm from "./personalDetailsForm";
import AddressForm from "./addressForm";
import OtherDetailsForm from "./otherDetailsForm";
import UploadsForm from "./uploadsForm";
import PreviewForm from "./previewForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import authActions from "../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";

function Index() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [searchParams] = useSearchParams();
  const Page = searchParams.get("page");
  const [registrationSection, setRegistrationSection] =
    useState("personaldetails");

  useEffect(() => {
    if (Page) {
      const currentActiveTab =
        (Page === "personaldetails") ? 0 :
          Page === "address" ? 1 :
            Page === "educationdetails" ? 2 :
              Page === "uploads" ? 3 :
              Page === "preview" ? 4 :
                null;

      if (currentActiveTab !== null) {
        setActiveTab(currentActiveTab);
      }
      setRegistrationSection(Page);
    } else {
      navigate('/home?page=personaldetails')
    }
    //eslint-disable-next-line
  }, [Page]);

  const { candidateTokenDetails, profileRegistrationActiveTab } = useSelector(state => state.authReducer);

  // useEffect(() => {
  //   if (candidateTokenDetails?.candidateid) {
  //     dispatch({ type: authActions.GET_PROFILE_REGISTRATION_ACTIVE_TAB, payload: { id: candidateTokenDetails?.candidateid } })
  //   }
  //   //eslint-disable-next-line
  // }, [candidateTokenDetails]);

  const RegistrationData = [
    { title: "Personal Details", 
      to: `/home?page=personaldetails`},
    { title: "Address", to: `/home?page=address`},
    { title: "Education Details", to: `/home?page=educationdetails` },
    { title: "Uploads", to: `/home?page=uploads` },
    { title: "Preview", to: `/home?page=preview` },
  ];

  useEffect(() => {
    if (!profileRegistrationActiveTab) {
      return;
    } else if (profileRegistrationActiveTab && profileRegistrationActiveTab === 'new') {
      navigate(`/home?page=personaldetails`);
    } else {
      if (!profileRegistrationActiveTab?.cu1_status && profileRegistrationActiveTab?.other_status === 2) {
        navigate(`/home?page=uploads`);
      } else if (!profileRegistrationActiveTab?.cu1_status && !profileRegistrationActiveTab?.other_status && profileRegistrationActiveTab?.address_status === 2) {
        navigate(`/home?page=educationdetails`);
      } else if (!profileRegistrationActiveTab?.cu1_status && !profileRegistrationActiveTab?.other_status && !profileRegistrationActiveTab?.address_status && profileRegistrationActiveTab?.candidate_status === 2) {
        navigate(`/home?page=address`);
      } else if (!profileRegistrationActiveTab?.cu1_status && !profileRegistrationActiveTab?.other_status && !profileRegistrationActiveTab?.address_status && !profileRegistrationActiveTab?.candidate_status) {
        navigate(`/home?page=personaldetails`);
      } else if (!profileRegistrationActiveTab?.cu1_status && !profileRegistrationActiveTab?.other_status && !profileRegistrationActiveTab?.address_status && !profileRegistrationActiveTab?.candidate_status) {
        navigate(`/home?page=preview`);
      };
    };
    //eslint-disable-next-line
  }, [profileRegistrationActiveTab]);

  const preprocessFormData = (data) => {
    const processedData = { ...data };
    for (const key in processedData) {
      if (processedData.hasOwnProperty(key)) {
        if (
          typeof processedData[key] === "string" &&
          (processedData[key].trim() === "" ||
            processedData[key].toLowerCase() === "null")
        ) {
          processedData[key] = null;
        }
      }
    }
    return processedData;
  };

  return (
    <>
      <Tabs activeTab={activeTab} RegistrationData={RegistrationData} profileRegistrationActiveTab={profileRegistrationActiveTab} />
      {registrationSection === "personaldetails" && (
        <PersonalDetailsForm
          preprocessFormData={preprocessFormData}
        />
      )}
      {registrationSection === "address" && (
        <AddressForm
          preprocessFormData={preprocessFormData}
        />
      )}
      {registrationSection === "educationdetails" && (
        <OtherDetailsForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "uploads" && (
        <UploadsForm preprocessFormData={preprocessFormData} />
      )}
      {registrationSection === "preview" && (
        <PreviewForm preprocessFormData={preprocessFormData} />
      )}
       
    </>
  );
}

export default Index;