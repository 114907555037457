import React from 'react'
import vibSchlrLogo from '../assets/images/landinPage/vibgyor_schollorship_logo.png'
import hexagonImg from '../assets/images/landinPage/hexagon.png'
import studentsImg from '../assets/images/landinPage/Students.png'
import ellipseImg from '../assets/images/landinPage/Ellipse 1@2x.png'
import noticeImgSmall from '../assets/images/landinPage/noticeSmall.png'
import noticeImgBig from '../assets/images/landinPage/noticeBoardBig.png'
import rectangle1 from '../assets/images/landinPage/Rectangle 5.png'
import rectangle2 from '../assets/images/landinPage/Rectangle 6.png'
import faqImg from '../assets/images/landinPage/image 6.png'
import fbImg from '../assets/images/landinPage/FB.png'
import XImg from '../assets/images/landinPage/X.png'
import InstaImg from '../assets/images/landinPage/INSTA.png'
import LinkedImg from '../assets/images/landinPage/lINKEDIN.png'
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
export const LandingPage = () => {

const navigate =useNavigate();

  return (
<>   
<Grid sx={{backgroundColor:"#ffff"}}>

<Grid container px={5} py={1} bgcolor={"#F2F2FC"} >
        
    <Grid item xs={6} sm={6} md={6} lg={6} >
<Box  sx={{width:{lg:'130px',md:'130px',sm:"130px",xs:"60px"}}}>

<img src={vibSchlrLogo} style={{width:"100%"}}/>
</Box>
    </Grid >
    <Grid item xs={6} sm={6} md={6} lg={6} 
    display={"flex"} justifyContent={"flex-end"} gap={2} alignItems={"center"}
    //  width={"222px"} height={"40px"}
     >
    <Box sx={{
        display: "inline-block",
        padding: "2px", // Space for the border
        borderRadius: "8px", // Optional: Add rounded corners
        background: "linear-gradient(90deg, #3F41D1, #9FA1FF)", // Gradient for the border
        position: "relative"
    }} onClick={()=> navigate('/login')}>

            <Box 
            sx={{
      display: "inline-block",
      fontSize:{xs:"10px",md:"14px",sm:"14px",lg:"14px"},
      padding: "10px 18px", // Adjust for inner padding
      background: "#ffffff",
      color:"#3F41D1" ,// Background of the inner content
      fontFamily: "Graphik",
      fontWeight: "500",
      borderRadius: "6px", // Inherit border radius to match the outer box
      cursor:"pointer"
    
    }}>LOGIN</Box>
            </Box>
        <Box
        sx={{
            padding:'12px 20px',
    fontFamily: "Graphik",
    fontWeight: "500",
    fontSize:{xs:"10px",md:"14px",sm:"14px",lg:"14px"},
    backgroundImage:"linear-gradient(90deg, #3F41D1, #9FA1FF)",
    color:"#FFFFFF",
    borderRadius:"8px",textWrap:"nowrap",
    cursor:"pointer"
  }}
  onClick={()=> navigate('/signup')}
  >REGISTER NOW</Box>
    </Grid>
</Grid>





<Grid container sx={{px:5,py:1}}>
<Grid item xs={12} sm={12} md={6} lg={6}>

<Box sx={{display:'flex',height:"100%", flexDirection:'column',justifyContent:"center",gap:"24px"}}>

<Box><Typography sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"32px",md:"32px",sm:"30px",xs:"18px"},color:"#413F73"}}>The VIBGYOR Group of Schools,</Typography></Box>
<Box><Typography sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"14px",xs:"14px"},color:"#413F73"}}>Where excellence in academics meets a holistic approach to learner development. Founded in 2004 by an ideal blend of academic and corporate professionals, VIBGYOR today is one of the country's leading educational providers, with 39 schools PAN India.</Typography></Box>
<Box><Typography sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"14px",xs:"14px"},color:"#413F73"}}>Join us at VIBGYOR and help nurture your children's future as innovators and torch bearers
of tomorrow. </Typography></Box>
    </Box>
</Grid>

<Grid item xs={12} sm={12} md={6} lg={6} py={2}>
<Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
    <img style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}} src={hexagonImg}/>
</Box>

</Grid>
</Grid>





<Grid container  bgcolor={"rgba(209, 209, 244, 0.3)"}>
<Grid item xs={12} sm={12} md={6} lg={6} >
<Box sx={{display:"flex",position:"relative",height:"100%",overflow:"hidden"}}>
    <Box sx={{display:"flex",position:"relative",top:"8%"}}>
     <img style={{width:"100%",height:"100%",display:"flex",position:"relative"}} src={ellipseImg}/>
    </Box>
<Box sx={{display:"flex",position:"absolute",bottom:"0%"}}>
     <img style={{width:"100%"}} src={studentsImg}/>
     </Box>
     </Box>
</Grid>

<Grid item xs={12} sm={12} md={6} lg={6} >

<Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",gap:"24px",height:"100%",px:1}}>
<Box><Typography sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"32px",md:"32px",sm:"30px",xs:"18px"},color:"#413F73"}}>Introducing,</Typography></Box>
<Box><Typography sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"32px",md:"32px",sm:"30px",xs:"18px"},color:"#413F73"}}>{`VIBGYOR Intellect Scholarship Exam (VISE)`}</Typography></Box>
<Box><Typography sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"24px",md:"24px",sm:"20px",xs:"14px"},color:"#413F73"}}>An Opportunity to Nurture Future Innovators</Typography></Box>
<Box><Typography sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"12px",xs:"12px"},color:"#413F73"}}>A novel opportunity to honor and reward talent and academic prowess for students currently studying in grades 7 to 10. This scholarship entitles the students to study in the VIBGYOR group of schools with full freeship.</Typography></Box>
<Box><Typography sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"12px",xs:"12px"},color:"#413F73",cursor:"pointer"}}>So,<span style={{color:"#3F41D1",fontWeight:500}} onClick={()=> navigate('/signup')}> REGISTER NOW & BOOK YOUR SEATS </span>or <span style={{color:"#3F41D1",fontWeight:500}} onClick={()=> navigate('/login')}>LOGIN </span>to continue</Typography></Box>
</Box>

</Grid>
</Grid>



<Grid container >

<Grid item xs={12} sm={12} md={6} lg={6}>
    <Box sx={{display:"flex",justifyContent:{xs:"center",sn:"center",md:"flex-end",lg:"flex-end"},py:5,px:5}}>

<Box sx={{minHeight:"100px",maxHeight:"434px",height:{xs:"300px",sm:"434px",md:"434px",lg:"434px"},width:{xs:"300px",sm:"428px",md:"428px",lg:"428px"},display:"flex",justifyContent:"center",alignItems:{md:"center",lg:"center"},pt:{xs:0},backgroundImage:`url(${noticeImgSmall})`,backgroundRepeat:"no-repeat",backgroundSize:"contain"}}>

<Box sx={{minHeight:"100px",maxHeight:"380px",height:{xs:"170px",sm:"260px",md:"280px",lg:"280px"},width:{xs:"196px",sm:"260px",md:"250px",lg:"250px"},mt:{xs:5,sm:5,md:0,lg:0},pt:{xs:2,sm:5,md:0,lg:0},overflowY:"auto",scrollbarWidth:"thin"}}>


<Box  sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"24px",md:"24px",sm:"20px",xs:"10px"},color:"#413F73",lineHeight:{xs:"10px",sm:"26px",md:"26px",lg:"22px"},mt:{md:2,lg:2}}}>  Eligibility Criteria </Box>
<Box  sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"14px",xs:"10px"},color:"#413F73",lineHeight:{xs:"28px",sm:"28px",md:"28px",lg:"22px"},mt:{xs:2,md:2,lg:2}}}>  Eligibility details- </Box>
<Box  sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"14px",xs:"10px"},color:"#413F73",lineHeight:{xs:"28px",sm:"28px",md:"28px",lg:"22px"},mt:{md:2,lg:2}}}>  Grades 7 to 10 </Box>
<Box  sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"14px",xs:"10px"},color:"#413F73",lineHeight:{xs:"28px",sm:"28px",md:"28px",lg:"22px"},mt:{md:2,lg:2}}}>  across all educational boards in  </Box>
<Box  sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"14px",xs:"10px"},color:"#413F73",lineHeight:{xs:"28px",sm:"28px",md:"28px",lg:"22px"},mt:{md:2,lg:2}}}>  India. </Box>


</Box>



</Box>
   
    </Box>
</Grid>

<Grid item xs={12} sm={12} md={6} lg={6}>
    <Box sx={{display:"flex",justifyContent:{xs:"center",sn:"center",md:"flex-start",lg:"flex-start"},py:5,px:5}}>

<Box sx={{minHeight:"100px",maxHeight:"434px",height:{xs:"300px",sm:"434px",md:"434px",lg:"434px"},width:{xs:"300px",sm:"428px",md:"428px",lg:"428px"},display:"flex",justifyContent:"center",alignItems:{md:"center",lg:"center"},pt:{xs:0},backgroundImage:`url(${noticeImgSmall})`,backgroundRepeat:"no-repeat",backgroundSize:"contain"}}>

<Box sx={{minHeight:"100px",maxHeight:"280px",height:{xs:"170px",sm:"280px",md:"280px",lg:"280px"},width:{xs:"196px",sm:"250px",md:"250px",lg:"250px"},mt:{xs:5,sm:5,md:0,lg:0},pt:{xs:2,sm:5,md:0,lg:0},overflowY:"auto",scrollbarWidth:"thin",backgroundColor:""}}>


<Box  sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"24px",md:"24px",sm:"20px",xs:"10px"},color:"#413F73",lineHeight:{xs:"10px",md:"26px",lg:"22px"},mt:{md:2,lg:2}}}>  Assessment Rounds </Box>
<Box  sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"10px"},color:"#413F73",lineHeight:{sm:"19px",md:"28px",lg:"28px"},marginLeft:{xs:"7%",sm:"6%",md:"6%",lg:"6%"},mt:2}} > <ul style={{display:"flex",flexDirection:"column",gap:"10px"}}> 
<li style={{lineHeight:{xs:"10px",sm:"26px",md:"26px",lg:"26px"}}}>General Round: Online</li>
<li style={{lineHeight:{xs:"10px",sm:"26px",md:"26px",lg:"26px"}}}>Challenger Round: Online</li>
<li style={{lineHeight:{xs:"10px",sm:"26px",md:"26px",lg:"26px"}}}>Group Discussion/Personal Interview: Face to Face</li> 
    </ul></Box>

</Box>



</Box>
   
    </Box>
</Grid>







<Grid item xs={12} sm={12} md={12} lg={12}>
    <Box sx={{display:"flex",justifyContent:"center",py:5,px:5}}>

<Box sx={{minHeight:"100px",maxHeight:"512px",height:{xs:"300px",sm:"434px",md:"512px",lg:"512px"},width:{xs:"300px",sm:"428px",md:"944px",lg:"944px"},display:"flex",justifyContent:"center",alignItems:{md:"center",lg:"center"},pt:{xs:5},backgroundImage:{xs:`url(${noticeImgSmall})`,sm:`url(${noticeImgSmall})`,md:`url(${noticeImgBig})`,lg:`url(${noticeImgBig})`},backgroundRepeat:"no-repeat",backgroundSize:"contain"}}>
  <Box sx={{minHeight:"100px",maxHeight:"380px",height:{xs:"170px",sm:"260px",md:"380px",lg:"380px"},width:{xs:"196px",sm:"260px",md:"650px",lg:"650px"},backgroundColor:"",pt:{xs:2,sm:5,md:0,lg:0},overflowY:"auto",scrollbarWidth:"thin"}}>

     <Box  sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"24px",md:"24px",sm:"20px",xs:"10px"},color:"#413F73",lineHeight:{xs:"10px",md:"26px",lg:"26px"},mt:{md:2,lg:2}}}>  Examination Pattern </Box>
   <Box  sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"10px"},color:"#413F73",lineHeight:{sm:"19px",md:"28px",lg:"28px"},marginLeft:{xs:"7%",sm:"6%",md:"3%",lg:"3%"},mt:2}} > <ul>
    <li>In the first two rounds, the registered participant will attempt 60 multiple choice questions.</li>
    <li>These questions require learners to select a single correct response from a list of four alternatives provided. </li>
<li>Each question carries 1 mark with the total number of 60 questions and with a total of 60 marks. </li>
<li>There will be no negative marking on this test. </li>
<li>The time duration for each examination will be 1 hour (60 minutes).</li>
    </ul></Box>

    </Box>
</Box>
    </Box>
</Grid>



</Grid>

<Grid container sx={{backgroundColor:"rgba(209, 209, 244, 0.3)"}} py={1} pl={5}>

<Grid item  lg={12} md={12} sm={12} xs={12} >

<Box sx={{display:"flex",position:"relative"}}>



<Box sx={{disply:"flex",flexDirection:"column",zIndex:1}}>
<Box  sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"32px",md:"32px",sm:"30px",xs:"15px"},color:"#413F73",lineHeight:{xs:"10px",md:"26px",lg:"35px"},mt:{xs:2,sm:2,md:2,lg:2}}}>
    Registration Details</Box>
<Box sx={{mt:{xs:1,sm:2,md:2,lg:2}}}>
    <Box sx={{lineHeight:{lg:'28px'},color:"#413F73",fontweight:400,fontSize:{lg:"24px",md:"24px",sm:"20px",xs:"14px"},color:"#413F73",fontFamily:"Graphik"}}>Fees :</Box>
    <Box sx={{lineHeight:{lg:'28px'},color:"#413F73",fontweight:400,fontSize:{lg:"16px",md:"16px",sm:"12px",xs:"12px"},fontFamily:"Graphik",mt:{xs:1,sm:2,md:2,lg:2}}}><ul style={{marginLeft:"4%"}}>
        <li>Rs 750/- valid for all the three rounds (for Indian students)</li>
        </ul></Box>
</Box>
<Box sx={{mt:{xs:1,sm:2,md:2,lg:2}}}>
    <Box sx={{lineHeight:{lg:'28px'},color:"#413F73",fontweight:400,fontSize:{lg:"24px",md:"24px",sm:"20px",xs:"14px"},fontFamily:"Graphik"}}>Mode of Examination:</Box>
    <Box sx={{lineHeight:{lg:'28px'},color:"#413F73",fontweight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"10px"},fontFamily:"Graphik",mt:{xs:1,sm:2,md:2,lg:2}}}><ul style={{marginLeft:"4%",display:"flex",flexDirection:"column",gap:"10px"}}>
        <li>The examination for the General and Challenger round will only be conducted online.</li>
        <li>Group discussion/Personal Interview will be face to face and will be conducted in designated schools across the cities.</li>
        <li>Username with password will be available on the registered candidates e-hall ticket.</li>
        <li>The Hall Ticket will be available for download at the registered email Id</li>
        </ul></Box>
</Box>
<Box sx={{mt:{xs:1,sm:2,md:2,lg:2}}}>
    <Box sx={{lineHeight:{lg:'28px'},color:"#413F73",fontweight:500,fontSize:{lg:"14px",md:"14px",sm:"12px",xs:"12px"},fontFamily:"Graphik"}}>Note: *Only applicable for Schools tuition fees:</Box>
</Box>

<Box
        sx={{mt:{xs:1,sm:2,md:2,lg:2},
            display:"inline-block",
            padding:'12px 20px',
    fontFamily: "Graphik",
    fontWeight: "500",
    fontSize:{xs:"10px",md:"14px",sm:"14px",lg:"14px"},
    backgroundImage:"linear-gradient(90deg, #3F41D1, #9FA1FF)",
    color:"#FFFFFF",
    borderRadius:"8px",textWrap:"nowrap",cursor:"pointer"
}}
onClick={()=> navigate('/signup')}
>REGISTER NOW</Box>

</Box>


<Box sx={{display:"flex",position:"absolute",right:{lg:0,md:"83px",sm:"60px",xs:"60px"}}}>
    <Box sx={{display:"flex",position:"relative",right:0,height:{xs:"265px",sm:"310px",md:"370px",lg:"476px"},width:{xs:"250px",sm:"310px",md:"370px",lg:"576px"}}}>
<img src={rectangle1} />
    <Box sx={{display:"flex",position:"absolute",right:{lg:0,md:0,sm:0,xs:"-10px"},bottom:0,height:{lg:"90%",md:"90%",sm:"90%",xs:"80%"},width:{lg:"90%",md:"90%",sm:"90%",xs:"80%"}} }>
<img src={rectangle2} />
   </Box>
    </Box>
</Box>




</Box>


</Grid>




</Grid>

<Grid container py={5} px={5}>

<Grid item xs={12} sm={12} md={6} lg={6}>

<Box  sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"32px",md:"32px",sm:"30px",xs:"15px"},color:"#413F73",lineHeight:{xs:"10px",md:"26px",lg:"35px"},mt:{xs:2,sm:2,md:2,lg:2}}}> FAQs</Box>

<Box sx={{display:"flex",flexDirection:"column",}}>
<Box sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"20px",md:"20px",sm:"12px",xs:"12px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2}}}>1. What is the VIBGYOR Intellect Scholarship Exam (VISE)?</Box>
<Box sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"9px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2},pl:3}}>VISE is a scholarship exam for students seeking admission in grades 8 to 11, offering full tuition fee scholarships at VIBGYOR Group of Schools. applicable to schools of the VIBGYOR group having grades 8,9,10,11, and 12</Box>
</Box>
<Box sx={{display:"flex",flexDirection:"column",}}>
<Box sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"20px",md:"20px",sm:"12px",xs:"12px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2}}}>2. Who is eligible to apply for VISE?</Box>
<Box sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"9px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2},pl:3}}>Students currently studying in grades 7 to 10 across all educational boards in India.</Box>
</Box>
<Box sx={{display:"flex",flexDirection:"column",}}>
<Box sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"20px",md:"20px",sm:"12px",xs:"12px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2}}}>3. How do I register for VISE?</Box>
<Box sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"9px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2},pl:3}}>Register online by filling out the registration form on our website and paying the registration fee of Rs 750/-.</Box>
</Box>
<Box sx={{display:"flex",flexDirection:"column",}}>
<Box sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"20px",md:"20px",sm:"12px",xs:"12px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2}}}>4. What is the structure of the examination?</Box>
<Box sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"9px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2},pl:3}}> The exam consists of three rounds: General Round (online), Challenger Round (online), and Group Discussion/Personal Interview (face-to-face).</Box>
</Box>
<Box sx={{display:"flex",flexDirection:"column",}}>
<Box sx={{fontFamily:"Graphik",fontWeight:500,fontSize:{lg:"20px",md:"20px",sm:"12px",xs:"12px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2}}}>5. What are the benefits of winning the VISE Scholarship?</Box>
<Box sx={{fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"16px",md:"16px",sm:"10px",xs:"9px"},color:"#413F73",lineHeight:{xs:"15px",sm:"20px",md:"26px",lg:"28px"},mt:{xs:2,sm:2,md:2,lg:2},pl:3}}>Winners receive a full tuition fee scholarship at VIBGYOR schools, providing opportunities for holistic development and academic excellence.</Box>
</Box>

</Grid>
<Grid item xs={12} sm={12} md={6} lg={6}>
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"100%"}}>
<Box sx={{height:"210px",width:"462px"}}>
<img style={{height:"100%",width:"100%"}} src={faqImg}/>
</Box>
    </Box>
</Grid>

</Grid>

<Grid container  sx={{bgcolor:"#B2B3ED",py:5}}>


<Grid item xs={12} sm={12} md={4} lg={4} >

<Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"100%"}} >
<Box sx={{height:"53px",width:"138px"}}>

<Box sx={{fontFamily:"Graphik",textAlign:"center",fontWeight:600,fontSize:{lg:"16px",md:"16px",sm:"12px",xs:"12px"},color:"#413F73"}}>
FOLLOW US 
</Box>
<Box sx={{display:"flex",width:"138px",justifyContent:"space-between",alignItems:"center",pt:1}}>
    <Box><img src={fbImg}/></Box>
    <Box><img src={XImg}/></Box>
    <Box><img src={LinkedImg}/></Box>
    <Box><img src={InstaImg}/></Box>
</Box>

</Box>

</Box>
</Grid>



<Grid item xs={12} sm={12} md={4} lg={4}>
<Box sx={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>

<Box sx={{height:"101px",width:"208px"}}>
<img style={{width:"100%",height:"100%"}} src={vibSchlrLogo}/>

</Box>

</Box>
</Grid>
<Grid item xs={12} sm={12} md={4} lg={4}>

<Box sx={{height:"100%",display:"flex",justifyContent:"center",alignItems:"center",fontFamily:"Graphik",fontWeight:400,fontSize:{lg:"12px",md:"12px",sm:"12px",xs:"12px"},color:"#413F73"}}>
<Box sx={{width:"220px"}}>

 @Copyright 2024 - All Rights Reserved
</Box>
</Box>
</Grid>
</Grid>

</Grid>
</>
)
}