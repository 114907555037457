import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Box,
  Checkbox,
  Grid,
  Link,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import photoImage from "../../assets/images/courseRegistration/photo.jpg";
import signatureImage from "../../assets/images/courseRegistration/signature.png";
import { useDispatch, useSelector } from "react-redux";
// import SubmitButton from "../../common/submitButton";
import { useLocation, useNavigate } from "react-router-dom";
import candidatesActions from "../../redux/candidates/actions";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import SubmitButton from "../../common/button/submitButton";
import dayjs from "dayjs";
import { API_URL, BILLDESK_ORDER_URL, BILLDESK_URL, FRONTEND_URL } from "../../utils/constants";
import SaveButton from "../../common/button/saveButton";
import BackButton from "../../common/button/backButton";
import CancelButton from "../../common/button/cancelButton";

export default function PreviewForm({ targetRef }) {
  const [CheckboxValue, setCheckBoxValue] = useState(false);
  const[referralCodeInpValue,setReferralCodeInpValue] = useState('')
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
const [ipAddress,setIpAddress] = useState('');



// console.log('referralCodeInpValue----',referralCodeInpValue);
  const { FullCandidateDetails, personalDetails,referralCode } = useSelector(
    (state) => state.candidatesReducer
  );
  

  // console.log("referralCode--------", referralCode == null);
  // console.log("referralCode invalid--------", referralCode == 'invalid');
  // console.log("FullCandidateDetails", FullCandidateDetails);
  // console.log("personalDetails", personalDetails);
  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const { uploadDetails } = useSelector((state) => state.candidatesReducer);
 
  const candidateuploadid = uploadDetails[2]?.candidateuploadid;

  function base64ToFile(base64String, fileName) {
    if (!base64String) {
      return null;
    }
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], fileName, { type: mimeString });
  }
  const handleView = () => {
    const operation = candidateuploadid ? 1 : 0;
    const base64DocumentDocument = uploadDetails[2]?.document;
    const currentDocument = base64ToFile(
      base64DocumentDocument,
      `${candidateid}_document.pdf`
    );
    console.log('currentDocument -------------', currentDocument);
  
    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(currentDocument);
    link.target = '_blank'; // Open in a new tab
  
    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);
  
    // Simulate a click on the link to open the document
    link.click();
  
    // Remove the link from the document
    document.body.removeChild(link);
  };
  




  // console.log(uploadDetails,'upload details')
  const candidateid = candidateTokenDetails?.candidateid;
  const {
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (FullCandidateDetails.length > 0) {
      const { pstateid } = FullCandidateDetails[0];
      setValue("pstateid", pstateid);
    }
  }, [FullCandidateDetails, setValue]);

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
    }
  }, [candidateid,referralCode]);


  useEffect(() => {
    // Keep input updated if personal details change (e.g., after referral code is applied or removed)
    if (personalDetails[0]?.referralcode) {
      setReferralCodeInpValue(personalDetails[0].referralcode);
    } else {
      setReferralCodeInpValue("");
    }
  }, [personalDetails]);
  
  function generateOrderId() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let orderId = "UATM";
    for (let i = 0; i < 10; i++) {
      orderId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return orderId;
  }

  function formatDate() {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const absOffset = Math.abs(offset);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    const tzHours = String(Math.floor(absOffset / 60)).padStart(2, "0");
    const tzMinutes = String(absOffset % 60).padStart(2, "0");
    const tzSign = offset > 0 ? "-" : "+";

    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate()).padStart(2, "0") +
      "T" +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      tzSign +
      tzHours +
      ":" +
      tzMinutes
    );
  }

  // console.log(formatDate());

  async function getIPAddress() {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip)
        // console.log('IP Address:', data.ip);
    } catch (error) {
        // console.error('Error fetching IP address:', error);
    }
}


getIPAddress();

  const handlePayment = async () => {
    const date = formatDate();
    const orderid = generateOrderId();
    // console.log(orderid);
    // console.log(date);

    const orderDetails = {
      orderid: orderid,
      amount: "1.00",
      currency: "356",
      order_date: date.toString(),
      ru: `${BILLDESK_URL}/billdesk/status?furl=${FRONTEND_URL}/billdesk-status?referralcode=${personalDetails[0]?.referralcode ?personalDetails[0]?.referralcode:"null"}`,
      additional_info: {},
      device: {
        init_channel: "internet",
        ip: `${ipAddress}`,
        user_agent: navigator.userAgent,
        accept_header: "text/html",
        browser_tz: "-330",
        browser_color_depth: "32",
        browser_java_enabled: "false",
        browser_screen_height: window.screen.height.toString(),
        browser_screen_width: window.screen.width.toString(),
        browser_language: navigator.language,
        browser_javascript_enabled: "true",
      },
    };

    // console.log(orderDetails);
    try {
      // console.log("ciddddddddddd", candidateid);
      const response = await axios.post(
        `${BILLDESK_URL}/billdesk/create-order`,
        { orderDetails: orderDetails, candidateid: candidateid }
      );

      // console.log("responce ---->", response.data);

      const { bdorderid } = response.data.data;
      const merchantid = "UVIBGYORV2";
      const rdata = response.data.data.links[1].parameters.rdata;

      const form = document.createElement("form");
      form.method = "POST";
      form.action = BILLDESK_ORDER_URL;

      const hiddenFields = {
        bdorderid,
        rdata: rdata,
        merchantid: merchantid,
      };
      // console.log("hiddenFields ----------->>", hiddenFields);
      for (const key in hiddenFields) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = hiddenFields[key];
        form.appendChild(input);
      }

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      // console.error("Error creating order:", error);
    }
  };
  const handleCheckbox = (e) => {
    const checked = e.target.checked;
    setCheckBoxValue(checked);
    setError("");
  };

  const ref = useRef();

  const handleBack = () => {
    navigate(`/home?page=uploads`);
  };

const handleReferralSubmit = (e) =>{
  e.preventDefault();

  // console.log("referralll------");

  dispatch({ type: candidatesActions.GET_REFERRAL_CODE,payload:referralCodeInpValue});
  dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });

};

const handleRemoveReferral  = (e) =>{
  e.preventDefault();

  dispatch({ type: candidatesActions.REMOVE_REFERRAL_CODE,payload:personalDetails[0]?.referralcode ? personalDetails[0]?.referralcode : ""});
  dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });

  setReferralCodeInpValue('')

};

  return (
    <Grid
      p={3}
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
      previewRef={targetRef}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h4"
          sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500 ,mb:"20px"}}
        >
          Preview & Validate Information
        </Typography>
        {personalDetails[0]?.applicantid && (
          <Typography
            variant="h4"
            sx={{
              textWrap: "wrap",
              fontSize: 28,
              fontWeight: "bold",
              color: "#3F41D1",
            }}
          >
            Application No - {personalDetails[0]?.applicantid}
          </Typography>
        )}
      </Box>

      <Grid container mt={1}>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>Personal Details</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#3F41D1",
              height:"50px"
              }}
              onClick={() => navigate(`/home?page=personaldetails`)}
            >
              {FullCandidateDetails[0]?.ispaid !== "1" && (
                <>
                  <MdOutlineModeEdit size={18} />
                  &nbsp;<Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>EDIT</Typography>
                </>
              )}
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Student Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.candidatename}
              </Typography>
            </Grid>
            {/* <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Student Last Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.candidatename}
              </Typography>
            </Grid> */}
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Student Email
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.emailid}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Student Mobile Number
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {/* {`+91-${FullCandidateDetails[0]?.mobileno.slice(2)}`} */}
                {FullCandidateDetails[0]?.mobileno.length>10?FullCandidateDetails[0]?.mobileno.slice(2):FullCandidateDetails[0]?.mobileno}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Gender
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.gender}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Date of Birth
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {dayjs(FullCandidateDetails[0]?.dob).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Guardian's First name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.parentfristname}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Guardian's Last name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.parentlastname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Guardian's Email Id
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.relationemail}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Guardian's Mobile number
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {/* {`+91-${FullCandidateDetails[0]?.relaationcontactno}`} */}
                {FullCandidateDetails[0]?.relaationcontactno}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Relationship with Student
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.relationshipwithstudent}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>Permanent Address</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#3F41D1",
              height:"50px"
              }}
              onClick={() => navigate(`/home?page=address`)}
            >
              {FullCandidateDetails[0]?.ispaid !== "1" && (
                <>
                  <MdOutlineModeEdit size={18} />
                  &nbsp;<Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>EDIT</Typography>
                </>
              )}
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.paddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.paddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.paddressline3}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {/* {getValues("pstate")} */}
                {FullCandidateDetails[0]?.pstate}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.pdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.ptaluka}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.pvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Std code
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.stdcode}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Pincode
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.ppincode}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Mobile number
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.phoneno}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>
              Correspondence Address
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#3F41D1",
              height:"50px"
              }}
              onClick={() => navigate(`/home?page=address`)}
            >
              {FullCandidateDetails[0]?.ispaid !== "1" && (
                <>
                  <MdOutlineModeEdit size={18} />
                  &nbsp;<Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>EDIT</Typography>
                </>
              )}
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                {" "}
                Address Line 1
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.caddressline1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Address Line 2
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.caddressline2}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Address Line 3
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.caddressline3}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                State
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.cstate}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                District
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.cdistrict}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Taluka
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.ctaluka}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Village
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.cvillage}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Pincode
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.cpincode}
              </Typography>
            </Grid>
            {/* <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Phone No
              </Typography>
            </Grid> */}
            {/* <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                9585702391
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>Educational Details</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#3F41D1",
              height:"50px"
              }}
              onClick={() => navigate(`/home?page=educationdetails`)}
            >
              {FullCandidateDetails[0]?.ispaid !== "1" && (
                <>
                  <MdOutlineModeEdit size={18} />
                  &nbsp;<Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>EDIT</Typography>
                </>
              )}
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Student Appearing Standard
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.standard}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Board
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.board}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Medium of Instructions
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.mediumofinstructions}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                Name of School
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.schoolname}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                State of School
              </Typography>
            </Grid>
            <Grid
              item
              md={9}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.state}
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{ fontSize: 15, textAlign: "right", color: "#3F41D1" }}
              >
                City of School
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                {FullCandidateDetails[0]?.district}
              </Typography>
            </Grid>
            <Grid
              item  
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            ></Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
        <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>Photo and Signature</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#3F41D1",
              height:"50px"
              }}
              onClick={() => navigate(`/home?page=uploads`)}
            >
              {FullCandidateDetails[0]?.ispaid !== "1" && (
                <>
                  <MdOutlineModeEdit size={18} />
                  &nbsp;<Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>EDIT</Typography>
                </>
              )}
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                Photo
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={12}
              p={1}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "140px", width: "auto" }}
                src={uploadDetails[0]?.document}
                alt="photo"
              />
            </Grid>
            <Grid
              item
              md={6}
              p={1}
              xs={12}
              sx={{
                border: "1px solid #F7F7F7",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "53px", width: "auto" }}
                src={uploadDetails[1]?.document}
                alt="signatute"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Box
            p={1}
            sx={{
              height: "48px",
              backgroundColor: "#EDEDED",
              borderTop: "1px solid #F7F7F7",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>Document Uploaded</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#3F41D1",
              height:"50px"
              }}
              onClick={() => navigate(`/home?page=uploads`)}
            >
              {FullCandidateDetails[0]?.ispaid !== "1" && (
                <>
                  <MdOutlineModeEdit size={18} />
                  &nbsp;<Typography sx={{ fontSize: 14,fontWeight:500,color:'#212121' }}>EDIT</Typography>
                </>
              )}
            </Box>
          </Box>

          <Grid container md={12}>
            <Grid
              item
              md={2}
              xs={12}
              p={1}
              sx={{ border: "1px solid #F7F7F7" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}>
                1
              </Typography>
            </Grid>
            <Grid
              item
              md={10}
              p={1}
              xs={12}
              sx={{ border: "1px solid #F7F7F7" ,display:"felx"}}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  textAlign: "right",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
               {FullCandidateDetails[0]?.documentid === 1 &&  "Adhaar Card"}
               {FullCandidateDetails[0]?.documentid === 2 &&  "School ID"}
               {FullCandidateDetails[0]?.documentid === 3 &&  "Birth Certificate"}
              </Typography>
        
        <Link sx={{cursor:"pointer"}}  onClick={handleView}>view </Link>
        
            </Grid>
            {/* {location.pathname + location.search ===
              "/mhcet/course-registration?page=preview" && (
                <>
                  <Grid
                    item
                    md={12}
                    p={1}
                    xs={12}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "right",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      Note :
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    p={1}
                    xs={12}
                    sx={{ border: "1px solid #F7F7F7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "right",
                        color: "red",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      *Candidate Shall Carry Disability Certificate at the time of
                      Examination.( For All PWD Candidate)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    p={1}
                    xs={12}
                    sx={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "0px 0px 8px 8px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      <Checkbox
                        onChange={handleCheckbox}
                        checked={CheckboxValue}
                        sx={{ margin: 0, padding: 0 }}
                      />
                      <Typography
                        sx={{
                          fontSize: 15,
                          textAlign: "right",
                          color: "red",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        I have read all important Instructions.
                      </Typography>
                    </Box>
                  </Grid>
                  {error && <small className="error">{error}</small>}
                </>
              )} */}
          </Grid>
        </Grid>
      </Grid>
      {FullCandidateDetails[0]?.ispaid !== "1" && (
        <>

<Box sx={{display:"flex",flexDirection:"column",gap:1}}>


          <Box pt={2} sx={{
            display: "flex",
            gap: "8px",
            alignItems: isMobile ? "flex-start" : "center",
            flexDirection: isMobile ? "column" : null,
          }}>



<Box sx={{display:"flex",flexDirection:"column",gap:1,justifyContent:"center",alignItems:"center"}}>

            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              },
            }}  
            disabled={
              // referralCode != null && referralCode != 'invalid' 
              // ||
             personalDetails[0]?.referralcode !== null
            
            }
            onChange={(e)=>setReferralCodeInpValue(e.target.value)} value={referralCodeInpValue} placeholder="Enter Refferal code"  />

            </Box>


{(
  // referralCode == null || referralCode == 'invalid' 

    //  ||
personalDetails[0]?.referralcode == null

) &&( <>

            <SaveButton
              name= "Apply"
              handleDraft={handleReferralSubmit}
              // disabled={!CheckboxValue}
            />
</>)  }

            {(
              // referralCode != null && referralCode != 'invalid' 
              // ||
             personalDetails[0]?.referralcode !== null
             ) && (<>
            
            
            <SaveButton name={'Remove'} handleDraft={handleRemoveReferral}/> 

            </>) }
{/* 
            {referralCode != null && <>

</>} */}
          </Box>

              {(
              //   referralCode != null && referralCode != 'invalid'
              //  ||
                personalDetails[0]?.referralcode !== null
               ) &&(<>
                        <Box color={"green"} fontWeight={500}>Referral Applied</Box> </>)}
              {(referralCode == 'invalid') &&(<>
                        <Box color={"red"} fontWeight={500}>Invalid Referral Code</Box> </>)}

          </Box>

          <Box pt={2} sx={{
            display: "flex",
            gap: "8px",
            alignItems: "flex-start",
            flexDirection: isMobile ? "column" : null,
          }}>
            <Checkbox sx={{ padding: 0 }} checked={CheckboxValue} onChange={handleCheckbox} />
            <Typography>I certify that all information provided in my application is accurate and truthful,
              and I understand that once submitted, no changes can be made to my application
              details.</Typography>

          </Box>
        </>
      )}
      {location.pathname + location.search === "/home?page=preview" && (
        <Grid
          item
          md={12}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
        {uploadDetails[0]?.status === 2 && (
          <BackButton handleBack={handleBack} />
        )}
          {FullCandidateDetails[0]?.ispaid !== "1" && (
            <SubmitButton
              name={buttonLoader ? "loader" : "Continue to payment"}
              handleSubmit={handlePayment}
              disabled={!CheckboxValue}
              // disabled={true}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}
