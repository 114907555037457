import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import CancelButton from '../../common/button/cancelButton';
import videoUrl from '../../assets/images/file_example_MP4_480_1_5MG.mp4';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px'
};

export default function HelpManualVideoModal(props) {

    const { handleClose, showModal } = props;

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant="h5" sx={{ color: `${cssProperties?.color?.primary}`, fontWeight: '500', marginBottom: '24px' }}>Help Manual</Typography>
                <Box sx={{height: '300px'}}>
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoUrl}
                        // frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Video"
                    ></iframe>

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                    <CancelButton handleBack={handleClose} title={'Close'} />
                </Box>
            </Box>
        </Modal >
    )
};