const authActions = {
    CANDIDATE_SINUP:'CANDIDATE_SINUP',
    CANDIDATE_LOGIN:'CANDIDATE_LOGIN',
    CANDIDATE_LOGOUT:'CANDIDATE_LOGOUT',
    CANDIDATE_FORGETPASSWORD:'CANDIDATE_FORGETPASSWORD',
    CANDIDATE_TOKEN_VERIFY: 'CANDIDATE_TOKEN_VERIFY',
    SET_CANDIDATE_TOKEN_DETAILS: 'SET_CANDIDATE_TOKEN_DETAILS',

    VERIFY_EMAILID_FOR_SIGNUP: 'VERIFY_EMAILID_FOR_SIGNUP',
    VERIFY_MOBILENO_FOR_SIGNUP: 'VERIFY_MOBILENO_FOR_SIGNUP',
    SEND_OTP_FOR_MOBILE_VERIFICATION: 'SEND_OTP_FOR_MOBILE_VERIFICATION',
    CHECK_EMAIL_VERIFICAITON: 'CHECK_EMAIL_VERIFICAITON',
    RESEND_EMAIL_FOR_VERIFICATION: 'RESEND_EMAIL_FOR_VERIFICATION',
    CHECK_CANDIDATE_MOBILENO: 'CHECK_CANDIDATE_MOBILENO',
    FORGOTPASSWORD_MOBILEVERIFICATION: 'FORGOTPASSWORD_MOBILEVERIFICATION',
    FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID: 'FORGOTPASSWORD_MOBILEVERIFICATION_SENDMAILID',

    GET_PROFILE_REGISTRATION_ACTIVE_TAB: 'GET_PROFILE_REGISTRATION_ACTIVE_TAB',
    SET_PROFILE_REGISTRATION_ACTIVE_TAB: 'SET_PROFILE_REGISTRATION_ACTIVE_TAB',
};

export default authActions;