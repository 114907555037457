const masterActions = {
    GET_GENDER : 'GET_GENDER',
    SET_GENDER : 'SET_GENDER',
    GET_MOTHERTONGUE : 'GET_MOTHERTONGUE',
    SET_MOTHERTONGUE : 'SET_MOTHERTONGUE',
    GET_RELIGION : 'GET_RELIGION',
    SET_RELIGION : 'SET_RELIGION',
    GET_REGION : 'GET_REGION',
    SET_REGION : 'SET_REGION',
    GET_NATIONALITY : 'GET_NATIONALITY',
    SET_NATIONALITY : 'SET_NATIONALITY',
    GET_ANNUALINCOME : 'GET_ANNUALINCOME',
    SET_ANNUALINCOME : 'SET_ANNUALINCOME',
    GET_STATE : 'GET_STATE',
    SET_STATE : 'SET_STATE',
    GET_DISTRICT : 'GET_DISTRICT',
    SET_DISTRICT : 'SET_DISTRICT',
    GET_DISTRICT_CORRESPODENT : 'GET_DISTRICT_CORRESPODENT',
    SET_DISTRICT_CORRESPONDENT : 'SET_DISTRICT_CORRESPONDENT',
    GET_TALUKA_CORRESPODENT : 'GET_TALUKA_CORRESPODENT',
    SET_TALUKA_CORRESPONDENT : 'SET_TALUKA_CORRESPONDENT',
    GET_VILLAGE_CORRESPODENT : 'GET_VILLAGE_CORRESPODENT',
    SET_VILLAGE_CORRESPONDENT : 'SET_VILLAGE_CORRESPONDENT',
    GET_TALUKA : 'GET_TALUKA',
    SET_TALUKA : 'SET_TALUKA',
    GET_VILLAGE : 'GET_VILLAGE',
    SET_VILLAGE : 'SET_VILLAGE',
    SET_CATEGORY : 'SET_CATEGORY',
    GET_CATEGORY : 'GET_CATEGORY',
    SET_CASTE : 'SET_CASTE',
    GET_CASTE : 'GET_CASTE',
    SET_PWD_TYPE : 'SET_PWD_TYPE',
    GET_PWD_TYPE : 'GET_PWD_TYPE',
    GET_BOARD : 'GET_BOARD',
    SET_BOARD : 'SET_BOARD',
    GET_LANGUAGE : 'GET_LANGUAGE',
    SET_LANGUAGE : 'SET_LANGUAGE',
    GET_ALL_DISTRICT : 'GET_ALL_DISTRICT',
    SET_ALL_DISTRICT : 'SET_ALL_DISTRICT',
    GET_GRIEVANCE : 'GET_GRIEVANCE',
    SET_GRIEVANCE : 'SET_GRIEVANCE',
    SET_MINORITY : 'SET_MINORITY',
    GET_MINORITY : 'GET_MINORITY',
    GET_DOCUMENT_TYPE : 'GET_DOCUMENT_TYPE',
    SET_DOCUMENT_TYPE : 'SET_DOCUMENT_TYPE',
    GET_MEDIUM_OF_INSTRUCTIONS : 'GET_MEDIUM_OF_INSTRUCTIONS',
    SET_MEDIUM_OF_INSTRUCTIONS : 'SET_MEDIUM_OF_INSTRUCTIONS',
    GET_STANDARD : "GET_STANDARD",
    SET_STANDARD : "SET_STANDARD",
    GET_PHONECODE : 'GET_PHONECODE',
    SET_PHONECODE : 'SET_PHONECODE',
};

export default masterActions;