import masterActions from "./action";

const initialState = {
  gender: [],
  mothertongue: [],
  religion: [],
  region: [],
  nationality: [],
  annualIncome: [],
  state: [],
  district: [],
  taluka: [],
  village: [],
  districtCorrespontance: [],
  talukaCorrespontance: [],
  villageCorrespontance: [],
  category: [],
  caste: [],
  pwdType: [],
  board: [],
  language: [],
  allDistrict: [],
  grievance: [],
  minority: [],
  documentType: [],
  mediumofinstructions:[],
  standard:[],
  phonecode:[]
};

const masterReducer = (state = initialState, action) => {
  switch (action.type) {
    case masterActions.SET_GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case masterActions.SET_MEDIUM_OF_INSTRUCTIONS:
      return {
        ...state,
        mediumofinstructions: action.payload,
      };
    case masterActions.SET_MOTHERTONGUE:
      return {
        ...state,
        mothertongue: action.payload,
      };
    case masterActions.SET_RELIGION:
      return {
        ...state,
        religion: action.payload,
      };
    case masterActions.SET_REGION:
      return {
        ...state,
        region: action.payload,
      };
    case masterActions.SET_NATIONALITY:
      return {
        ...state,
        nationality: action.payload,
      };
    case masterActions.SET_ANNUALINCOME:
      return {
        ...state,
        annualIncome: action.payload,
      };
    case masterActions.SET_STATE:
      return {
        ...state,
        state: action.payload,
      };
    case masterActions.SET_DISTRICT:
      return {
        ...state,
        district: action.payload,
      };
    case masterActions.SET_DISTRICT_CORRESPONDENT:
      return {
        ...state,
        districtCorrespontance: action.payload,
      }; 
      case masterActions.SET_TALUKA_CORRESPONDENT:
      return {
        ...state,
        talukaCorrespontance: action.payload,
      };  
      case masterActions.SET_VILLAGE_CORRESPONDENT:
      return {
        ...state,
        villageCorrespontance: action.payload,
      };   
    case masterActions.SET_TALUKA:
      return {
        ...state,
        taluka: action.payload,
      };
    case masterActions.SET_VILLAGE:
      return {
        ...state,
        village: action.payload,
      };
    case masterActions.SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case masterActions.SET_CASTE:
      return {
        ...state,
        caste: action.payload,
      };
    case masterActions.SET_PWD_TYPE:
      return {
        ...state,
        pwdType: action.payload,
      };
    case masterActions.SET_BOARD:
      return {
        ...state,
        board: action.payload,
      };
    case masterActions.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case masterActions.SET_ALL_DISTRICT:
      return {
        ...state,
        allDistrict: action.payload,
      };
    case masterActions.SET_GRIEVANCE:
      return {
        ...state,
        grievance: action.payload,
      };
    case masterActions.SET_MINORITY:
      return {
        ...state,
        minority: action.payload,
      };
    case masterActions.SET_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload,
      };

      case masterActions.SET_STANDARD:
        
        return {
          ...state,
          standard: action.payload,
        }; 
      case masterActions.SET_PHONECODE:
        return {
          ...state,
          phonecode: action.payload,
        };    
    default:
      return state;
  }
};

export default masterReducer;
