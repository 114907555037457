import React, { useState } from "react";
import Index from "../components/signup/index";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";
import SupportModal from "../components/modals/supportModal";

// import HelpManualVideoModal from "../components/modals/helpManualVideoModal";

export default function Signup() {

  const [showModal, setShowModal] = useState({ show: false, type: null });



  // const breadcrumbs = [
  //   <Typography key="1" color="text.primary"
  //     sx={{
  //       display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
  //       fontSize: '14px',
  //       fontWeight: 500,
  //       lineHeight: '20px',
  //       letterSpacing: '0.1px'
  //     }}
  //   >
  //     Candidate Signup <MdKeyboardArrowRight size={16} />
  //   </Typography>
  // ];

  return (
    <>
      <BeforeLoginHeader handleShow={(type) => setShowModal({ show: true, type: type })} />
      <Index />
      <SupportModal showModal={showModal?.type === 'services' && showModal?.show} handleClose={() => setShowModal({ show: false, type: null })} />

      {/* <HelpManualVideoModal showModal={showModal?.type === 'video' && showModal?.show} handleClose={() => setShowModal({ show: false, type: null })} /> */}
    </>
  );
}