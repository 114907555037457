  import React, { useState, useEffect } from "react";
  import { IoCloudUploadOutline } from "react-icons/io5";
  import imageCompression from "browser-image-compression";
  import { useSelector, useDispatch } from "react-redux";
  import candidatesActions from "../../redux/candidates/actions";
  import masterActions from "../../redux/master/action";
  import SubmitButton from "../../common/button/submitButton";
  import SaveButton from "../../common/button/saveButton";
  import { useNavigate } from "react-router-dom";
  import { PiX } from "react-icons/pi";
  import pdf from "../../assets/images/uploads/pdf.png";
  import ImageCrop from "./imageCrop";
  import ImageCropSignature from "./imageCropSignature";
  import BackButton from "../../common/button/backButton";
  import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";

  export default function UploadsForm() {
    const [subCompleted,setSubcompleted] = useState(false);
    const [photofilename, setPhotoImage] = useState("");
    const [photoSize, setPhotoSize] = useState("");
    const [photoSizeCompressed, setPhotoSizeCompressed] = useState("");
    const [signfilename, setSignatureImage] = useState("");
    const [signatureSize, setSignatureSize] = useState("");
    const [signatureSizeCompressed, setSignatureSizeCompressed] = useState("");
    const [size, setSize] = useState("");
    const [photoError, setError] = useState("");
    const [signatureError, setSignatureError] = useState("");
    const [documentTypeError, setDocumentTypeError] = useState("");
    const [documentError, setDocumentError] = useState("");
    const [photoCheckError, setPhotoCheckError] = useState("");
    const [signatureCheckError, setSignatureCheckError] = useState("");
    const [documentid, setDocumentId] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [documentName, setDocumentName] = useState("");
    const [documentfilename, setDocument] = useState("");
    const [showModal, setShowModal] = useState({ show: false, data: null });
    const [showModalSignature, setShowModalSignature] = useState({
      show: false,
      data: null,
    });

    const [confirmPhotoImage, setConfirmPhotoImage] = useState(false);
    const [confirmSignatureImage, setConfirmSignatureImage] = useState(false);

    const [cropPhotoImage, setCropPhotoImage] = useState(null);
    const [cropSignatureImage, setCropSignatureImage] = useState(null);

    const { candidateTokenDetails } = useSelector((state) => state.authReducer);
    const candidateid = candidateTokenDetails?.candidateid;

    const { uploadDetails, FullCandidateDetails } = useSelector((state) => state.candidatesReducer);
    // console.log(uploadDetails,'upload');

    const { documentType } = useSelector((state) => state.masterReducer);

    const candidateuploaddraftid = uploadDetails[0]?.candidateuploaddraftid
      ? uploadDetails[0]?.candidateuploaddraftid
      : uploadDetails[1]?.candidateuploaddraftid
        ? uploadDetails[1]?.candidateuploaddraftid
        : uploadDetails[2]?.candidateuploaddraftid;
    const candidateuploadid = uploadDetails[0]?.candidateuploadid
      ? uploadDetails[0]?.candidateuploadid
      : uploadDetails[1]?.candidateuploadid
        ? uploadDetails[1]?.candidateuploadid
        : uploadDetails[2]?.candidateuploadid;

        // console.log('uploadDetails[2]---',uploadDetails[2]);
    useEffect(() => {
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      dispatch({ type: masterActions.GET_DOCUMENT_TYPE });
    }, []);

    useEffect(() => {
      if (uploadDetails.length > 0) {
        setDocumentId(uploadDetails[0]?.documentid || "");
        // console.log(uploadDetails);
      }
    }, [uploadDetails]);
    useEffect(() => {
      if (candidateid) {
        dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
      }
      
    }, [candidateid]);

    const handleDocumentUpload = (event) => {
      const file = event.target.files[0];
      const fileName = file.name;
      const fileType = file.type;
    
      // Check if the file is a PDF
      if (fileType !== "application/pdf") {
        setDocumentError("Please upload pdf file");
        setDocumentName(""); // Reset the document name
        setDocument(""); // Reset the document state
        event.target.value = null; // Clear the input value
        return; // Stop further execution
      }
    
      setDocumentName(fileName);
      setDocument(file);
      setDocumentError(""); // Clear any previous error
      event.target.value = null;
    };


    const handleCloseDocument = () => {
      setDocumentName("");
    };

    const handleDocumentId = (event) => {
      setDocumentId(event.target.value);
      setDocumentTypeError("");
    };

    const handleClose = () => {
      setShowModal({ show: false, data: null });
    };

    const { buttonLoader } = useSelector((state) => state.commonReducer);
    const { draftButtonLoader } = useSelector((state) => state.commonReducer);

    const {
      showImage,
      showCompressedImage,
      showSignature,
      showCompressedSignature,otherDetails
    } = useSelector((state) => state.candidatesReducer);

    const handleImageUpload = async (event) => {
      const file = event.target.files[0];
      const fileSizeInKB = file.size / 1024;
      setPhotoSize(fileSizeInKB.toFixed(2));
      setSize(fileSizeInKB);
      setShowModal({ show: true, data: null });
      if (fileSizeInKB > 100) {
        try {
          const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          const convertedFileCompressed = URL.createObjectURL(compressedFile);
          setCropPhotoImage(convertedFileCompressed);
          setConfirmPhotoImage(false);
          // setPhotoImage(compressedFile)
          setPhotoSizeCompressed(compressedFile);
        } catch (err) {
          // console.log(err);
        }
      } else {
        const convertedFile = URL.createObjectURL(file);
        setCropPhotoImage(convertedFile);
        setConfirmPhotoImage(true);
        // setPhotoImage(file)
      }
    };

    const handleSignatureUpload = async (event) => {
      const file = event.target.files[0];
      const fileSizeInKB = file.size / 1024;
      setSize(fileSizeInKB);
      setSignatureSize(fileSizeInKB.toFixed(2));
      setShowModalSignature({ show: true, data: null });
      if (fileSizeInKB > 100) {
        try {
          const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
          };
          const compressedFile = await imageCompression(file, options);
          const convertedFileCompressed = URL.createObjectURL(compressedFile);
          setCropSignatureImage(convertedFileCompressed);
          setSignatureSizeCompressed(compressedFile);
          setConfirmSignatureImage(false);
          // setSignatureImage(compressedFile)
          setSubcompleted(true)
        } catch (err) {
          // console.log(err);
        }
      } else {
        const convertedFile = URL.createObjectURL(file);
        setCropSignatureImage(convertedFile);
        setConfirmSignatureImage(true);
        // setSignatureImage(file)
      }
    };

    function base64ToFile(base64String, fileName) {
      if (!base64String) {
        return null;
      }
      const byteString = atob(base64String.split(",")[1]);
      const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new File([ab], fileName, { type: mimeString });
    }

    const handleSubmit = () => {
      const operation = candidateuploadid ? 1 : 0;
      const base64DocumentImage = uploadDetails[0]?.document;
      const base64DocumentSignature = uploadDetails[1]?.document;
      const base64DocumentDocument = uploadDetails[2]?.document;
      const currentSignature = base64ToFile(
        base64DocumentSignature,
        `${candidateid}_signature.jpeg`
      );
      const currentImage = base64ToFile(
        base64DocumentImage,
        `${candidateid}_photo.jpeg`
      );
      const currentDocument = base64ToFile(
        base64DocumentDocument,
        `${uploadDetails[2]?.filename}`
      );
      // if (
      //   photofilename ||
      //   (uploadDetails[0]?.document && documentfilename) ||
      //   (uploadDetails[2]?.document && signfilename) ||
      //   (uploadDetails[1]?.document && documentid)
      // ) 
      if (
        (photofilename || uploadDetails[0]?.document || cropPhotoImage) && (signfilename || uploadDetails[1]?.document || cropSignatureImage) &&
        (documentfilename || uploadDetails[2]?.document) && (documentid || uploadDetails[0]?.documentid) && (cropPhotoImage ? confirmPhotoImage : uploadDetails[0]?.filename) &&
        (cropSignatureImage ? confirmSignatureImage : uploadDetails[1]?.filename)
      ) {

        // console.log('uploadDetails[2]?.filename---',uploadDetails[2]?.filename);
        const data = {
          photofilename: photofilename ? photofilename : currentImage,
          signfilename: signfilename ? signfilename : currentSignature,
          documentfilename: documentfilename ? documentfilename: currentDocument,
        
        };

        // console.log('data-------',data);
        dispatch({
          type: candidatesActions.PROFILE_UPLOADS_DETAILS_CREATION,
          payload: {
            data: {
              ...data,
              operation: operation,
              candidateid: candidateid,
              documentid: documentid ? documentid : uploadDetails[0].documentid,
              status: 2,
            },
            navigate: navigate,
          },
        });
      } else {
        if (!cropPhotoImage && !uploadDetails[0]?.filename) {
          setError("Image is required");
        }
        if (!cropSignatureImage && !uploadDetails[1]?.filename) {
          setSignatureError("Signature is required");
        }
        if (!documentfilename && !uploadDetails[2]?.filename) {
          setDocumentError("Document is required");
        }
        if (!documentid) {
          setDocumentTypeError("Select One");
        }
        if (!confirmPhotoImage || !uploadDetails[0]?.filename) {
          setPhotoCheckError("select the checkbox is required");
        }
        if (!confirmSignatureImage || !uploadDetails[1]?.filename) {
          setSignatureCheckError("select the checkbox is required");
        }
      }
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
    };

    const handleDraft = () => {
      const operation = candidateuploaddraftid ? 1 : 0;
      const base64DocumentImage = uploadDetails[0]?.document;
      const base64DocumentSignature = uploadDetails[1]?.document;
      const base64DocumentDocument = uploadDetails[2]?.document;
      const currentSignature = base64ToFile(
        base64DocumentSignature,
        `${candidateid}_signature.jpeg`
      );
      const currentImage = base64ToFile(
        base64DocumentImage,
        `${candidateid}_photo.jpeg`
      );
      const currentDocument = base64ToFile(
        base64DocumentDocument,
        `${candidateid}_document.pdf`
      );
      const data = {
        photofilename: photofilename ? photofilename : currentImage,
        signfilename: signfilename ? signfilename : currentSignature,
        documentfilename: documentfilename ? documentfilename : currentDocument,
      };
      dispatch({
        type: candidatesActions.PROFILE_UPLOADS_DETAILS_DRAFT_CREATION,
        payload: {
          data: {
            ...data,
            operation: parseInt(operation),
            candidateid: parseInt(candidateid),
            documentid: documentid ? documentid : uploadDetails[0].documentid,
            status: 1,
          },
        },
      });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
    };

    const onCropDone = (imgCroppedArea) => {
      if (cropPhotoImage) {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;
        const context = canvasEle.getContext("2d");
        let croppedImage = new Image();
        croppedImage.src = cropPhotoImage;
        croppedImage.onload = function () {
          context.drawImage(
            croppedImage,
            imgCroppedArea.x,
            imgCroppedArea.y,
            imgCroppedArea.width,
            imgCroppedArea.height,
            0,
            0,
            imgCroppedArea.width,
            imgCroppedArea.height
          );
          const dataURL = canvasEle.toDataURL("image/jpeg");
          if (size <= 100) {
            dispatch({
              type: candidatesActions.SHOW_IMAGE,
              payload: dataURL,
            });
            dispatch({
              type: candidatesActions.SHOW_COMPRESSED_IMAGE,
              payload: null,
            });
          } else {
            dispatch({
              type: candidatesActions.SHOW_COMPRESSED_IMAGE,
              payload: dataURL,
            });
            dispatch({
              type: candidatesActions.SHOW_IMAGE,
              payload: dataURL,
            });
          }
          setSize("");
          setShowModal({ show: false, data: null });

          const byteString = atob(dataURL.split(",")[1]);
          const arrayBuffer = new ArrayBuffer(byteString.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: "image/jpeg" });
          const file = new File([blob], "croppedImage.jpg", {
            type: "image/jpeg",
          });
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          const fileInput = document.createElement("input");
          fileInput.type = "file";
          fileInput.files = dataTransfer.files;
          setPhotoImage(fileInput.files[0]);
        };
      }
    };

    const onCropDoneSignature = (imgCroppedArea) => {
      const canvasEle = document.createElement("canvas");
      canvasEle.width = imgCroppedArea.width;
      canvasEle.height = imgCroppedArea.height;
      const context = canvasEle.getContext("2d");
      let croppedImage = new Image();
      croppedImage.src = cropSignatureImage;
      croppedImage.onload = function () {
        context.drawImage(
          croppedImage,
          imgCroppedArea.x,
          imgCroppedArea.y,
          imgCroppedArea.width,
          imgCroppedArea.height,
          0,
          0,
          imgCroppedArea.width,
          imgCroppedArea.height
        );
        const dataURL = canvasEle.toDataURL("image/jpeg");
        if (size <= 100) {
          dispatch({
            type: candidatesActions.SHOW_SIGNATURE,
            payload: dataURL,
          });
          dispatch({
            type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
            payload: null,
          });
        } else {
          dispatch({
            type: candidatesActions.SHOW_SIGNATURE,
            payload: dataURL,
          });
          dispatch({
            type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
            payload: dataURL,
          });
        }
        setShowModalSignature({ show: false, data: null });
        const byteString = atob(dataURL.split(",")[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/jpeg" });
        const file = new File([blob], `croppedImage.jpg`, { type: "image/jpeg" });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.files = dataTransfer.files;
        setSignatureImage(fileInput.files[0]);
      };
    };

    const onCropCancelSignature = () => {
      setShowModalSignature({ show: false, data: null });
    };

    const onCropCancel = () => {
      setShowModal({ show: false, data: null });
    };

    const handleBack = () => {
      navigate(`/home?page=educationdetails`);
    
    };

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container>
        <Grid item lg={7}>
          {uploadDetails[0]?.filename !== "undefined" &&
            uploadDetails[0]?.filename &&
            !showImage &&
            !showCompressedImage ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Recent Photo<span className="error">*</span>
              </InputLabel>
              {/* {uploadDetails[0]?.filename} */}
              <Box
                sx={{
                  display: "flex",
                  gap: "2%",
                  marginTop: ".4rem",
                  flexDirection: isMobile ? "column" : "",
                }}
              >
                {/* {showImage && ( */}
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    src={uploadDetails[0].document}
                    alt="Original Image"
                    style={{ maxWidth: "80px" }}
                  />
                  <Typography variant="caption" style={{ paddingTop: "10px" }}>
                    Uploaded Photo
                  </Typography>
                  {/* {cropPhotoImage !== "" && (
                      <small className="photo-size">
                        {(photoSize.size / 1024).toFixed(2)} KB
                      </small>
                    )} */}
                </Box>
                {/* )} */}
                {/* {showImage && (
                   <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showImage}
                      alt="Original Image"
                      style={{ maxWidth: "80px" }}
                    />
                    <Typography style={{ paddingTop: "10px" }}>Uploaded image</Typography>
                    {cropPhotoImage !== "" && (
                      <small className="photo-size">
                        {(photoSize.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )}
                {showCompressedImage && (
                  <>
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={showCompressedImage}
                        alt="Compressed Image"
                        style={{ maxWidth: "80px" }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Typography style={{ paddingTop: "10px" }}>Compressed image</Typography>
                        <Box className="form-check">
                          <input
                            className="form-check-input rounded-circle is-valid"
                            type="checkbox"
                            checked={confirmPhotoImage}
                            onChange={(e) => {
                              setConfirmPhotoImage(e.target.checked);
                              if (e.target.checked) {
                                setPhotoCheckError("");
                              }
                            }}
                            style={{ height: "18px", width: "18px" }}
                          />
                        </Box>
                      </Box>
                      {cropPhotoImage !== "" && (
                        <small className="photo-size">
                          {(photoSize.size / 1024).toFixed(2)} KB
                        </small>
                      )}
                    </Box>
                  </>
                )} */}
                <Box
                  style={{
                    border: "1px solid #203272",
                    // width: "180px",
                    padding: "0 10px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    alignSelf: isMobile ? "flex-start" : "end",
                  }}
                >
                  <input
                    type="file"
                    name="photofilename"
                    id="photofilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor="photofilename"
                    style={{
                      cursor: "pointer",
                      color: "#203272",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Update
                    Photo
                  </label>
                </Box>
              </Box>
              {photoError && <small className="error">{photoError}</small>}
              <br />
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </Box>
          ) : showImage ? (
            <>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Recent Photo<span className="error">*</span>
              </InputLabel>
              <Box style={{ display: "flex", gap: "5%" }}>
                {showImage && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showImage}
                      alt="Original Image"
                      style={{ maxWidth: "80px" }}
                    />
                    <Typography
                      variant="caption"
                      style={{ paddingTop: "10px" }}
                    >
                      Uploaded Photo
                    </Typography>
                    {cropPhotoImage !== "" && (
                    subCompleted &&  <small className="photo-size">{photoSize} KB</small>
                    )}
                  </Box>
                )}
                {showCompressedImage && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showCompressedImage}
                      alt="Compressed Image"
                      style={{ maxWidth: "80px" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Compressed Photo
                      </Typography>
                      <Box className="form-check">
                        <Checkbox
                          checked={confirmPhotoImage}
                          onChange={(e) => {
                            setConfirmPhotoImage(e.target.checked);
                            if (e.target.checked) {
                              setPhotoCheckError("");
                            }
                          }}
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Box>
                    </Box>
                    {cropPhotoImage !== "" && (
                  subCompleted &&  <small className="photo-size">
                        {(photoSizeCompressed.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )}

                <Box
                  style={{
                    border: "1px solid #203272",
                    // width: "180px",
                    padding: "0 10px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    alignSelf: "end",
                  }}
                >
                  <input
                    type="file"
                    name="photofilename"
                    id="photofilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleImageUpload}
                  />
                  <label
                    htmlFor="photofilename"
                    style={{
                      cursor: "pointer",
                      color: "#203272",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Update
                    Photo
                  </label>
                </Box>
              </Box>
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </>
          ) : (
            <>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Recent Photo<span className="error">*</span>
              </InputLabel>
              <Box
                sx={{
                  border: "1px dashed gray",
                  maxWidth: "330px",
                  minHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  name="photofilename"
                  id="photofilename"
                  accept=".jpg, .jpeg, .png"
                  style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                  }}
                  onChange={handleImageUpload}
                />

                <label
                  htmlFor="photofilename"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  &nbsp;
                  <IoCloudUploadOutline size={22} /> &nbsp; Drag or drop your
                  image, or <span style={{ color: "blue" }}>&nbsp;browse</span>
                </label>
              </Box>
              {photoError && <small className="error">{photoError}</small>}
              <br />
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </>
          )}

          {uploadDetails[1]?.filename !== "undefined" &&
            uploadDetails[1]?.filename &&
            !showSignature &&
            !showCompressedSignature ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Signature<span className="error">*</span>
              </InputLabel>
              {/* {uploadDetails[1]?.filename} */}
              <Box
                style={{
                  display: "flex",
                  gap: "2%",
                  marginTop: ".4rem",
                  flexDirection: isMobile ? "column" : "",
                }}
              >
                {/* {showSignature && ( */}
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    src={uploadDetails[1].document}
                    alt="Original Signature"
                    style={{ maxWidth: "80px" }}
                  />
                  <Typography variant="caption" style={{ paddingTop: "10px" }}>
                    Uploaded signature
                  </Typography>
                  {/* {cropSignatureImage !== "" && (
                      <small className="photo-size">
                        {(signatureSize.size / 1024).toFixed(2)} KB
                      </small>
                    )} */}
                </Box>
                {/* )} */}
                {/* {showSignature && (
                 <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showSignature}
                      alt="Original Signature"
                      style={{ maxWidth: "80px" }}
                    />
                    <Typography style={{ paddingTop: "10px" }}>Uploaded signature</Typography>
                    </Box>)}
                {showCompressedSignature && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showCompressedSignature}
                      alt="Compressed Signature"
                      style={{ maxWidth: "80px" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography style={{ paddingTop: "10px" }}>
                        Compressed signature
                      </Typography>
                      <Box className="form-check">
                        <input
                          className="form-check-input rounded-circle is-valid"
                          type="checkbox"
                          checked={confirmSignatureImage}
                          onChange={(e) => {
                            setConfirmSignatureImage(e.target.checked);
                            if (e.target.checked) {
                              setSignatureCheckError("");
                            }
                          }}
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Box>
                    </Box>
                    {cropSignatureImage !== "" && (
                      <small className="photo-size">
                        {(signatureSize.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )} */}
                <Box
                  style={{
                    border: "1px solid #203272",
                    padding: "0 10px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    display: "flex",
                    alignSelf: isMobile ? "flex-start" : "end",
                  }}
                >
                  <input
                    type="file"
                    name="signfilename"
                    id="signfilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleSignatureUpload}
                  />
                  <label
                    htmlFor="signfilename"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      color: "#203272",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Update
                    signature
                  </label>
                </Box>
              </Box>
              {signatureError && (
                <small className="error">{signatureError}</small>
              )}
              <br />
              {signatureCheckError && (
                <small className="error">{signatureCheckError}</small>
              )}
            </Box>
          ) : showSignature ? (
            <>
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Signature<span className="error">*</span>
              </InputLabel>
              <Box style={{ display: "flex", gap: "5%" }}>
                {showSignature && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showSignature}
                      alt="Original Signature"
                      style={{ maxWidth: "80px" }}
                    />
                    <Typography
                      variant="caption"
                      style={{ paddingTop: "10px" }}
                    >
                      Uploaded signature
                    </Typography>
                    {cropSignatureImage !== "" && (
                     subCompleted && <small className="photo-size">{signatureSize} KB</small>
                    )}
                  </Box>
                )}
                {showCompressedSignature && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showCompressedSignature}
                      alt="Compressed Signature"
                      style={{ maxWidth: "80px" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Compressed signature
                      </Typography>
                      <Box className="form-check">
                        <Checkbox
                          checked={confirmSignatureImage}
                          onChange={(e) => {
                            setConfirmSignatureImage(e.target.checked);
                            if (e.target.checked) {
                              setSignatureCheckError("");
                            }
                          }}
                          style={{ height: "18px", width: "18px" }}
                        />
                      </Box>
                    </Box>
                    {cropSignatureImage !== "" && (
                       subCompleted && <small className="photo-size">
                      {(signatureSizeCompressed.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )}
                <Box
                  style={{
                    border: "1px solid #203272",
                    minWidth: "205px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    display: "flex",
                    alignSelf: "end",
                  }}
                >
                  <input
                    type="file"
                    name="signfilename"
                    id="signfilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleSignatureUpload}
                  />
                  <label
                    htmlFor="signfilename"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      color: "#203272",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Update
                    signature
                  </label>
                </Box>
              </Box>
              {signatureCheckError && (
                <small className="error">{signatureCheckError}</small>
              )}
            </>
          ) : (
            <>
              <br />
              <InputLabel
                htmlFor="signfilename"
                className="mt-3"
                style={{ fontWeight: "bold" }}
              >
                Upload Signature<span className="error">*</span>
              </InputLabel>
              <Box
                style={{
                  border: "1px dashed gray",
                  width: "330px",
                  minHeight: "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="file"
                  name="signfilename"
                  id="signfilename"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleSignatureUpload}
                  style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                  }}
                />
                <label
                  htmlFor="signfilename"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  &nbsp;
                  <IoCloudUploadOutline size={22} /> &nbsp; Drag or drop your
                  image, or <span style={{ color: "blue" }}> &nbsp;browse</span>
                </label>
              </Box>
              {signatureError && (
                <small className="error">{signatureError}</small>
              )}
              <br />
              {signatureCheckError && (
                <small className="error">{signatureCheckError}</small>
              )}
            </>
          )}
          <br />
          <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
            <Box>

              <InputLabel htmlFor="document" style={{ fontWeight: "bold" }}>
                Upload Age Proof Document<span className="error">*</span>
              </InputLabel>
              <FormControl
              sx={{
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#3F41D1', // Label color when focused
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#3F41D1', // Border color when focused
                },pt: 1 
              }}   
             fullWidth size="small">
                <Select
                  value={documentid}
                  id="documentid"
                  onChange={handleDocumentId}
                >
                  <MenuItem value="">Select One</MenuItem>
                  <MenuItem value={1}>Aadhar Card</MenuItem>
                  <MenuItem value={2}>School ID Card</MenuItem>
                  <MenuItem value={3}>Birth Certificate</MenuItem>
                  {/* {documentType?.map((val, i) => (
                  <MenuItem value={val?.documenttypeid} key={i}>
                  {val?.documenttype}
                  </MenuItem>
                ))} */}
                </Select>
              </FormControl>
              {documentTypeError && (
                <small className="error">{documentTypeError}</small>
              )}
            </Box>
            <Box  sx={{ mt: 1 }}>
              {!documentName && !uploadDetails[2]?.filename && (
                <Box sx={{backgroundColor: documentid === "" ? "#E0E0E0":"",color:documentid === "" ? "#E0E0E0":"",color:documentid === "" ? "#A3A3A3":""}}
                  style={{
                    border: "1px solid #203272",
                    width: "180px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "21px",
                    display: "flex",
                    alignSelf: "end",
                    marginTop: "0.8rem",
                  }}
                >
                  <input 
                  disabled = {documentid === ""}
                    type="file"
                    name="documentfilename"
                    id="documentfilename"
                    accept=".pdf"
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                    onChange={handleDocumentUpload}
                  />
                  <label
                    htmlFor="documentfilename"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Upload Document
                  </label>
                </Box>
              )}

              {documentName && (
                <Box
                  style={{
                    width: "18em",
                    height: "45px",
                    border: "1px solid #D5D5D5",
                    display: "flex",
                    alignItems: "center",
                    gap: "1%",
                    marginTop: "1rem",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <img
                    src={pdf}
                    style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                    alt="pdf"
                  />
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {documentName}
                  </span>
                  <PiX
                    style={{ margin: 0, padding: 0, cursor: "pointer" }}
                    onClick={handleCloseDocument}
                  />
                </Box>
              )}
              {uploadDetails[2]?.filename !== "undefined" &&
                uploadDetails[2]?.filename &&
                !documentName && (
                  <><Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                   
                    <Box
                      style={{
                        border: "1px solid #203272",
                        width: "220px",
                        height: "32px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "21px",
                        display: "flex",
                        alignSelf: "end",
                        marginTop: "0.8rem",
                      }}
                    >
                      <input
                        type="file"
                        name="documentfilename"
                        id="documentfilename"
                        accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                        style={{
                          width: "0.1px",
                          height: "0.1px",
                          opacity: 0,
                          overflow: "hidden",
                          position: "absolute",
                          zIndex: -1,
                        }}
                        onChange={handleDocumentUpload}
                      />
                      <label
                        htmlFor="documentfilename"
                        style={{
                          cursor: "pointer",
                          color: "#203272",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        &nbsp; <IoCloudUploadOutline size={22} /> &nbsp;Upload New
                        Document
                      </label>
                    </Box>
                    <Box
                      style={{
                        width: isMobile ? "10rem" : "18rem",
                        height: "45px",
                        border: "1px solid #D5D5D5",
                        display: "flex",
                        alignItems: "center",
                        gap: "1%",
                        marginTop: "1rem",
                        marginLeft:"20px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <img
                        src={pdf}
                        style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                        alt="pdf"
                      />
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {uploadDetails[2]?.filename}
                      </span>
                    </Box>
                   
                   
                    </Box>
                  </>
                )}
              <small
                className="error"
                style={{ color: "#666666", textWrap: "wrap" }}
              >
                {" "}
                Only PDF files are allowed
              </small>
              <br />
              {documentError && <small className="error">{documentError}</small>}
            </Box>

          </Box>
          <Box
            style={{
              minHeight: "5rem",
              maxWidth: "541px",
              padding: "1rem",
              backgroundColor: "#F15B501A",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <p style={{ fontSize: "0.9rem", color: "#F15B50" }}>
              Note :
              <br />
              1. The Photograph and Signature Image should be in jpg/jpeg/png
              format.
              <br />
              2. Ensure that Photograph and Signature Image is of good quality.
              <br /> 3. If uploaded bigger size photo/Signature, you will view
              the compressed image, if the quality of compressed image is good
              click the check box and confirm. If not good than upload lesser
              size photo/signature.
              <br /> 4. For Document upload, select the the appropriate type of
              document which is getting uploaded.
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        pb={2}
        pt={2}
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
          flexDirection: isMobile ? "column" : null,
        }}
      >
        {/* {uploadDetails[0]?.status === 2 && (
          <BackButton handleBack={handleBack} />
        )} */}
{/* {console.log("otherDetails",parseInt(otherDetails[0]?.status))} */}
   {parseInt(otherDetails[0]?.status) === 2 && (
            <BackButton handleBack={handleBack} />
          )}
{/* 
        <SaveButton
          name={draftButtonLoader ? "loader" : "Save Draft"}
          handleDraft={handleDraft}
          disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
        /> */}
          <SubmitButton
            name={buttonLoader ? "loader" : "Submit"}
            handleSubmit={handleSubmit}
            disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
          />
      </Grid>
      {cropPhotoImage && (
        <ImageCrop
          showModal={showModal?.show}
          handleClose={handleClose}
          image={cropPhotoImage}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      )}
      {cropSignatureImage && (
        <ImageCropSignature
          showModal={showModalSignature?.show}
          handleClose={handleClose}
          image={cropSignatureImage}
          onCropDone={onCropDoneSignature}
          onCropCancel={onCropCancelSignature}
        />
      )}
    </Grid>
  );
}
