import { put, call, all, takeEvery } from "redux-saga/effects";
import masterActions from "./action";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const masterSaga = function* () {
  yield all([
    yield takeEvery(masterActions.GET_GENDER, getGender),
    yield takeEvery(masterActions.GET_STATE, getState),
    yield takeEvery(masterActions.GET_DISTRICT, getDistrict),
    yield takeEvery(masterActions.GET_DISTRICT_CORRESPODENT, getDistrictCorrespondent),
    yield takeEvery(masterActions.GET_VILLAGE_CORRESPODENT, getVillageCorrespondent),
    yield takeEvery(masterActions.GET_TALUKA_CORRESPODENT, getTalukaCorrespondent),
    yield takeEvery(masterActions.GET_TALUKA, getTaluka),
    yield takeEvery(masterActions.GET_VILLAGE, getVillage),
    yield takeEvery(masterActions.GET_BOARD, getBoard),
    yield takeEvery(masterActions.GET_LANGUAGE, getLanguage),
    yield takeEvery(masterActions.GET_ALL_DISTRICT, getAllDistrict),
    yield takeEvery(masterActions.GET_MEDIUM_OF_INSTRUCTIONS, getmediumofinstructions),
    yield takeEvery(masterActions.GET_STANDARD, getStandard),
    yield takeEvery(masterActions.GET_PHONECODE, getPhonecode),
  ]);
};

const getGender = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/gender`));
    if (result) {
      yield put({
        type: masterActions.SET_GENDER,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getmediumofinstructions = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/mediumofinstructions`));
    if (result) {
      yield put({
        type: masterActions.SET_MEDIUM_OF_INSTRUCTIONS,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getState = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/state`));
    if (result) {
      yield put({
        type: masterActions.SET_STATE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getDistrict = function* (action) {
  try {


    console.log('action.payload get district----',action.payload);
    const stateid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/district/by_stateid/${stateid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_DISTRICT,
        payload: {
          permanent:result?.data?.result}
      });
    }
  } catch (err) {
    console.log(err);
  }
};
const getDistrictCorrespondent = function* (action) {
  try {
    console.log('action.payload get district correspon----',action.payload);

    const stateid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/district/by_stateid/${stateid}`)
    );
    console.log(result?.data?.result,'stateid');
    if (result) {
      yield put({
        type: masterActions.SET_DISTRICT_CORRESPONDENT,
        payload: result?.data?.result
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getTaluka = function* (action) {
  try {
    const districtid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/taluka/by_districtid/${districtid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_TALUKA,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getTalukaCorrespondent = function* (action) {
  try {
    const districtid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/taluka/by_districtid/${districtid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_TALUKA_CORRESPONDENT,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getVillage = function* (action) {
  try {
    const talukaid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/village/by_talukaid/${talukaid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_VILLAGE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getVillageCorrespondent = function* (action) {
  try {
    const talukaid = action.payload;
    const result = yield call(() =>
      axios.get(`${API_URL}/master/village/by_talukaid/${talukaid}`)
    );
    if (result) {
      yield put({
        type: masterActions.SET_VILLAGE_CORRESPONDENT,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getBoard = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/board`));
    if (result) {
      yield put({
        type: masterActions.SET_BOARD,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getLanguage = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/language`));
    if (result) {
      yield put({
        type: masterActions.SET_LANGUAGE,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getAllDistrict = function* () {
  try {
    const result = yield call(() => axios.get(`${API_URL}/master/district`));
    if (result) {
      yield put({
        type: masterActions.SET_ALL_DISTRICT,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getStandard = function* () {
  try {
    const result = yield call(() => 
      axios.get(`${API_URL}/master/standard`)
    )
    if (result) {
      yield put({
        type: masterActions.SET_STANDARD,
        payload: result?.data?.result,
      });


    }
  } catch (err) {
    console.log(err)
  }
}

const getPhonecode = function* () {
  try {
    const result = yield call(() => 
      axios.get(`${API_URL}/master/country`)
    )
    if (result) {
      yield put({
        type: masterActions.SET_PHONECODE,
        payload: result?.data?.result,
      });
    }
    // console.log(result);
  } catch (err) {
    console.log(err)
  }
}

export default masterSaga;
