import React from "react";
import { Button } from "@mui/material";
import ButtonLoader from "../buttonLoader";
import { cssProperties } from "../../utils/commonCssProperties";

export default function SubmitButton({ name, icon, handleSubmit, disabled }) {
  return (
    <>
      {name === "loader" ? (
        <Button
          size="small"
          varient="contained"
          style={{
            backgroundColor: "#F0F3F7",
            borderRadius: "40px",
            color: "#3F41D1",
            height: "40px"
          }}
        >
          <ButtonLoader />
        </Button>
      ) : (
        <Button
          size="small"
          varient="contained"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            backgroundColor: `${disabled ? "#E0E0E0" : "#E697AB"}`,
            color: `${disabled ? '#A3A3A3' : cssProperties?.fontcolor?.primary}`,
            border: "none",
            borderRadius: "40px",
            minWidth: "12rem",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "bold",
            height: "40px",
            padding:"24px"
          }}
          onClick={handleSubmit}
          disabled={disabled}
        >
          {name}
          {icon}
        </Button>
      )}
    </>
  );
}
