import React from "react";
import { Button } from "@mui/material";
import ButtonLoader from "../buttonLoader";

export default function SaveButton({ name, handleDraft, disabled }) {
  return (
    <>
      {name === "loader" ? (
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#F0F3F7",
            borderRadius: "40px",
            color: "#3F41D1",
            height:"40px"
          }}
        >
          <ButtonLoader />
        </Button>
      ) : (
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: disabled ? '#F0F3F7' : '#F0F3F7',
            cursor: disabled ? 'not-allowed' : 'pointer',
            borderRadius: "40px",
            boxShadow:"none",
            color: disabled ? "#797AF0 ":"#3F41D1",
            border: disabled ? '1px solid #B0B2FF' :"1px solid #3F41D1",
            fontSize: "14px",
            fontWeight: 500,
            height:"40px",
            padding:"24px"
          }}
          disabled={disabled}
          onClick={handleDraft}
        >
          {name}
        </Button>
      )}
    </>
  );
}
