import React from "react";
import {
  AppBar,
  Box,
  Breadcrumbs,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import { MdNavigateNext, MdPerson } from "react-icons/md";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineCustomerService } from "react-icons/ai";
export default function Header({ breadcrumbs,handleShow }) {

  

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);

  let userName = '';

  if (candidateTokenDetails && candidateTokenDetails.candidatename) {
    const shortendName = candidateTokenDetails.candidatename.trim().split(' ');
    if (shortendName.length > 0) {
      userName = shortendName[0];
    }
  }
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${cssProperties?.drawerWidth}px)`,
          ml: `${cssProperties?.drawerWidth}px`,
          border: "none",
          boxShadow: "none",
          backgroundColor: "#F7F7F7",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CommonBreadcrumb breadcrumbs={breadcrumbs} />
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            {/* <MdOutlineNotifications
              size={24}
              style={{
                height: "48px",
                width: "48px",
                borderRadius: "70px",
                border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                padding: "10px",
                color: `${cssProperties?.color?.gray}`,
              }}
            /> */}
            <AiOutlineCustomerService size={24} style={{
                        height: "48px",
                        width: "48px",
                        borderRadius: "70px",
                        border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                        backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                        padding: "10px",
                        color: `${cssProperties?.color?.gray}`,
                        cursor: 'pointer'
                    }}
                        onClick={() => handleShow('services')}
                    />
            <Link>
              <Box sx={{ position: "relative" }}>
                <MdPerson
                  size={24}
                  style={{
                    height: "48px",
                    width: "48px",
                    borderRadius: "70px",
                    border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                    backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                    padding: "10px",
                    color: `${cssProperties?.color?.primary}`,
                  }}
                />
                {/* <MdMenu
                  size={10}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    color: `${cssProperties?.color?.neutralgrey}`,
                    right: 0,
                    border: `0.5px solid ${cssProperties?.bordercolor?.secondary}`,
                    height: "16px",
                    width: "16px",
                    borderRadius: "14px",
                    padding: "3px",
                    backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.21)",
                  }}
                /> */}
              </Box>
            </Link>
           
            <Typography sx={{ fontSize: 16, fontWeight: 500, color: "black" }}>
              {userName}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

function CommonBreadcrumb({ breadcrumbs }) {
  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<MdNavigateNext size={16} />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
