import React, { useState } from "react";
import Index from "../components/registration/index";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import Sidebar from "../layouts/SideBar";
import { cssProperties } from "../utils/commonCssProperties";
import { Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import SupportModal from "../components/modals/supportModal";

export default function Registration() {
  const [showModal, setShowModal] = useState({ show: false, type: null });



  const breadcrumbs = [
    <Typography key="3" color="text.primary"
      sx={{
        display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
        fontSize: '14px',
        fontWeight: "bold",
        lineHeight: '20px',
        letterSpacing: '0.1px'
      }}
    >
      Registration Form<MdKeyboardArrowDown size={16} />
    </Typography>,
  ];
  return (

    <>
      <Layout>
        <Header handleShow={(type) => setShowModal({ show: true, type: type })}   breadcrumbs={breadcrumbs} />
        <Sidebar />
        <Index />
        <SupportModal showModal={showModal?.type === 'services' && showModal?.show} handleClose={() => setShowModal({ show: false, type: null })} />
      </Layout>
    </>
  )
}
