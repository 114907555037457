// export const API_URL = 'http://18.143.159.223:5000'; //old url

// export const API_URL = 'http://54.169.144.238:3001'; //TEST url

// export const API_URL = "http://34.93.35.227:3001"; //UAT url

// export const API_URL = 'http://localhost:3002';

//local URL

// export const BILLDESK_URL = 'http://localhost:3001';
// export const API_URL = 'http://localhost:3001';  //our_UAT url VIBGYOR
// export const FRONTEND_URL = 'http://localhost:3000';

// server URL

// export const BILLDESK_URL = 'http://35.207.240.74:3001';
// export const API_URL = 'http://35.207.240.74:3001';  //our_UAT url VIBGYOR
// export const FRONTEND_URL = 'http://vise-uat.hubblehox.ai';

// server URL updated

// export const BILLDESK_URL = 'https://vise-uat.hubblehox.ai/api';
// export const API_URL = 'https://vise-uat.hubblehox.ai/api';  //our_UAT url VIBGYOR
// export const FRONTEND_URL = 'https://vise-uat.hubblehox.ai';


// production URL updated

export const BILLDESK_URL = 'https://vise.vibgyorviva.com/api';
export const API_URL = 'https://vise.vibgyorviva.com/api';  //our_UAT url VIBGYOR
export const FRONTEND_URL = 'https://vise.vibgyorviva.com';
export const BILLDESK_ORDER_URL= 'https://api.billdesk.com/payments/ve1_2/embeddedsdk';
// export const BILLDESK_ORDER_URL = 'https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk';