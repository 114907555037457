import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import {useNavigate } from 'react-router-dom';
import authActions from '../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLoader from '../../common/buttonLoader';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { AiOutlineLeft } from "react-icons/ai";
import { cssProperties } from '../../utils/commonCssProperties';
import PageHeading from '../../layouts/pageHeading';
import { InputTrimAndLowercaseFunction, handleEmailValidation } from '../../utils/validations';
import BluePrimaryButton from '../../common/button/bluePrimaryButton';
import EnterMobilenoModal from './enterMobilenoModal';
import { MdOutlineCheckCircleOutline } from 'react-icons/md';

export default function Index() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { buttonLoader } = useSelector(state => state.commonReducer);

  const { forgotPass_MobileVerification } = useSelector(state => state.authReducer);

  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (data) => {

    dispatch({
      type: authActions.CANDIDATE_FORGETPASSWORD,
      payload: { navigate: navigate, data: data },
    });
    dispatch({
      type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION,
      payload: { validated: false }
    });

  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item md={8} sm={11}
          sx={{
            borderRadius: "8px",
            padding: '16px',
            backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
          }}
        >
          <Box sx={{ marginBottom: '12px' }}>
            <PageHeading page={'Forgot Password ?'} />
          </Box>
          {forgotPass_MobileVerification?.validated &&
            <Box sx={{ backgroundColor: 'rgba(34, 160, 107, 0.1)', border: '1px solid rgba(34, 160, 107, 1)', color: 'rgba(34, 160, 107, 1)', borderRadius: '8px', margin: '24px 0', padding: '12px' }}>
              <Typography variant="body2" sx={{ display: 'flex', gap: '6px' }}><MdOutlineCheckCircleOutline size={16} /> Email id sent to your Mobile number. Enter the received emailid to reset your password</Typography>
            </Box>
          }
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <TextField
                fullWidth
                variant='outlined'
                size="small"
                label={
                  <span>
                    Email id
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  </span>
                }
                inputProps={{ maxLength: 50 }}
                error={!!errors.email}
                {...register("email", {
                  required: "Emailid  is required ",
                  validate: handleEmailValidation
                })}
                onInput={InputTrimAndLowercaseFunction}
                helperText={errors.email?.message}
                FormHelperTextProps={{
                  style: { margin: 0 },
                }}
              />
              <Box display="flex" justifyContent="flex-end" alignItems={"flex-end"}  sx={{
                marginTop: '12px',
              }}
              >
                <span
                style={{
                  color: `${cssProperties?.bordercolor?.primary2blue}`,
                  fontWeight: 500,
                  fontSize: '14px', cursor: 'pointer'

                }}
                onClick={() => setShowModal(true)}
                
                >
                Forgot Email?
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", color: `${cssProperties?.color?.primary}`, cursor: 'pointer' }} onClick={() => navigate(`/login`)}>
                <AiOutlineLeft size={14} />
                <Typography variant='body1'>Back to Login </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BluePrimaryButton
                type="submit"
                title={buttonLoader ? <ButtonLoader /> : "Submit"}
                handleSubmit={handleSubmit(onSubmit)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EnterMobilenoModal showModal={showModal} handleClose={() => setShowModal(false)} />
    </Box>
  );
}
