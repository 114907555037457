import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Box,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import ButtonLoader from "../../common/buttonLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  AlphabetsValidation,
  InputTrimAndLowercaseFunction,
  InputTrimFunction,
  MobileNumberFirstNumberValidation,
  MobileNumberValidation,
  handleEmailValidation,
  passwordValidation,
} from "../../utils/validations";
import authActions from "../../redux/auth/actions";
import PageHeading from "../../layouts/pageHeading";
import { cssProperties } from "../../utils/commonCssProperties";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import masterActions from "../../redux/master/action";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {
  getCountryCodeFromCountryName
 } from "country-codes-flags-phone-codes";
import TermsAndCond from "../modals/termAndCondModal";
import dayjs from "dayjs";

export default function Index() {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ischecked, setIsChecked] = useState(false);
  // console.log(ischecked);
  const [error, setError] = useState("");

  const { buttonLoader } = useSelector((state) => state.commonReducer);

  const { phonecode } = useSelector((state) => state.masterReducer);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useForm();
  // console.log(errors);
  const [passState, setPassState] = useState(false);
  const [passState2, setPassState2] = useState(false);
  const [isFocus,setIsFocus] = useState({});

  const handleTermsandCondition = (e) => {
    const value = e.target.checked;
    setIsChecked(value);
    if (value === true) setError("");
  };

  const [dob, setDob] = useState(null); // State to store the selected/entered date
// console.log("usestate dob---",dob);

  // const onSubmit = (data) => {
  //   console.log("dob---",dob);
  //   if (ischecked) {
  //     dispatch({
  //       type: authActions.CANDIDATE_SINUP,
  //       payload: {
  //         navigate: navigate,
  //         data: {
  //           ...data,
  //           dob:dob,
  //           mobileno: getValues("phonecode") + getValues("mobileno"),
  //         },
  //       },
  //     });
  //     setError("");
  //   } else if (!ischecked) {
  //     setError(
  //       "Please select the checkbox to agree to the terms and conditions."
  //     );
  //   }
  // };


  const onSubmit = (data) => {
    const formattedDob = dob?.format('YYYY-MM-DD');
    // console.log(data);
    if (ischecked) {
      dispatch({
        type: authActions.CANDIDATE_SINUP,
        payload: {
          navigate: navigate,
          data: {
            ...data,
            dob: formattedDob,
            mobileno: getValues("phonecode") + getValues("mobileno"),
          },
        },
      });
      setError("");
    } else if (!ischecked) {
      setError("Please select the checkbox to agree to the terms and conditions.");
    }
  };







  const handlePasswordChange = async (e) => {
    const { name } = e.target;

    await trigger(name);
  };

  // console.log(phonecode?.find((val) => val?.countrycode === "IND")?.phonecode);

  useEffect(() => {
    dispatch({ type: masterActions.GET_PHONECODE });
  }, []);

  useEffect(() => {
    if (phonecode?.length > 0) {
      setValue(
        "phonecode",
        phonecode?.find((val) => val?.countrycode === "IND")?.phonecode
      );
    }
  }, [phonecode]);
  // console.log({ phonecode: watch("phonecode") });


const handleFocus = (field)=>{setIsFocus((prev)=>{
    // console.log("filed",field)
    
    return {...prev,[field]:true}})}

const handleBlur = (field)=>{ setIsFocus((prev)=>{return {...prev,[field]:false}})}


const [dobError, setdobError] = useState('');


const handleDateChange = (date) => {
  // console.log('date---',date);
  setDob(date);
  setError('');

  if (date && date.isAfter(today)) {
    setError('Date cannot be in the future.');
  } else if (date && date.isAfter(tenYearsAgo)) {
    setError('Date must be at most 10 years old.');
  }
};

// const handleDateChange = (date) => {
//   const adjustedDate = date?.startOf('day');
//   setDob(adjustedDate);
//   setError('');

//   if (adjustedDate && adjustedDate.isAfter(today)) {
//     setError('Date cannot be in the future.');
//   } else if (adjustedDate && adjustedDate.isAfter(tenYearsAgo)) {
//     setError('Date must be at most 10 years old.');
//   }
// };

const today = dayjs();
// console.log('today---',);
const tenYearsAgo = today.subtract(10, 'year');

const handleManualDateChange = (event) => {

  
  const inputDate = dayjs(event.target.value, 'DD/MM/YYYY', true);

  // console.log('inputDate-------',inputDate);
  setDob(inputDate);

  if (!inputDate.isValid()) {
    setError('Invalid date format.');
  } else if (inputDate.isAfter(today)) {
    setError('Date cannot be in the future.');
  } else if (inputDate.isAfter(tenYearsAgo)) {
    setError('Date must be more than 10 years old.');
  } else {
    setError('');
  }
};
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "24px auto",
      }}
    >
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          md={8}
          sm={11}
          sx={{
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
          marginTop:5
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginBottom: "24px" }}>
              <PageHeading page={"Student Sign Up"} />
            </Box>
            <Grid container spacing={"20px"} >
              <Grid item xs={12} md={6}>
                <TextField sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}  
                  {...register("firstname", {
                    required: "First name is required",
                  })}
                  onInput={AlphabetsValidation}
                  inputProps={{ maxLength: 50 }}
                  label={
                    <span>
                      First name
                      <span className="error" style={{ color: "red" }}>
                        
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter first name"
                  fullWidth
                  error={!!errors.firstname}
                  helperText={errors.firstname ? errors.firstname.message : "" }
                  onFocus={()=>handleFocus("name")}
                  onBlur={()=>handleBlur("name")}
                  FormHelperTextProps={{
                    style: { margin: 0 ,color:errors.firstname?"#E6393E" : "#666666",fontSize:"12px"}
                  }}
                />
               
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}   
                  
                  {...register("lastname", {
                    required: "Last name is required",
                  })}
                  onInput={AlphabetsValidation}
                  inputProps={{ maxLength: 50 }}
                  label={
                    <span>
                      Last name
                      <span className="error" style={{ color: "red" }}>
                        
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter last name"
                  fullWidth
                  error={!!errors.lastname}
                  helperText={errors.lastname ? errors.lastname.message : ""}
                  FormHelperTextProps={{
                    style: { margin: 0 ,color:errors.lastname?"#E6393E" : "#666666",fontSize:"12px"}
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}   
                  
                  {...register("emailid", {
                    required: "Email id is required",
                    validate: handleEmailValidation,
                  })}
                  onInput={InputTrimAndLowercaseFunction}
                  inputProps={{ maxLength: 50 }}
                  label={
                    <span>
                      Email id
                      <span className="error" style={{ color: "red" }}>
                        
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter email id"
                  fullWidth
                  error={!!errors.emailid}
                  helperText={errors.emailid ? errors.emailid.message : ""}
                  FormHelperTextProps={{
                    style: { margin: 0 ,color:errors.emailid?"#E6393E" : "#666666",fontSize:"12px"}
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2.7}>
              <FormControl
              sx={{ 
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#3F41D1', // Label color when focused
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#3F41D1', // Border color when focused
                },minWidth: "5rem",
              }}   
 fullWidth
 >
         <InputLabel>Country code</InputLabel><Select
          label="Country code"
          // name="phonecode"
          // id="phonecode"
          {...register("phonecode", {
           required: "Phone code is required",
          })}
          value={watch("phonecode") || ""}
          // value={
          //  phonecode?.find((val) => val?.countrycode === "IND")
          //   ?.phonecode || ""
         // }
         >
          <MenuItem value="" disabled>
           Select one
          </MenuItem>
          {phonecode?.map((val, i) => (
           <MenuItem key={i} value={val?.phonecode}>
            
            {val?.countryname + "  "+"+"+`(${ val?.phonecode.replace(/-/g,'')})`+ "  " }
            <span style={{marginLeft:"10px", width:"18px"}} className={`fi fi-${getCountryCodeFromCountryName(val?.countryname)?.toLowerCase()}`}></span>

           </MenuItem>
          ))}
         </Select>
        </FormControl>
                {errors?.phonecode && (
                  <FormHelperText sx={{color:"#d32f2f"}}>{errors.phonecode.message}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={3.3} >
                <TextField sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}   
                  
                  fullWidth
                  {...register("mobileno", {
                    required: "Mobile number is required",
                    minLength: {
                      value: 10,
                      message: "Enter valid Mobile number",
                    },
                    validate: MobileNumberFirstNumberValidation,
                  })}
                  onInput={MobileNumberValidation}
                  inputProps={{ maxLength: 10 }}
                  label={
                    <span>
                      Mobile no
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter Mobile number"
                  error={!!errors.mobileno}
                  helperText={errors.mobileno ? errors.mobileno.message : ""}
                  FormHelperTextProps={{
                    style: { margin: 0 ,color:errors.mobileno?"#E6393E" : "#666666",fontSize:"12px"}
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}   
                  
                  {...register("temppassword", {
                    validate: { passwordValidation },
                    required: "Password is required",
                  })}
                  onKeyUp={handlePasswordChange}
                  onInput={InputTrimFunction}
                  inputProps={{ maxLength: 16 }}
                  type={passState ? "text" : "password"}
                  label={
                    <span>
                      Password
                      <span className="error" style={{ color: "red" }}>
                        
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter password"
                  fullWidth
                  error={!!errors.temppassword}
                  helperText={
                    errors.temppassword ? errors.temppassword.message : ""
                  }
                  FormHelperTextProps={{
                    style: { margin: 0 ,color:errors.temppassword?"#E6393E" : "#666666",fontSize:"12px"}
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        tabIndex={"-1"}
                          onClick={() => setPassState(!passState)}
                          sx={{ paddingRight: "0" }}
                        >
                          {passState ? (
                            <FiEyeOff size={24} />
                          ) : (
                            <FiEye size={24} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}   
                  
                  {...register("confirmpassword", {
                    required: "Confirm password is required",
                    validate: (val) => {
                      if (watch("temppassword") !== val) {
                        return "Passwords do not match";
                      }
                    },
                  })}
                  onInput={InputTrimFunction}
                  onKeyUp={handlePasswordChange}
                  inputProps={{ maxLength: 16 }}
                  type={passState2 ? "text" : "password"}
                  label={
                    <span>
                      Confirm Password
                      <span className="error" style={{ color: "red" }}>
                        
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter password"
                  fullWidth
                  error={!!errors.confirmpassword}
                  helperText={
                    errors.confirmpassword ? errors.confirmpassword.message : ""
                  }
                  FormHelperTextProps={{
                    style: { margin: 0 ,color:errors.confirmpassword?"#E6393E" : "#666666",fontSize:"12px"}
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                        tabIndex={"-1"}
                          onClick={() => setPassState2(!passState2)}
                          sx={{ paddingRight: "0" }}
                        >
                          {passState2 ? (
                            <FiEyeOff size={24} />
                          ) : (
                            <FiEye size={24} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="DD/MM/YYYY"
          label = "Date of Birth"
          disableFuture
          maxDate={tenYearsAgo}
          value={dob}
          onChange={(date) => handleDateChange(date)}
          onOpen={() => handleFocus("dob")}
          onClose={() => handleBlur("dob")}
          renderInput={(params) => (
            <TextField
              {...params}
              // inputProps={{placeholder:"Date of birth"}}
              error={!!dobError}
              helperText={dobError || 'Student’s DOB as per the Birth Certificate'}
              onChange={handleManualDateChange}
              sx={{
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#3F41D1', // Label color when focused
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#3F41D1', // Border color when focused
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </Grid>
            </Grid>
            <Grid
              sx={{ display: "flex", alignItems: "center", mt: 1, gap: "4px" }}
            >
              <Checkbox size="40"
                checked={ischecked}
                onChange={(e) => handleTermsandCondition(e)}
                sx={{ m: 0, p: 0 }}
              />
              <Typography>
                Agree to 
                <span
                  onClick={() =>{handleOpenModal()}}
                  style={{
                    color: `#3F41D1`,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Terms and Condition
                </span>
              </Typography>
            </Grid>
            {error && (
              <small style={{ color: `${cssProperties?.color?.failure}` }}>
                {error}
              </small>
            )}

            <Box sx={{ textAlign: "center", marginTop: "24px" }}>
              <BluePrimaryButton
                type="submit"
                title={buttonLoader ? <ButtonLoader /> : "Signup"}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Typography variant="body2">Already have an account?</Typography>
              <Link
                to={`/login`}
                style={{
                  marginLeft: 4,
                  fontWeight: 500,
                  color: `#3F41D1`,
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                LOGIN
              </Link>
            </Box>
          </form>
          <TermsAndCond showModal={showModal} handleClose={handleCloseModal} />

        </Grid>
      </Grid>
    </Box>
  );
}

