import { AppBar, Box, Toolbar, Breadcrumbs, Stack, Typography } from "@mui/material";
import React from "react";
import { cssProperties } from "../utils/commonCssProperties";
import { MdNavigateNext } from "react-icons/md";
import Logo2 from '../assets/images/landinPage/vibgyor_schollorship_logo.png';
// import Logo2 from '../assets/images/navbar/image 9.png';
import { PiVideoLight } from "react-icons/pi";
import { AiOutlineCustomerService } from "react-icons/ai";
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from "react-router-dom";


export default function BeforeLoginHeader(props) {
const navigate =useNavigate()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { handleShow, breadcrumbs } = props;

    return (
        <AppBar
            position="fixed"
            sx={{
                border: 'none',
                boxShadow: 'none',
                backgroundColor: "#F7F7F7",
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Box sx={{ width: `${cssProperties?.drawerWidth}px`,cursor:"pointer" }} onClick={()=>navigate('/')}>
                        <img style={{ width: "auto", height: "42px" }} src={Logo2} alt="logo" />
                    </Box>
                    <Stack spacing={2} pl={3}>
                        <Breadcrumbs
                            separator={<MdNavigateNext size={16} />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                </Box>
                <Box>
                    <Typography
                        key="1"
                        color="text.primary"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: cssProperties?.fontcolor?.primary,
                            fontSize: isMobile ? '20px' : '34px', // Adjust font size for mobile
                            fontWeight: 400,
                            
                        }}
                    >
                        VIBGYOR Scholarship
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: '4px' }}>
                    {/* <PiVideoLight size={24} style={{
                        height: "48px",
                        width: "48px",
                        borderRadius: "70px",
                        border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                        backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                        padding: "10px",
                        color: `${cssProperties?.color?.gray}`,
                        cursor: 'pointer'
                    }}
                        onClick={() => handleShow('video')}
                    /> */}
                    {/* above use in the vibgyor */}
                    {/* <PiQuestionLight size={24} style={{
                        height: "48px",
                        width: "48px",
                        borderRadius: "70px",
                        border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                        backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                        padding: "10px",
                        color: `${cssProperties?.color?.gray}`,
                        cursor: 'pointer'
                    }} /> */}
                    <AiOutlineCustomerService size={24} style={{
                        height: "48px",
                        width: "48px",
                        borderRadius: "70px",
                        border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                        backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                        padding: "10px",
                        color: `${cssProperties?.color?.gray}`,
                        cursor: 'pointer'
                    }}
                        onClick={() => handleShow('services')}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    )
}

