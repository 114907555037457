import React from "react";
import Logo2 from "../assets/images/landinPage/vibgyor_schollorship_logo.png";
// import Logo2 from "../assets/images/navbar/image 9.png";
import { CiLogout } from "react-icons/ci";
import { useDispatch } from "react-redux";
import authActions from "../redux/auth/actions";
import { Box, AppBar, Divider } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { cssProperties } from "../utils/commonCssProperties";
import { PiNotePencilThin } from "react-icons/pi";
import { BiReceipt } from "react-icons/bi";

const items = [
  {
    name: "Registration Form",
    icon: <PiNotePencilThin size={24} />,
    path: `/home`,
  },
  {
    name: "Payment Recipt",
    icon: <BiReceipt size={24} />,
    path: `/payment-recipt`,
  },
];

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname, search } = location;

  const handleLogout = () => {
    dispatch({ type: authActions.CANDIDATE_LOGOUT });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "85px",
        height: "100vh",
        left: 0,
        backgroundColor: "#FFFFFF",
        scrollbarWidth: "thin",
        justifyContent: "space-between",
        padding: "24px 0px 24px 12px",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          cursor: "pointer",
          paddingRight: "12px",
        }}  
        onClick={() => navigate(`/home`)}
      >
        <img style={{ width: "auto", height: "20px" }} src={Logo2} alt="logo" />
        <Divider sx={{ width: "70%", borderWidth: "1px" }} />
      </Box>

      <Box
        sx={{
          height: "75vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            margin: "10px 0px",
            paddingRight: "12px",
          }}
        >
          {items.map((item, index) => {
            const isActive = pathname === item.path || pathname + search === item.path || (Array.isArray(item.subPath) && item.subPath.some(subPath => (pathname + search).includes(subPath)));
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  cursor: item.disabled ? 'not-allowed' : 'pointer',
                  padding: "0px 0px",
                  "& .item-icon": {
                    backgroundColor: isActive
                      ? `${cssProperties?.hoverbgcolor?.sidebar}`
                      : "inherit",
                    borderRadius: "52px",
                    color: isActive
                      ? `${cssProperties?.color?.primary}`
                      : `${cssProperties?.color?.textsecondary}`,
                  },
                  "& .item-name": {
                    color: isActive
                      ? `${cssProperties?.color?.primary}`
                      : `${cssProperties?.color?.textsecondary}`,
                  },
                  "&:hover": {
                    "& .item-icon": {
                      backgroundColor: item.disabled ? null : "#E1E8F5",
                      borderRadius: "52px",
                      padding: "4px, 16px, 4px, 16px",
                    },
                    "& .item-icon, & .item-name": {
                      color: item.disabled ? null : "#3F41D1",
                    },
                  },
                }}
                onClick={() => (item.path ? navigate(item.path) : null)}
              >
                <Box
                  className={"item-icon"}
                  sx={{ color: "#666666", width: "70%", textAlign: "center" }}
                  key={index}
                >
                  {item.icon}
                </Box>

                <Box
                  className={"item-name"}
                  sx={{
                    color: "#666666",
                    fontSize: "12px",
                    textWrap: "wrap",
                    textAlign: "center",
                    font: "Graphik",
                  }}
                >
                  {item.name}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: "#3F41D1",
          paddingRight: "12px",
        }}
      >
        <Box
          className={"item-icon"}
          sx={{ cursor: "pointer" }}
          onClick={() => handleLogout()}
        >
          <CiLogout
            style={{
              "&:hover": {
                backgroundColor: "#E1E8F5",
                borderRadius: "10%",
                "& .item-icon, & .item-name": {
                  color: "#3F41D1",
                },
              },
            }}
            size={24}
            color="#666666"
          />
        </Box>
        <Box
          className={"item-name"}
          sx={{
            color: "#666666",
            fontSize: "12px",
            textWrap: "wrap",
            textAlign: "center",
            font: "Graphik",
            cursor: "pointer"
          }}
        >
          {"Logout"}
        </Box>
      </Box>
    </AppBar>
  );
}

export default Sidebar;
