import { put, call, all, takeEvery, delay, select } from "redux-saga/effects";
import commonActions from "../common/actions";
import candidatesActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import masterActions from "../master/action";
import Cookies from 'js-cookie';

const candidatesSaga = function* () {
  yield all([
    yield takeEvery(
      candidatesActions.PROFILE_PERSONAL_DETAILS_CREATION,
      profilePersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.GET_PROFILE_PERSONAL_DETAILS,
      getProfilePersonalDetails
    ),
    yield takeEvery(
      candidatesActions.PROFILE_ADDRESS_DETAILS_CREATION,
      addressPersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_UPLOADS_DETAILS_CREATION,
      uploadsPersonalDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.GET_ADDRESS_PERSONAL_DETAILS,
      getAddressPersonalDetails
    ),
    yield takeEvery(
      candidatesActions.PROFILE_PERSONAL_DETAILS_DRAFT_CREATION,
      personalDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_ADDRESS_DETAILS_DRAFT_CREATION,
      personalAddressDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_OTHER_DETAILS_CREATION,
      otherDetailsCreation
    ),
    yield takeEvery(
      candidatesActions.PROFILE_OTHER_DETAILS_DRAFT_CREATION,
      otherDetailsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.GET_OTHER_PERSONAL_DETAILS,
      getOtherDetails
    ),
    yield takeEvery(
      candidatesActions.PROFILE_UPLOADS_DETAILS_DRAFT_CREATION,
      uploadsDraftCreation
    ),
    yield takeEvery(
      candidatesActions.GET_UPLOAD_PERSONAL_DETAILS,
      getUploadDetails
    ),
    yield takeEvery(candidatesActions.GET_PINCODE, getPincode),

    yield takeEvery(candidatesActions.GET_FULL_CANDIDATE_DETAILS, getFullCandidateDetails),
   
    yield takeEvery(candidatesActions.GET_PINCODE_CORRESPONDENCE, getPincodeCorrespondence),
   
    yield takeEvery(candidatesActions.GET_REFERRAL_CODE, getReferralCode),
    yield takeEvery(candidatesActions.REMOVE_REFERRAL_CODE, removeReferralCode),
  ]);
};

const getProfilePersonalDetails = function* () {
  const candidateid = yield select(
    (state) => state.authReducer?.candidateTokenDetails?.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {

    // console.log('candidateid saga',candidateid);
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/${candidateid}`)
    );
    // console.log(result,'candidate saga')
    if (result) {
      yield put({
        type: candidatesActions?.SET_PROFILE_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    // console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getAddressPersonalDetails = function* () {
  const candidateidid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/address/${candidateidid}`)
    );
    // console.log(result,'address saga')
    if (result) {
      yield put({
        type: candidatesActions.SET_ADDRESS_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
      yield put({ type: masterActions.GET_STATE });
      if (result?.data?.result[0]?.pstateid) {
        yield put({
          type: masterActions.GET_DISTRICT,
          payload: result?.data?.result[0]?.pstateid,
        });
      }
      if (result?.data?.result[0]?.pdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA,
          payload: result?.data?.result[0]?.pdistrictid,
        });
      }
      if (result?.data?.result[0]?.ptalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE,
          payload: result?.data?.result[0]?.ptalukaid,
        });
      }
      if (result?.data?.result[0]?.cstateid) {
        yield put({
          type: masterActions.GET_DISTRICT_CORRESPODENT,
          payload: result?.data?.result[0]?.cstateid,
        });
      }
      if (result?.data?.result[0]?.cdistrictid) {
        yield put({
          type: masterActions.GET_TALUKA_CORRESPODENT,
          payload: result?.data?.result[0]?.cdistrictid,
        });
      }
      if (result?.data?.result[0]?.ctalukaid) {
        yield put({
          type: masterActions.GET_VILLAGE_CORRESPODENT,
          payload: result?.data?.result[0]?.ctalukaid,
        });
      }
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    // console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const profilePersonalDetailsCreation = function* (data) {
  const candidateidid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/candidate/registration/${candidateidid}`,
        payload.data
      )
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
    }
    if(result?.data?.statusCode === 200){
      navigate(`/home?page=address`);
    }
    
  } catch (err) {
    // console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/personaldetails`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    // console.log(err);
  }
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const addressPersonalDetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/address`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: null,
          show: false,
          message: null,
        },
      });
      
    }
    if(result?.data?.statusCode === 200){
      navigate("/home?page=educationdetails");
    }
    
  } catch (err) {
    // console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const personalAddressDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/address`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const otherDetailsCreation = function* (data) {
  const { payload } = data;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/otherdetails`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if(result?.data?.statusCode === 200){
        navigate("/home?page=uploads");
      }
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const otherDetailsDraftCreation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/otherdetails`, payload.data)
    );
    if (result) {
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const getOtherDetails = function* () {
  const candidateidid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/candidate/registration/otherdetails/${candidateidid}`
      )
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_OTHER_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
      yield put({
        type: masterActions.GET_DISTRICT,
        payload: result?.data?.result[0].stateofschoolid,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getUploadDetails = function* () {
  const candidateidid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/uploads/${candidateidid}`)
    );
    if (result) {
      yield put({
        type: candidatesActions.SET_UPLOAD_PERSONAL_DETAILS,
        payload: result?.data?.result,
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const uploadsPersonalDetailsCreation = function* (action) {
  const candidateidid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  const { payload } = action;
  const { navigate } = payload;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const formData = new FormData();
    const photoFile = new File(
      [payload.data.photofilename],
      `${candidateidid}_photo.jpeg`,
      { type: "image/jpeg" }
    );
    const signFile = new File(
      [payload.data.signfilename],
      `${candidateidid}_signature.jpeg`,
      {
        type: "image/jpeg",
      }
    );
    const documentFile = new File(
      [payload.data.documentfilename],
      payload.data.documentfilename?.name,
      {
        type: "application/pdf",
      }
    );
    console.log('documentFile----',payload?.data);
    const operation = payload.data.operation;
    const candidateid = payload.data.candidateid;
    const documentid = payload.data.documentid;
    const status = payload.data.status;

    formData.append("photofilename", photoFile);
    formData.append("signfilename", signFile);
    formData.append("documentfilename", documentFile);
    formData.append("operation", operation);
    formData.append("candidateid", candidateid);
    formData.append("documentid", documentid);
    formData.append("status", status);
    console.log(formData,'formData');

    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/registration/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
    if (result?.data?.statusCode === 200) {
      navigate("/home?page=preview");
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: null,
        show: false,
        message: null,
      },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const uploadsDraftCreation = function* (action) {
  const candidateidid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  const { payload } = action;
  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: true });
  try {
    const formData = new FormData();
    const photoFile = new File(
      [payload.data.photofilename],
      `${candidateidid}_photo.jpeg`,
      { type: "image/jpeg" }
    );
    const signFile = new File(
      [payload.data.signfilename],
      `${candidateidid}_signature.jpeg`,
      {
        type: "image/jpeg",
      }
    );
    const documentFile = new File(
      [payload.data.documentfilename],
      `${candidateidid}_document.pdf`,
      {
        type: "application/pdf",
      }
    );

    const operation = payload.data.operation;
    const candidateid = payload.data.candidateid;
    const documentid = payload.data.documentid;
    const status = payload.data.status

    formData.append("photofilename", photoFile);
    formData.append("signfilename", signFile);
    formData.append("documentfilename", documentFile);
    formData.append("operation", operation);
    formData.append("candidateid", candidateid);
    formData.append("documentid", documentid);
    formData.append("status", status);

    const result = yield call(() =>
      axios.post(`${API_URL}/candidate/draft/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );

    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });

    yield delay(2000);
  } catch (err) {
    console.log(err);
  }

  yield put({ type: commonActions.SET_DRAFT_BUTTON_LOADER, payload: false });
};

const getPincode = function* (data) {
  try {
    const { payload } = data;
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/pincode/${payload?.value}`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: candidatesActions.SET_PINCODE,
        payload: result?.data?.result,
      });
      if (result?.data?.result[0]?.stateid) {
        yield put({
          type: masterActions.GET_DISTRICT,
          payload: result?.data?.result[0]?.stateid,
        });
      }
      if (result?.data?.result[0]?.districtid) {
        yield put({
          type: masterActions.GET_TALUKA,
          payload: result?.data?.result[0]?.districtid,
        });
      }
      if (result?.data?.result[0]?.talukaid) {
        yield put({
          type: masterActions.GET_VILLAGE,
          payload: result?.data?.result[0]?.talukaid,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const getPincodeCorrespondence = function* (data) {
  try {
    const { payload } = data;
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/pincode/${payload?.value}`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: candidatesActions.SET_PINCODE_CORRESPONDENCE,
        payload: result?.data?.result,
      });
      if (result?.data?.result[0]?.stateid) {
        yield put({
          type: masterActions.GET_DISTRICT,
          payload: result?.data?.result[0]?.stateid,
        });
      }
      if (result?.data?.result[0]?.districtid) {
        yield put({
          type: masterActions.GET_TALUKA,
          payload: result?.data?.result[0]?.districtid,
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

const getFullCandidateDetails = function* (data) {
  const candidateid = yield select(
    (state) => state?.authReducer?.candidateTokenDetails?.candidateid
  );
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/candidate/registration/details/${candidateid}`)
    );
    // console.log("check-1",result)
    yield put({
      type: candidatesActions?.SET_FULL_CANDIDATE_DETAILS,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : []
    });
    if (result?.data?.result[0]?.stateid) {
      yield put({
        type: masterActions?.GET_STATE,
        payload: result?.data?.result[0]?.stateid,
      });
    }
    console.log(result);
  } catch (err) {
    console.log(err);
  };
};
const getReferralCode = function* (data) {

const {payload} = data;

  const candidateid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/referral/setreferral`,{candidateid:candidateid,referralCode:payload}));
    // console.log("check-1",result)
    yield put({
      type: candidatesActions.SET_REFERRAL_CODE,
      payload: result?.data?.statusCode === 200 ? result?.data?.data?.referralCode : result?.data?.statusCode === 400 ? 'invalid' : "" 
    });
   
    console.log('saga result for referral code---',result);

  } catch (err) {
    console.log(err);
  };
};

const removeReferralCode = function* (data) {

const {payload} = data;

  const candidateid = yield select(
    (state) => state.authReducer.candidateTokenDetails.candidateid
  );
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/referral/removereferral`,{candidateid:candidateid,referralCode:payload}));
    // console.log("check-1",result)
    yield put({
      type: candidatesActions.SET_REFERRAL_CODE,
      payload: null
    });
   
    console.log('saga result for referral code remove---',result);

  } catch (err) {
    console.log(err);
  };
};


export default candidatesSaga;
