import { Grid } from "@mui/material";
import React from "react";
import { cssProperties } from "../../utils/commonCssProperties";
import { useNavigate } from "react-router-dom";

export default function Index (){
  const navigate = useNavigate();
  return(
    <>
    <Grid mt={8}>
      <p style={{padding:"24px",textAlign:"justify"}}>
      <h3><b>VIBGYOR Scholarship Team Terms and Conditions</b></h3>
      <br/>
      1. Eligibility Criteria
      Applicants must be enrolled in a full-time undergraduate or postgraduate program at an accredited institution. The scholarship is open to students of all nationalities. Applicants must demonstrate academic excellence, community involvement, and financial need.
      <br/>
      2. Application Process
      To apply for the VIBGYOR Scholarship, candidates must complete the online application form and submit all required documents, including academic transcripts, letters of recommendation, and a personal statement. Applications must be submitted by the specified deadline. Late or incomplete applications will not be considered.
      <br/>
      3. Selection Process
      The VIBGYOR Scholarship Team will review all applications and select recipients based on academic merit, financial need, and personal achievements. Shortlisted candidates may be invited for an interview. The decision of the selection committee is final and cannot be appealed.
      <br />
      4. Scholarship Award
      The scholarship award will be disbursed directly to the recipient's educational institution to cover tuition fees and other related expenses. The scholarship amount and duration will be specified in the award letter. Recipients must maintain satisfactory academic progress to continue receiving the scholarship.
      <br/>
      5. Termination of Scholarship
      The VIBGYOR Scholarship Team reserves the right to terminate the scholarship if the recipient fails to maintain the required academic standards, engages in misconduct, or violates any of the terms and conditions. In such cases, the recipient may be required to repay the scholarship funds.
      <br/>
      6. Data Privacy
      The VIBGYOR Scholarship Team is committed to protecting the privacy of applicants. All personal information provided during the application process will be used solely for the purpose of scholarship selection and administration. Information will not be shared with third parties without the applicant's consent.
      The VIBGYOR Scholarship Team reserves the right to amend these terms and conditions at any time. Any changes will be communicated to applicants and recipients in a timely manner. Continued participation in the scholarship program constitutes acceptance of the revised terms and conditions.<span onClick={ ()=>navigate('/signup')} style={{color: `${cssProperties?.bordercolor?.primary2blue}`,cursor:"pointer", textDecoration:"underline"}}>Back to Sign Up</span>
      </p>
    </Grid>
    </>
  )
}