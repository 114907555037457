import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useSelector } from "react-redux";

export default function ToastMessage() {

    const { alert } = useSelector(state => state.commonReducer);

    return (
        <>
            <Snackbar
                open={alert?.show === true && alert?.status === 'success'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    severity="success"
                    sx={{ width: '100%', backgroundColor: 'rgb(176 240 176)' }}
                >
                    {alert?.message}
                </Alert>
            </Snackbar>
            <Snackbar
                open={alert?.show === true && alert?.status === 'failed'}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    severity="error"
                    sx={{ width: '100%', backgroundColor: 'rgb(241 206 206)' }}
                >
                    {alert?.message}
                </Alert>
            </Snackbar>
        </>
    )
};