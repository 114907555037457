
import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import CancelButton from '../../common/button/cancelButton'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px'
};

export default function SupportModal(props) {

    const { handleClose, showModal } = props;

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography  sx={{fontSize:"20px", color: `#212121`, fontWeight: '500', marginBottom: '24px' }}>Customer Support</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap:   '12px', alignItems: 'center' }}>
                    <Typography sx={{fontSize:"16px", fontWeight: 400,color:"#212121" }}>Need Help? For any assistance, reach out to our support
                        team at <a href="mailto:vise@vgos.org">vise@vgos.org</a> or call us at 600 3000 700. Available from 8AM to 6PM, Monday to
                        Friday</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                    <CancelButton variant={"outlined"} handleBack={handleClose} title={'Close'} />
                </Box>
            </Box>
        </Modal >
    )
};
