

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography, TextField, IconButton, InputAdornment, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import { InputTrimAndLowercaseFunction, InputTrimFunction, handleEmailValidation, passwordValidation } from "../../utils/validations";
import ButtonLoader from "../../common/buttonLoader";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { cssProperties } from '../../utils/commonCssProperties';
import PageHeading from "../../layouts/pageHeading";
import BluePrimaryButton from '../../common/button/bluePrimaryButton';

export default function Index() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { buttonLoader } = useSelector((state) => state.commonReducer);

    const { register, handleSubmit, formState: { errors },trigger } = useForm();

    const [passState, setPassState] = useState(false);

    const onSubmit = (data) => {
        dispatch({
            type: authActions.CANDIDATE_LOGIN,
            payload: { navigate: navigate, data: data },
        });
    };


    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: '26px'
                }}
            >
                <Grid container sx={{ display: 'flex', justifyContent: 'center', width:"80%" }} spacing={2}>
                    <Grid item md={8} sm={12} >
                        <Box
                            sx={{
                                borderRadius: "8px",
                                padding: '34px',
                                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                                fontWeight: 600,
                                height:'100%'
                                
                            }}
                        >
                            <Box sx={{ marginBottom: '24px', display: 'flex', justifyContent: 'center' }}>
                                <PageHeading page={'INSTRUCTIONS'} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                                <Typography variant="body1">
                                    First Time Here? Click <Link
                                    to={`/signup`}
                                    style={{
                                        marginLeft: 4,
                                        fontWeight: 600,
                                        color: `${cssProperties?.bordercolor?.primary2blue}`,
                                        fontSize: '14px',
                                        textDecoration: "none",
                                    }}
                                >
                                    Click Here
                                </Link> to create your new account
                                </Typography>
                                <Typography variant="body1">
                                    Keep your Recent Passport Size Photo, Scanned Signature and School ID/Adhaar Card or Birth Certificate ready before filling registration form.
                                </Typography>
                                <Typography variant="body1">
                                    Already Registered? Enter your Registered Email and password to login.
                                </Typography>
                                <Typography variant="body1">
                                    Always log out of your account after you finish your session to keep your information secure.
                                </Typography>
                            </Box>
                        </Box>

                    </Grid>
                    <Grid item md={4} sm={12}>
                        <Box
                            sx={{
                                borderRadius: "8px",
                                padding: '34px',
                                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                            }}
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{ marginBottom: '24px', display: 'flex', justifyContent: 'center' }}>
                                    <PageHeading page={'STUDENT LOGIN'} />
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                        sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}  
                                            fullWidth
                                            id="emailid"
                                            label={
                                                <span>
                                                    Email id
                                                    <span className="error" style={{ color: "red" }}>
                                                        {" "}
                                                        *
                                                    </span>
                                                </span>
                                            }
                                            type="text"
                                            variant="outlined"
                                            error={!!errors.emailid}
                                            {...register("emailid", {
                                                required: "Email id is required",
                                                validate: handleEmailValidation,
                                                onBlur:()=>trigger("emailid")
                                            })}
                                        
                                            onInput={InputTrimAndLowercaseFunction}
                                            helperText={errors.emailid?.message}
                                            FormHelperTextProps={{
                                                style: { margin: 0 },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                        sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}  
                                            fullWidth
                                            id="temppassword"
                                            type={passState ? "text" : "password"}
                                            label={
                                                <span>
                                                    Password
                                                    <span className="error" style={{ color: "red" }}>
                                                        {" "}
                                                        *
                                                    </span>
                                                </span>
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setPassState(!passState)} sx={{ paddingRight: '0' }}>
                                                            {passState ? <FiEyeOff size={18} /> : <FiEye size={18} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={!!errors.temppassword}
                                            {...register("temppassword", {
                                                required: 'Password is required',
                                                minLength: {
                                                    value: 8,
                                                    message: "Password must have at least 8 characters",
                                                },
                                                validate: passwordValidation,
                                                onBlur:()=> trigger('temppassword')
                                            })}
                                            onInput={InputTrimFunction}
                                            helperText={errors.temppassword?.message}
                                            FormHelperTextProps={{
                                                style: { margin: 0 },
                                            }}
                                        />
                                        <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '12px' }}>
                                            <Link
                                                to={`/forgot-password`}
                                                style={{
                                                    textDecoration: "none",
                                                    color: `${cssProperties?.bordercolor?.primary2blue}`,
                                                    fontWeight: 500,
                                                    fontSize: '14px'
                                                }}
                                            >
                                                FORGOT PASSWORD?
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{ textAlign: 'center', marginTop: '24px' }}>
                                    <BluePrimaryButton
                                        type="submit"
                                        title={buttonLoader ? <ButtonLoader /> : "Login"}
                                    />
                                </Box>
                            </form>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <Typography variant="body2">Don't have an account?</Typography>
                                <Link
                                    to={`/signup`}
                                    style={{
                                        marginLeft: 4,
                                        fontWeight: 600,
                                        color: `${cssProperties?.bordercolor?.primary2blue}`,
                                        fontSize: '14px',
                                        textDecoration: "none",
                                    }}
                                >
                                    SIGN UP
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


        </>
    );
}


