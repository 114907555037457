
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
// import SaveButton from "../common/saveButton";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";

export default function PageHeading({ page }) {
  // const navigate = useNavigate();
  return (
    <Grid pb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography
        fontSize={"20px"}
          sx={{
            color: '#212121',
            fontWeight: "500"
          }}
        >
          {page}
        </Typography>
      </Box>
    </Grid>
  );
}

