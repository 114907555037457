import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  handleNameValidation,
  AlphabetsValidation,
  handleEmailValidation,
  NumbersValidation,
  MobileNumberValidation,
  MobileNumberFirstNumberValidation,
} from "../../utils/validations";
import masterActions from "../../redux/master/action";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/button/submitButton";
import SaveButton from "../../common/button/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/button/backButton";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";

export default function PersonalDetailsForm({ preprocessFormData }) {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRelationChange = (e) => {
    setValue("relationshipwithstudent", e.target.value);
    trigger("relationshipwithstudent");
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { gender } =
    useSelector((state) => state.masterReducer);
  const { personalDetails, FullCandidateDetails } = useSelector((state) => state.candidatesReducer);
  // console.log("personalDetails", personalDetails)
  const candidatedraftid = personalDetails[0]?.candidatedraftid;


  useEffect(() => {
    // console.log(dayjs(personalDetails[0]?.dob).format('DD-MM-YYYY') );
    if (personalDetails?.length > 0) {
      reset({ ...personalDetails[0],dob:dayjs(personalDetails[0]?.dob).format('DD-MM-YYYY'),mobileno:personalDetails[0].mobileno.length>10 ?personalDetails[0].mobileno.slice(2):personalDetails[0].mobileno });
      // console.log(getValues("dob"))
    }
  }, [personalDetails]);

  // console.log(personalDetails);

  useEffect(() => {
    dispatch({ type: masterActions.GET_GENDER });
    dispatch({ type: masterActions.GET_MOTHERTONGUE });
    dispatch({ type: masterActions.GET_REGION });
    dispatch({ type: masterActions.GET_RELIGION });
    dispatch({ type: masterActions.GET_NATIONALITY });
    dispatch({ type: masterActions.GET_ANNUALINCOME });
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    }
    //eslint-disable-next-line
  }, [candidateid]);

  const handleDraft = (getValues) => {
    const values = getValues();
    let operation = candidatedraftid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_PERSONAL_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          dob:personalDetails[0]?.dob,
          status: 1,
          operation: operation,
          candidateid: parseInt(candidateid),
        },
      },
    });
    dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
  };

  const handleSave = (data) => {
    // console.log("data", data)
    
    dispatch({
      type: candidatesActions.PROFILE_PERSONAL_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          dob:personalDetails[0]?.dob,
          status: 2,
        },
        navigate: navigate,
      },
    });
  };

  const handleBack = () => {
    navigate(`/home`);
  };

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider sx={{fontWeight:500,color:"#A3A3A3"}}>Candidate Details</Divider>
        </Grid>
      <Box mb={2}>
        <small style={{color:'#666666'}}>* Marked fields are required</small>
      </Box>
      <Grid container spacing={"24px"}>
        <Grid item xs={12} md={6}>
          <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}     
            fullWidth
            variant="outlined"
            
            type="text"
            label={
              <span style={{fontSize:12,padding:2}}>
                Full Name (As per latest School Marksheet)
                <span className="error" style={{ color: "red" }}>
                  
                  *
                </span>
              </span>
            }
            placeholder="Enter name"
            {...register("candidatename", {
              required: "Name is required",
              validate: handleNameValidation,
            })}
            disabled
            onInput={AlphabetsValidation}
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch("candidatename")?.trim(),
            }}
          />
          {/* {errors.candidatename && (
            <small className="error">{errors.candidatename.message}</small>
          )} */}
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}    
          inputProps={{
style:{fontSize:14}
            }}
            fullWidth
            label={
              <span style={{fontSize:12,padding:2}}>
                DOB
                <span className="error" style={{ color: "red" }}>
                  
                  *
                </span>
              </span>
            }
            
            placeholder="Click to set date"
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch("dob")
            }}
            type="text"
            value={getValues('dob')}
            // {...register("dob", {
            //   required: "DOB is required",
             
            // })}
            disabled
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}     
          inputProps={{
style:{fontSize:14}
            }}
            fullWidth
            type="text"
            variant="outlined"
            
            label={
              <span style={{fontSize:12,padding:2}}>
                Email
                <span className="error" style={{ color: "red" }}>
                  
                  *
                </span>
              </span>
            }
            placeholder="Enter email"
            {...register("emailid", {
              required: "Email is required",
              validate: handleEmailValidation,
            })}
            disabled={true}
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch("emailid")?.trim(),
            }}
          />
          {/* {errors.emailid && (
            <small className="error">{errors.emailid.message}</small>
          )} */}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}     
          inputProps={{
style:{fontSize:14}
            }}
            fullWidth
            type="text"
            variant="outlined"
            
            label={
              <span style={{fontSize:12,padding:2}}>
                Mobile No
                <span className="error" style={{ color: "red" }}>
                  
                  *
                </span>
              </span>
            }
            placeholder="Enter Mobile number"
            {...register("mobileno", {
              required: "Mobile number is required",
            })}
            disabled={true}
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch("mobileno")?.trim(),
            }}
          />
          <Box pt={1} sx={{ display: "flex", flexDirection: "column" }}>
            {/* {errors.mobileno && (
              <small className="error">{errors.mobileno.message}</small>
            )} */}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
           fullWidth  error={!!errors.genderid} >
            <InputLabel sx={{fontSize:"12px"}}
             htmlFor="genderid">
              Gender<span className="error">*</span>
            </InputLabel>
            <Select 
              id="genderid"
              label="Gender"
              {...register("genderid", { required: "Gender is required" })}
              onChange={(e) => {
                setValue("genderid", e.target.value);
                trigger("genderid");
              }}
              value={watch("genderid") || ""}
            >
              <MenuItem sx={{fontSize:"14px"}} value="">Select One</MenuItem>
              {gender?.map((val, i) => (
                <MenuItem sx={{fontSize:"14px"}} value={val?.genderid} key={i}>
                  {val?.gender}
                </MenuItem>
              ))}
            </Select>
            {errors.genderid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.genderid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider sx={{fontWeight:500,color:"#A3A3A3"}}>Guardian Details</Divider>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}     
          inputProps={{
          style:{fontSize:14,fontWeight:500}
            }}
         
            fullWidth
            type="text"
            variant="outlined"
            
            label={
              <span style={{fontSize:12}}>
                Guardian's first name
                <span className="error" style={{ color: "red" }}>
                  
                  *
                </span>
              </span>
            }
            placeholder="Enter Guardian's first name"
            {...register("parentfristname", {
              required: "Guardian's first name is required",
              validate: handleNameValidation,
            })}
            onInput={AlphabetsValidation}
            error={!!errors.parentfristname}
            helperText={errors.parentfristname?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch("parentfristname")?.trim(),

            }}
          />
          {/* {errors.fathername && (
            <small className="error">{errors.fathername.message}</small>
          )} */}
        </Grid>
        <Grid item xs={12} md={6}>
        <TextField
        sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}     
          inputProps={{
style:{fontSize:14,fontWeight:500}
            }}
            fullWidth
            type="text"
            variant="outlined"
            
            label={
              <span style={{fontSize:12}}>
                Guardian's last name
                <span className="error" style={{ color: "red" }}>
                  
                  *
                </span>
              </span>
            }
            placeholder="Enter Guardian's last name"
            {...register("parentlastname", {
              required: "Guardian's last name is required",
              validate: handleNameValidation,
            })}
            onInput={AlphabetsValidation}
            error={!!errors.parentlastname}
            helperText={errors.parentlastname?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            InputLabelProps={{
              style:{fontSize:12},            
            
              shrink: !!watch("parentlastname")?.trim(),

            }}
          />
          {/* {errors.mothername && (
            <small className="error">{errors.mothername.message}</small>
          )} */}
        </Grid>
       
        {/* <Grid item xs={12} md={6}>
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
           fullWidth  error={!!errors.religionid}>
            <InputLabel shrink={!!watch("religionid")} htmlFor="religionid">
              Religion<span className="error">*</span>
            </InputLabel>
            <Select 
              label="Religion"
              {...register("religionid", {
                required: "Religion is required",
              })}
              onChange={(e) => {
                setValue("religionid", e.target.value);
                trigger("religionid");
              }}
              value={watch("religionid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {religion?.map((val, i) => (
                <MenuItem value={val?.religionid} key={i}>
                  {val?.religion}
                </MenuItem>
              ))}
            </Select>
            {errors.religionid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.religionid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
           fullWidth  error={!!errors.regionid}>
            <InputLabel shrink={!!watch("regionid")} htmlFor="regionid">
              Region<span className="error">*</span>
            </InputLabel>
            <Select 
              label="Region"
              {...register("regionid", { required: "Region is required" })}
              onChange={(e) => {
                setValue("regionid", e.target.value);
                trigger("regionid");
              }}
              value={watch("regionid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {region?.map((val, i) => (
                <MenuItem value={val?.regionid} key={i}>
                  {val?.region}
                </MenuItem>
              ))}
            </Select>
            {errors.regionid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.regionid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
           fullWidth  error={!!errors.mothertongueid}>
            <InputLabel
              shrink={!!watch("mothertongueid")}
              htmlFor="mothertongueid"
            >
              Mother Tongue<span className="error">*</span>
            </InputLabel>
            <Select 
              label="Mother Tongue"
              {...register("mothertongueid", {
                required: "Mother Tongue is required",
              })}
              onChange={(e) => {
                setValue("mothertongueid", e.target.value);
                trigger("mothertongueid");
              }}
              value={watch("mothertongueid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {mothertongue?.map((val, i) => (
                <MenuItem value={val?.mothertongueid} key={i}>
                  {val?.mothertongue}
                </MenuItem>
              ))}
            </Select>
            {errors.mothertongueid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.mothertongueid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
           fullWidth  error={!!errors.nationalityid}>
            <InputLabel
              shrink={!!watch("nationalityid")}
              htmlFor="nationalityid"
            >
              Nationality<span className="error">*</span>
            </InputLabel>
            <Select 
              label="Nationality"
              {...register("nationalityid", {
                required: "Nationality is required",
              })}
              onChange={(e) => {
                setValue("nationalityid", e.target.value);
                trigger("nationalityid");
              }}
              value={watch("nationalityid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {nationality?.map((val, i) => (
                <MenuItem value={val?.nationalityid} key={i}>
                  {val?.nationality}
                </MenuItem>
              ))}
            </Select>
            {errors.nationalityid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.nationalityid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
           fullWidth  error={!!errors.familyincomeid}>
            <InputLabel
              shrink={!!watch("familyincomeid")}
              htmlFor="familyincomeid"
            >
              
              Annual Family Income
              <span className="error" style={{ color: "red" }}>
                
                *
              </span>
            </InputLabel>
            <Select 
              label=" Annual Family Income"
              {...register("familyincomeid", {
                required: "Annual Family Income is required",
              })}
              onChange={(e) => {
                setValue("familyincomeid", e.target.value);
                trigger("familyincomeid");
              }}
              value={watch("familyincomeid") || ""}
            >
              <MenuItem value="">Select One</MenuItem>
              {annualIncome?.map((val, i) => (
                <MenuItem value={val?.familyincomeid} key={i}>
                  {val?.incomerange}
                </MenuItem>
              ))}
            </Select>
            {errors.familyincomeid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.familyincomeid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
           fullWidth  error={!!errors.maritalstatusid}>
            <InputLabel
              shrink={!!watch("maritalstatusid") || watch("maritalstatusid") === 0}
              htmlFor="maritalstatusid"
            >
              
              Marital Status<span className="error">*</span>
            </InputLabel>
            <Select 
              
              label="Marital Status"
              {...register("maritalstatusid", {
                required: "Marital status is required",
              })}
              onChange={handleMaritalStatusChange}
              value={watch("maritalstatusid") ?? ""}            >
              <MenuItem value="">Select One</MenuItem>
              <MenuItem value={0}>Unmarried</MenuItem>
              <MenuItem value={1}>Married</MenuItem>
            </Select>
            {errors.maritalstatusid && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.maritalstatusid.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6}>
          {/* <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}     
          inputProps={{
style:{fontSize:14}
            }}
            
            fullWidth
            type="text"
            label={
              <span style={{fontSize:12,padding:2}}>
              Relationship With Student
              <span className="error" style={{ color: "red" }}>
                
                *
              </span>
            </span>
            }
            placeholder="Enter Relationship with student"
            {...register("relationshipwithstudent", {
              required: "Filed is required",
              validate: handleNameValidation,
            })}
            error={!!errors.relationshipwithstudent}
            disabled={
              maritalStatus === 0 || getValues("maritalstatusid") === 0
            }
            onInput={AlphabetsValidation}
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch("relationshipwithstudent")?.trim(),
            }}
            helperText={errors.relationshipwithstudent?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
          /> */}
          <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
           fullWidth  error={!!errors.relationshipwithstudent}>
            <InputLabel 
            sx={{fontSize:"12px"}}
              htmlFor="relationshipwithstudent"
            >
              
              Relationship with Student<span className="error">*</span>
            </InputLabel>
            <Select               
              label="Relationship with Student"
              {...register("relationshipwithstudent", {
                required: "Relationship with Student is required",
              })}
              onChange={handleRelationChange}
              value={watch("relationshipwithstudent") ?? ""}            >
              <MenuItem sx={{fontSize:"14px"}} value="">Select One</MenuItem>
              <MenuItem sx={{fontSize:"14px"}} value={"Father"}>Father</MenuItem>
              <MenuItem sx={{fontSize:"14px"}} value={"Mother"}>Mother</MenuItem>
              <MenuItem sx={{fontSize:"14px"}} value={"Guardian"}>Guardian</MenuItem>
            </Select>
            {errors.relationshipwithstudent && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.relationshipwithstudent.message}
              </FormHelperText>
            )}
          </FormControl>
          {/* {errors.relationshipwithstudent && (
              <FormHelperText style={{ margin: 0 }}>
                {errors.relationshipwithstudent.message}
              </FormHelperText>
            )}
            */}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },
        }}     
          inputProps={{
style:{fontSize:14, fontWeight:500}
            }}
            
            fullWidth
            type="email"
            label={
              <span style={{fontSize:12,padding:2}}>
                Guardian Email
                {/* <span className="error" style={{ color: "red" }}>
                  
                  *
                </span> */}
              </span>
            }
            placeholder="Enter Relation Email"
            {...register("relationemail", {
              // required: "Filed is required",
              validate: handleEmailValidation,
            })}
            error={!!errors.relationemail}

            // onInput={handleEmailValidation}
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch('relationemail')
            }}
            helperText={errors.relationemail?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
          sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
            
            fullWidth

            label={
              <span style={{fontSize:12,padding:2}}>
                Guardian Mobile number
                <span className="error" style={{ color: "red" }}>
                  
                  *
                </span>
              </span>
            }
            placeholder="Enter Relationship Mobile number"
            {...register("relationcontactno", {
              required: "Filed is required",
              validate: MobileNumberFirstNumberValidation,
              maxLength: {
                value: 10,
                message: "Enter valid Mobile number",
              },
            })}
            type="tel"
            maxLength={10}
            inputProps={{
              classes:{input: {fontSize:"14px"}},
              pattern: "[0-9]*",
              maxLength: 10,
              style:{fontSize:14, fontWeight:500}

            }}
            error={!!errors.relationcontactno}

            onInput={MobileNumberValidation}
            InputLabelProps={{
              style:{fontSize:12},
              shrink: !!watch('relationcontactno')

            }}


            helperText={errors.relationcontactno?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        pb={2}
        pt={2}
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "flex-end",
          flexDirection: isMobile ? "column" : null,
        }}
      >
        {/* {personalDetails?.length > 0 && personalDetails[0]?.status === 2 && (
          <BackButton handleBack={handleBack} />
        )} */}
        {/* <SaveButton
          name={draftButtonLoader ? "loader" : "Save Draft"}
          handleDraft={() => handleDraft(getValues)}
          disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
        /> */}
        <SubmitButton
          name={buttonLoader ? "loader" : "Save & Continue"}
          handleSubmit={handleSubmit(handleSave)}
          disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
        />
      </Grid>
    </Grid>
  );
}
