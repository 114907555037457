import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import masterActions from "../../redux/master/action";
import {
  percentageValidation,
  percentageNumberValidation,
} from "../../utils/validations";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/button/submitButton";
import SaveButton from "../../common/button/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/button/backButton";
import {
  handleNameValidation,
  AlphabetsValidation,
  handleEmailValidation,
} from "../../utils/validations";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getValue } from "@testing-library/user-event/dist/utils";

export default function   OtherDetailsForm({ preprocessFormData }) {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const date = new Date();
  const currentYear = date.getFullYear();
  const [years, setYears] = useState([]);
  const [disability, setDisability] = useState("");
  const [minorityDetails, setMinorityDetails] = useState("");
  const [scribe, setScribe] = useState("");

  const handleDisability = (e) => {
    setValue("isdisability", parseInt(e.target.value));
    setDisability(e.target.value);
    trigger("isdisability");
  };

  const handleMinority = (e) => {
    setValue("isminority", parseInt(e.target.value));
    setMinorityDetails(e.target.value);
    trigger("isminority");
  };

  const handleScribe = (e) => {
    setValue("isscriberequired", parseInt(e.target.value));
    setScribe(e.target.value);
    trigger("isscriberequired");
  };

  const handleBack = () => {
    navigate(`/home?page=address`);
  
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);
  const { standard, board, state,district,mediumofinstructions } = useSelector(
    (state) => state.masterReducer
  );

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { otherDetails, FullCandidateDetails,addressDetails } = useSelector((state) => state.candidatesReducer);
  // console.log("hello",otherDetails)
  const candidateotherdetailsid = otherDetails[0]?.candidateotherdetailsid;
  const candidateotherdetailsdraftid =
    otherDetails[0]?.candidateotherdetailsdraftid;


    // console.log('otherDetails ---',otherDetails);

  useEffect(() => {

    

setTimeout(() => {
  
  if (otherDetails.length > 0) {
    reset({ ...otherDetails[0],stateofschoolid:otherDetails[0].stateofschoolid,cityofschoolid:otherDetails[0].cityofschoolid });
    
  }
  //eslint-disable-next-line
}, (2000));
}, [otherDetails]);


const dispatch = useDispatch();
const navigate = useNavigate();
    
  useEffect(() => {
      
    setTimeout(() => {
      
      dispatch({ type: masterActions.GET_DISTRICT });
      dispatch({ type: masterActions.GET_STANDARD });
      // dispatch({ type: masterActions.GET_CASTE });
      // dispatch({ type: masterActions.GET_PWD_TYPE });
      dispatch({ type: masterActions.GET_BOARD });
      dispatch({ type: masterActions.GET_MEDIUM_OF_INSTRUCTIONS });
      dispatch({ type: masterActions.GET_STATE });
      // dispatch({ type: masterActions.GET_MINORITY });
      if (candidateid) {
        dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
      }
    }, 1000);
    
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let i = 2000; i <= currentYear; i++) {
        yearsArray.push(i);
      }
      return yearsArray;
    };
    setYears(generateYears());
    //eslint-disable-next-line

    // trigger('stateofschoolid')
    // setValue('stateofschoolid',getValues('stateofschoolid'))
  }, [candidateid]);

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_ADDRESS_PERSONAL_DETAILS });
    }
    //eslint-disable-next-line
  }, [candidateid]);
  const handleDraft = (getValues) => {
    const values = preprocessFormData(getValues());
    let operation = candidateotherdetailsdraftid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: 1,
          operation: operation,
          candidateid: candidateid,
        },
      },
    });
    dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });
  };

  const handleSave = (data) => {
    let operation = candidateotherdetailsid ? 1 : 0;
    // let operation = 0;
    dispatch({
      type: candidatesActions.PROFILE_OTHER_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
          candidateid: candidateid,
          operation: operation,
        },
        navigate: navigate,
      },
    });
    // navigate("/home?page=uploads");
    dispatch({ type: candidatesActions.GET_OTHER_PERSONAL_DETAILS });

  };

  // console.log("state of school---",getValues("stateofschoolid"));
  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid>
        <Typography pb={2} pt={2} variant="h4" sx={{ textWrap: "wrap", fontSize: 30, fontWeight: 500,mb:"20px" }}>Education Details</Typography>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={6}>
            <TextField
             sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
              fullWidth
              variant="outlined"
              
              type="text"
              label={
                <span style={{fontSize:"12px"}}>
                  SSC Total percentage
                  <span className="error" style={{ color: "red" }}>
                    {" "}
                    *
                  </span>
                </span>
              }
              placeholder="Enter the SSC Total percentage"
              {...register("ssctotalpercentage", {
                required: "SSC Total percentage is required",
                validate: percentageValidation,
              })}
              onInput={percentageNumberValidation}
              error={!!errors.ssctotalpercentage}
              helperText={errors.ssctotalpercentage?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("ssctotalpercentage")
              }}
            />
            <Box>
              <small style={{ color: "#666666" }}>
                Up to 2 decimal places are allowed
              </small>
            </Box>
          </Grid>  */}
            <Grid item xs={12} md={6}>
            <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   fullWidth  error={!!errors.standardid}>
              <InputLabel sx={{fontSize:12}}  htmlFor="standardid">
              Select Appearing Standard
                {/* Are you appearing/applied for 12th HSC Diploma exam in{" "} */}
                <span className="error">*</span>
                {/* {currentYear}?<span className="error">*</span> */}
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                id="standardid"
                label={
                  <>
                    <span style={{fontSize:"12px"}}>
                    Select Appearing Standard
                      {/* Are you appearing/applied for 12th HSC Diploma exam in{" "} */}
                      {currentYear}?
                    </span>
                    <span className="error">*</span>
                  </>
                }
                {...register("standardid", {
                  required: "Required",
                })}
                onChange={(e) => {
                  setValue("standardid", e.target.value);
                  trigger("standardid");
                }}
                value={watch("standardid") ?? ""}
                
              >
                <MenuItem sx={{fontSize:14}} value="">Select One</MenuItem>
                {/* <MenuItem sx={{fontSize:14}} value={1}>1-10</MenuItem>
                <MenuItem sx={{fontSize:14}} value={0}>(+1)-(+2)</MenuItem> */}
                {standard?.map((val, i) => (
                  <MenuItem sx={{fontSize:14}} value={val?.standardid} key={i}>
                    {val?.standard}
                  </MenuItem>
                ))}
              </Select>
              {errors.standardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.standardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   fullWidth  error={!!errors.boardid}>
              <InputLabel sx={{fontSize:12}}  htmlFor="boardid">
                Select Board<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                id="boardid"
                label="SSC Board"
                {...register("boardid", {
                  required: "SSC Board is required",
                })}
                onChange={(e) => {
                  const id = e.target.value;
                  const filterId = board?.find(
                    (row) => row.boardid === parseInt(id)
                  );
                  // const finalId = filterId.boardstateid;
                  // setValue("stateofschoolid", finalId);
                  setValue("boardid", id)
                }}
                value={watch("boardid") ?? ""}

              >
                <MenuItem sx={{fontSize:14}} value={0}>Select One</MenuItem>
                {/* <MenuItem sx={{fontSize:14}} value={1}>Sample1</MenuItem>
                <MenuItem sx={{fontSize:14}} value={2}>Sample2</MenuItem> */}
                {board?.map((val, i) => (
                  <MenuItem sx={{fontSize:14}} value={val?.boardid} key={i}>
                    {val?.board}
                  </MenuItem>
                ))}
              </Select>
              {errors.boardid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.boardid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
              fullWidth
              
              error={!!errors.stateofschoolid}
            >
              <InputLabel sx={{fontSize:12}}  htmlFor="stateofschoolid">
                State of School<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                id="stateofschoolid"
                label="SSC School State"
                {...register("stateofschoolid", {
                  required: "SSC School State is required",
                })}
                onChange={(e) => {
                  setValue("stateofschoolid",e.target.value);
                  trigger("stateofschoolid");
                  dispatch({
                    type: masterActions.GET_DISTRICT,
                    payload: e.target.value,
                  });
                }}
                value={ watch("stateofschoolid") ?? "" }

              >
                <MenuItem sx={{fontSize:14}} value={0}>Select One</MenuItem>
                {/* <MenuItem sx={{fontSize:14}} value={1}>Sample1</MenuItem>
                <MenuItem sx={{fontSize:14}} value={2}>Sample2</MenuItem> */}
                {state?.map((val, i) => (
                  <MenuItem sx={{fontSize:14}} value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.stateofschoolid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.stateofschoolid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
              fullWidth
              
              error={!!errors.cityofschoolid}
            >
              <InputLabel sx={{fontSize:12}}  htmlFor="cityofschoolid">
                City of School<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                id="cityofschoolid"
                label="SSC School Districts"
                {...register("cityofschoolid", {
                  required: "SSC School District is required",
                })}
                onChange={(e) => {
                  setValue("cityofschoolid", e.target.value);
                  trigger("cityofschoolid");
                }}
                value={watch("cityofschoolid") ?? ""}

              >
                <MenuItem sx={{fontSize:14}} value={0}>Select One</MenuItem>
                {/* <MenuItem sx={{fontSize:14}} value={1}>Sample1</MenuItem>
                <MenuItem sx={{fontSize:14}} value={2}>Sample2</MenuItem> */}
                {district?.permanent?.map((val, i) => (
                  <MenuItem sx={{fontSize:14}} value={val?.districtid} key={i}>
                    {val?.district}
                  </MenuItem>
                ))}
              </Select>
              {errors.cityofschoolid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cityofschoolid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          
        
          <Grid item xs={12} md={6}>
            <FormControl  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   fullWidth  error={!!errors.hscboardid}>
              <InputLabel sx={{fontSize:12}}  htmlFor="mediumofinstructionsid">
                Medium of Instructions<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                id="mediumofinstructionsid"
                label="Medium of Instructions"
                {...register("mediumofinstructionsid", {
                  required: " Medium of Instructions is required",
                })}
                onChange={(e) => {
                  setValue("mediumofinstructionsid", e.target.value);
                  trigger("mediumofinstructionsid");
                }}
                value={watch("mediumofinstructionsid") ?? ""}
              >
                <MenuItem sx={{fontSize:14}} value={0}>Select One</MenuItem>
                {/* <MenuItem sx={{fontSize:14}} value={1}>Sample1</MenuItem>
                <MenuItem sx={{fontSize:14}} value={2}>Sample2</MenuItem> */}
                {mediumofinstructions?.map((val, i) => (
                  <MenuItem sx={{fontSize:14}} value={val?.mediumofinstructionsid} key={i}>
                    {val?.mediumofinstructions}
                  </MenuItem>
                ))}
              </Select>
              {errors.mediumofinstructionsid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.mediumofinstructionsid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
          <TextField
           sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
  
            fullWidth
            type="text"
            variant="outlined"
            
            label={
              <span style={{fontSize:"12px"}}>
                Name of School
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter Name of School"
            {...register("schoolname", {
              required: "Enter Name of School is required",
              
            })}
           
            error={!!errors.schoolname}
            helperText={errors.schoolname?.message}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
            InputLabelProps={{
              shrink:!!watch('schoolname')
            }}
            inputProps={{
              style:{fontSize:14, fontWeight:500}
                          }}
          />
         
        </Grid>
        </Grid>

        <Grid
          item
          md={12}
          pb={2}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          {/* {parseInt(otherDetails[0]?.status) === 2 && (
            <BackButton handleBack={handleBack} />
          )} */}

  {/* {console.log("test-------",addressDetails?.length > 0 && addressDetails[0]?.status === 2)} */}
          {addressDetails?.length > 0 && addressDetails[0]?.status === 2 && (
         
                <BackButton handleBack={handleBack} />
         
         )} 

          {/* <SaveButton
            name={draftButtonLoader ? "loader" : "Save Draft"}
            handleDraft={() => handleDraft(getValues)}
            disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
          /> */}
          <SubmitButton
            name={buttonLoader ? "loader" : "Save & Continue"}
            handleSubmit={handleSubmit(handleSave)}
            disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
