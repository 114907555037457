import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AddressValidationFirstField,
  AlphabetsValidation,
  AddressValidation,
  pincodeValidation,
  pincodeNumberValidation,
  NumbersValidation,
  MobileNumberValidation,
  MobileNumberFirstNumberValidation,
  AlphabetsandNumbersValidation,
} from "../../utils/validations";
import masterActions from "../../redux/master/action";
import candidatesActions from "../../redux/candidates/actions";
import { useSelector, useDispatch } from "react-redux";
import SubmitButton from "../../common/button/submitButton";
import SaveButton from "../../common/button/saveButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../../common/button/backButton";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";

export default function AddressForm({ preprocessFormData }) {
  const [isSameAddress, setIsSameAddress] = useState(false);
  // const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { state, district, districtCorrespontance, taluka, village, villageCorrespontance, talukaCorrespontance } = useSelector(
    (state) => state.masterReducer
  );

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);
  const { addressDetails, pincode, pincodeCorrespondence, FullCandidateDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  // console.log(addressDetails, 'address')
  // const handleFocus = () => {
  //   setIsFocused(true);
  // };

  const { personalDetails } = useSelector((state) => state.candidatesReducer);
  // console.log("personalDetails", personalDetails)


  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const handleIsSameAddressChange = (e) => {
    const isChecked = e.target.checked;
    setIsSameAddress(isChecked);

    if (!isChecked) {
      // const fields = [
      //   "caddressline1",
      //   "caddressline2",
      //   "caddressline3",
      //   "cpincode",
      //   "cstateid",
      //   "cdistrictid",
      //   "ctalukaid",
      //   "cvillageid",
      // ];

      // fields.forEach((field) => {
      //   setValue(field, "");
      // });
    }
  };
  const handleCaddresschange = () => {
    const isChecked = false
    setIsSameAddress(isChecked);


  };
  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    }
    //eslint-disable-next-line
  }, [candidateid]);

  useEffect(() => {
    if (isSameAddress) {
      
      setValue("caddressline1", getValues("paddressline1"));
      setValue("caddressline2", getValues("paddressline2"));
      setValue("caddressline3", getValues("paddressline3"));
      setValue("cpincode", getValues("ppincode"));
      setValue("cstateid", getValues("pstateid"));
      setValue("cdistrictid", getValues("pdistrictid"));
      setValue("ctalukaid", getValues("ptalukaid"));
      setValue("cvillageid", getValues("pvillageid"));
    }
    //eslint-disable-next-line
  }, [watch("paddressline1"), watch("paddressline2"), watch("paddressline3"), watch("ppincode"), watch("pstateid"), watch("pdistrictid"), watch("ptalukaid"), watch("pvillageid"), isSameAddress]);

  const candidateaddressdraftid = addressDetails && addressDetails[0]?.candidateaddressdraftid;


  const candidateaddressid = addressDetails && addressDetails[0]?.candidateaddressid;

  useEffect(() => {
    setTimeout(() => {
      if (addressDetails.length > 0) {
        reset({ ...addressDetails[0] });
        if (addressDetails[0].sameascommunication === "1") {
          setIsSameAddress(true)
        }
      }
    }, 1000);
    //eslint-disable-next-line
  }, [addressDetails]);

  const dispatch = useDispatch();

  const handleDraft = (getValues) => {
    const values = preprocessFormData(getValues());
    let operation = candidateaddressdraftid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_ADDRESS_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...values,
          status: 1,
          operation: operation,
          candidateid: parseInt(candidateid),
        },
      },
    });
    dispatch({ type: candidatesActions.GET_ADDRESS_PERSONAL_DETAILS });
  };

  const handleSave = (data) => {
    let operation = candidateaddressid ? 1 : 0;
    dispatch({
      type: candidatesActions.PROFILE_ADDRESS_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          status: 2,
          candidateid: candidateid,
          operation: operation,
          sameascommunication: isSameAddress ? 1 : null
        },
        navigate: navigate,
      },
    });

    // navigate("/home?page=educationdetails");
    dispatch({ type: candidatesActions.GET_ADDRESS_PERSONAL_DETAILS });
  };

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_ADDRESS_PERSONAL_DETAILS });
    }
    //eslint-disable-next-line
  }, [candidateid]);

  // const watchPermanentFields = watch([
  //   "paddressline1",
  //   "paddressline2",
  //   "paddressline3",
  //   "ppincode",
  //   "pstateid",
  //   "pdistrictid",
  //   "ptalukaid",
  //   "pvillageid",
  // ]);

  // const handleCheckboxChange = (e) => {
  //   const isChecked = e.target.checked;
  //   const fields = [
  //     "caddressline1",
  //     "caddressline2",
  //     "caddressline3",
  //     "cpincode",
  //     "cstateid",
  //     "cdistrictid",
  //     "ctalukaid",
  //     "cvillageid",
  //   ];

  //   fields.forEach((field, index) => {
  //     const correspondingField = Object.keys(watchPermanentFields)[index];
  //     setValue(
  //       field,
  //       isChecked ? watchPermanentFields[correspondingField] : ""
  //     );
  //   });
  // };

  const handlePincodePermanent = (e) => {
    const value = e.target.value;
    if (value.length === 6) {
      dispatch({
        type: candidatesActions.GET_PINCODE,
        payload: { value: value },
      });
      setValue("ctalukaid", watch("ptalukaid"));
    }
  };

  const handlePincodeCorrspondance = (e) => {
    const value = e.target.value;
    if (value.length === 6) {
      dispatch({
        type: candidatesActions.GET_PINCODE_CORRESPONDENCE,
        payload: { value: value },
      });
    }
  };

  useEffect(() => {
    setTimeout(()=>{
    if (pincode.length > 0 && pincode[0]) {
      setValue("pstateid", pincode[0]?.stateid);
      setValue("pdistrictid", pincode[0]?.districtid);
      setValue("ptalukaid", pincode[0]?.talukaid);
    }
  },2000)
    //eslint-disable-next-line
  }, [pincode]);


  useEffect(() => {

    if (pincodeCorrespondence.length > 0 && pincodeCorrespondence[0]) {
      setValue("cstateid", pincodeCorrespondence[0]?.stateid);
      setValue("cdistrictid", pincodeCorrespondence[0]?.districtid);
      setValue("ctalukaid", pincodeCorrespondence[0]?.talukaid);
    }
    //eslint-disable-nxt-line
  }, [pincodeCorrespondence]);
  

  const handleBack = () => {
    navigate(`/home?page=personaldetails`);
  };

  // console.log('p district-----',getValues('pdistrictid'));
  // console.log('ptalukaid------',getValues('ptalukaid'));
  // console.log('pstateid------',getValues('pstateid'));

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid>
        <Typography
          variant="h4"
          sx={{ textWrap: "wrap", fontSize: 20, fontWeight: 500, color: "#212121", mb: "20px" }}
        >
          Permanent Address
        </Typography>
        <Grid pt={1} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              variant="outlined"

              type="text"
              label={
                <span style={{ fontSize: 12 }}>
                  Address line 1
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </span>
              }
              placeholder="Door no,street"
              {...register("paddressline1", {
                required: "Address is required",
                validate: AddressValidationFirstField,
              })}
              error={!!errors.paddressline1}
              helperText={errors.paddressline1?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("paddressline1")?.trim(),

              }}
            />
            {/* {errors.paddressline1 && (
              <small className="text-danger">
                {errors.paddressline1.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              variant="outlined"

              type="text"
              label={
                <span style={{ fontSize: 12 }}>
                  Address line 2
                  <span className="error" style={{ color: "red" }}>

                    *
                  </span>
                </span>
              }
              placeholder="Locality"
              {...register("paddressline2", {
                required: "Address is required",
                validate: AddressValidation,
              })}
              error={!!errors.paddressline2}
              helperText={errors.paddressline2?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={AlphabetsandNumbersValidation}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("paddressline2")?.trim(),
              }}
            />
            {/* {errors.paddressline2 && (
              <small className="text-danger">
                {errors.paddressline2.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              variant="outlined"

              type="text"
              label={
                <span style={{ fontSize: 12 }}>
                  Address line 3
                  {/* <span className="error" style={{ color: "red" }}>
                    
                    *
                  </span> */}
                </span>
              }
              placeholder="Locality"
              {...register("paddressline3", {
                // required: "Address is required",
                // maxLength: {
                //   value: 50,
                //   message: "Address Line 3 should be less than 50 characters"
                // },
                validate: AddressValidation,
              })}
              error={!!errors.paddressline3}
              helperText={errors.paddressline3?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={AlphabetsValidation}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("paddressline3")?.trim(),
              }}
            />
            {/* {errors.paddressline3 && (
              <small className="text-danger">
                {errors.paddressline3.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              type="text"
              variant="outlined"

              label={
                <span style={{ fontSize: 12 }}>
                  Pincode
                  <span className="error" style={{ color: "red" }}>

                    *
                  </span>
                </span>
              }
              placeholder="Enter the pincode"
              {...register("ppincode", {
                required: "Pincode is required",
                validate: pincodeValidation,
              })}
              // onFocus={handleFocus}
              // onChange={handlePincodePermanent}
              onInput={pincodeNumberValidation}
              error={!!errors.ppincode}
              helperText={errors.ppincode?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("ppincode")
              }}
            />
            {/* {errors.ppincode && (
              <small className="text-danger">{errors.ppincode.message}</small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.pstateid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="genderid">
                State<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="pstateid"
                label="State"
                // value={getValues("pstateid")}
                {...register("pstateid", {
                  required: "state is required",
                })}
                onChange={(e) => {
                  const stateid = e.target.value;
                  setValue("pstateid", stateid);
                  dispatch({
                    type: masterActions.GET_DISTRICT,
                    payload: stateid,
                  });
                  trigger("pstateid");
                }}
                value={watch("pstateid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* <MenuItem value={1}>Sample1</MenuItem>
                <MenuItem value={2}>Sample2</MenuItem> */}
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.pstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.pstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.pdistrictid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="pdistrictid">
                District<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="pdistrictid"
                label="District"
                // value={getValues("pdistrictid")}
                value={watch("pdistrictid") ?? ""}
                {...register("pdistrictid", {
                  required: "District is required",
                })}
                onChange={(e) => {
                  const districtid = e.target.value;
                  setValue("pdistrictid", districtid);
                  dispatch({
                    type: masterActions.GET_TALUKA,
                    payload: districtid,
                  });
                  trigger("pdistrictid");
                }}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* <MenuItem value={1}>sample1</MenuItem>
                <MenuItem value={2}>sample2</MenuItem> */}
                {district?.permanent?.map((val, i) => (
                  <MenuItem value={val?.districtid} key={i}>
                    {val?.district}
                  </MenuItem>
                ))}
              </Select>
              {errors.pdistrictid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.pdistrictid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.ptalukaid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="ptalukaid">
                Taluka<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="ptalukaid"
                label="Taluka"
                value={watch("ptalukaid") ?? ""}
                // value={getValues("ptalukaid")}
                aria-label="Default select example"
                {...register("ptalukaid", {
                  required: "Taluka is required",
                })}
                onChange={(e) => {
                  const talukaid = e.target.value;
                  setValue("ptalukaid", talukaid);
                  dispatch({
                    type: masterActions.GET_VILLAGE,
                    payload: talukaid,
                  });
                  trigger("ptalukaid");
                }}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* <MenuItem value={1}>Sample1</MenuItem>
                <MenuItem value={2}>Sample2</MenuItem> */}
                {taluka?.map((val, i) => (
                  <MenuItem value={val?.talukaid} key={i}>
                    {val?.taluka}
                  </MenuItem>
                ))}
              </Select>
              {errors.ptalukaid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ptalukaid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.pvillageid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="pvillageid">
                Village<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="pvillageid"
                label="Village"
                value={watch("pvillageid") ?? ""}
                {...register("pvillageid", {
                  required: "Village is required",
                })}
                onChange={(e) => {
                  const villageid = e.target.value;
                  setValue("pvillageid", villageid);
                  trigger("pvillageid");
                }}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* <MenuItem value={1}>Sample1</MenuItem>
                <MenuItem value={2}>Sample2</MenuItem> */}
                {village?.map((val, i) => (
                  <MenuItem value={val?.villageid} key={i}>
                    {val?.village}
                  </MenuItem>
                ))}

              </Select>
              {errors.pvillageid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.pvillageid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14,fontWeight:500 }
              }}
              fullWidth
              variant="outlined"

              type="tel"
              label={
                <span style={{ fontSize: 12 }}>
                  Std Code
                </span>
              }
              placeholder="Enter the std code"
              {...register("stdcode", {
                // required: "Std code is required",
                maxLength: {
                  value: 8,
                  message: "Enter valid std code",
                },
              })}
              error={!!errors.stdcode}
              helperText={errors.stdcode?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={NumbersValidation}
              maxLength={8}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("stdcode")?.trim(),
              }}
            />
          </Grid> */}

          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              fullWidth
              variant="outlined"

              label={
                <span style={{ fontSize: 12 }}>
                  Mobile no
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </span>
              }
              placeholder="Enter the Mobile number"
              {...register("phoneno", {
                // required: "Mobile number is required",
                maxLength: {
                  value: 10,
                  message: "Enter valid Mobile number",
                },
                validate: MobileNumberFirstNumberValidation,
              })}
              error={!!errors.phoneno}
              helperText={errors.phoneno?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={MobileNumberValidation}
              type="tel"
              maxLength={10}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("phoneno")?.trim(),
              }}
              inputProps={{
                style: { fontSize: 14, fontWeight: 500 },
                pattern: "[0-9]*",
                maxLength: 10,
              }}
            />
            {/* {errors.phoneno && (
                <small className="text-danger">{errors.phoneno.message}</small>
              )} */}
          </Grid>
        </Grid>

        <Typography
          mt={1}
          variant="h4"
          sx={{ textWrap: "wrap", fontSize: 20, fontWeight: 500, color: "#212121", mb: "10px", mt: "30px" }}
        >
          Correspondence Address
        </Typography>
        {/* <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#red', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#red', // Border color when focused
          },fontSize:"44px"
        }}     
  Label
          control={
            <Checkbox size="40"
              style={{ fontSize: 18 }}
              onChange={handleIsSameAddressChange}
            />
          }
          // label={
          // }
        /> */}
        <Box display={"flex"} alignItems={"center"}>

          <Checkbox size="40"
            checked={isSameAddress}
            style={{ fontSize: 18 }}
            onChange={handleIsSameAddressChange}
          />
          <span className="">
            Click here if the permanent and correspondence address are same
          </span>
        </Box>
        <Grid pt={1} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              variant="outlined"
              type="text"
              label={
                <span style={{ fontSize: 12 }}>
                  Address line 1
                  <span className="error" style={{ color: "red" }}>
                    *
                  </span>
                </span>
              }
              placeholder="Door no,street"
              {...register("caddressline1", {
                required: "Address is required",
                validate: AddressValidationFirstField,
              })}
              onKeyDown={() => { handleCaddresschange() }}
              error={!!errors.caddressline1}
              helperText={errors.caddressline1?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                style: { fontSize: 12 }

                , shrink: !!watch("caddressline1")?.trim(),
              }}
            />
            {/* {errors.caddressline1 && (
              <small className="text-danger">
                {errors.caddressline1.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              variant="outlined"

              type="text"
              label={
                <span style={{ fontSize: 12 }}>
                  Address line 2
                  <span className="error" style={{ color: "red" }}>

                    *
                  </span>
                </span>
              }
              placeholder="Locality"
              {...register("caddressline2", {
                required: "Address is required",
                validate: AddressValidation,
              })}
              onKeyDown={() => { handleCaddresschange() }}

              error={!!errors.caddressline2}
              helperText={errors.caddressline2?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={AlphabetsandNumbersValidation}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("caddressline2")?.trim(),
              }}
            />
            {/* {errors.caddressline2 && (
              <small className="text-danger">
                {errors.caddressline2.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              variant="outlined"

              type="text"
              label={
                <span style={{ fontSize: 12 }}>
                  Address line 3
                  {/* <span className="error" style={{ color: "red" }}>
                    
                    *
                  </span> */}
                </span>
              }
              placeholder="Locality"
              {...register("caddressline3", {
                // required: "Address is required",
                maxLength: {
                  value: 50,
                  message: "Address Line 3 should be less than 50 characters"
                },
                validate: AddressValidation,
              })}
              onKeyDown={() => { handleCaddresschange() }}
              error={!!errors.caddressline3}
              helperText={errors.caddressline3?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              onInput={AlphabetsValidation}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("caddressline3")?.trim(),
              }}
            />
            {/* {errors.caddressline3 && (
              <small className="text-danger">
                {errors.caddressline3.message}
              </small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}

              inputProps={{
                style: { fontSize: 14, fontWeight: 500 }
              }}
              fullWidth
              type="text"
              variant="outlined"

              label={
                <span style={{ fontSize: 12 }}>
                  Pincode
                  <span className="error" style={{ color: "red" }}>

                    *
                  </span>
                </span>
              }
              placeholder="Enter the pincode"
              {...register("cpincode", {
                required: "Pincode is required",
                // validate: pincodeValidation,
              })}
              onKeyDown={() => { handleCaddresschange() }}
              // onFocus={handleFocus}
              // onChange={handlePincodeCorrspondance}
              onInput={pincodeNumberValidation}
              error={!!errors.cpincode}
              helperText={errors.cpincode?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                style: { fontSize: 12 }
                , shrink: !!watch("cpincode")?.trim(),
              }}
            />
            {/* {errors.ppincode && (
              <small className="text-danger">{errors.ppincode.message}</small>
            )} */}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.cstateid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="cstateid">
                State<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="cstateid"
                label="State"
                // value={getValues("pstateid")}
                {...register("cstateid", {
                  required: "state is required",
                })}
              onKeyDown={() => { handleCaddresschange() }}
                onChange={(e) => {
                  handleCaddresschange()
                  const stateid = e.target.value;
                  setValue("cstateid", stateid);
                  dispatch({
                    type: isSameAddress ? masterActions.GET_DISTRICT : masterActions.GET_DISTRICT_CORRESPODENT,
                    
                    payload: stateid,
                  });
                  trigger("cstateid");
                }}
                value={watch("cstateid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* <MenuItem value={1}>Sample1</MenuItem>
                <MenuItem value={2}>Sample2</MenuItem> */}
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.cstateid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cstateid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.cdistrictid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="cdistrictid">
                District<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="cdistrictid"
                label="District"
                // value={getValues("cdistrictid")}
                {...register("cdistrictid", {
                  required: "District is required",
                })}
              onKeyDown={() => { handleCaddresschange() }}
                onChange={(e) => {
                  handleCaddresschange()
                  const districtid = e.target.value;
                  setValue("cdistrictid", districtid);
                  dispatch({
                    type: isSameAddress ? masterActions.GET_TALUKA :  masterActions.GET_TALUKA_CORRESPODENT,
                    
                    payload: districtid,
                  });
                  trigger("cdistrictid");
                }}
                value={watch("cdistrictid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* {districtCorrespontance?.map((val, i) => (
                  <MenuItem value={val?.districtid} key={i}>
                    {val?.district}
                  </MenuItem>
                  ))} */}

                {isSameAddress ? (
                  district?.permanent?.map((val, i) => (
                    <MenuItem value={val?.districtid} key={i}>
                      {val?.district}
                    </MenuItem>
                  ))
                ) : (
                  districtCorrespontance?.map((val, i) => (
                    <MenuItem value={val?.districtid} key={i}>
                      {val?.district}
                    </MenuItem>
                  ))
                )}
              </Select>
              {errors.cdistrictid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cdistrictid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.ctalukaid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="ctalukaid">
                Taluka<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="ctalukaid"
                label="Taluka"
                // value={getValues("ptalukaid")}
                aria-label="Default select example"
                {...register("ctalukaid", {
                  required: "Taluka is required",
                })}
              onKeyDown={() => { handleCaddresschange() }}
                onChange={(e) => {
                  const talukaid = e.target.value;
                  setValue("ctalukaid", talukaid);
                  dispatch({
                    type:isSameAddress ? masterActions.GET_VILLAGE :  masterActions.GET_VILLAGE_CORRESPODENT,
                    payload: talukaid,
                  });
                  trigger("ctalukaid");
                }}
                value={watch("ctalukaid") ?? ""}
              >
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* <MenuItem value={1}>Sample1</MenuItem>
                <MenuItem value={2}>Sample2</MenuItem>                 */}
                {/* {talukaCorrespontance?.map((val, i) => (
                  <MenuItem value={val?.talukaid} key={i}>
                    {val?.taluka}
                  </MenuItem>
                ))} */}

{isSameAddress ? (
                  taluka?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))
                ) : (
                  talukaCorrespontance?.map((val, i) => (
                    <MenuItem value={val?.talukaid} key={i}>
                      {val?.taluka}
                    </MenuItem>
                  ))
                )}
              </Select>
              {errors.ctalukaid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.ctalukaid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#3F41D1', // Label color when focused
              },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#3F41D1', // Border color when focused
              }, fontSize: "44px"
            }}
              fullWidth error={!!errors.cvillageid}>
              <InputLabel sx={{ fontSize: 12 }} htmlFor="cvillageid">
                Village<span className="error">*</span>
              </InputLabel>
              <Select
                sx={{ fontSize: 14 }}
                id="cvillageid"
                label="Village"
                // value={getValues("pvillageid")}
                {...register("cvillageid", {
                  required: "Village is required",
                })}
                onChange={(e) => {
                  const villageid = e.target.value;
                  setValue("cvillageid", villageid);
                  trigger("cvillageid");
                }}
              onKeyDown={() => { handleCaddresschange() }}
                value={watch("cvillageid") ?? ""}
              >
                {/* <MenuItem value={0}>Not Applicable</MenuItem> */}
                <MenuItem value={0}>Not Applicable</MenuItem>
                {/* <MenuItem value={1}>Sample1</MenuItem>
                <MenuItem value={2}>Sample2</MenuItem> */}
                {/* {villageCorrespontance?.map((val, i) => (
                  <MenuItem value={val?.villageid} key={i}>
                    {val?.village}
                  </MenuItem>
                ))} */}

                
{isSameAddress ? (
                  village?.map((val, i) => (
                    <MenuItem value={val?.villageid} key={i}>
                      {val?.village}
                    </MenuItem>
                  ))
                ) : (
                  villageCorrespontance?.map((val, i) => (
                    <MenuItem value={val?.villageid} key={i}>
                      {val?.village}
                    </MenuItem>
                  ))
                )}

              </Select>
              {errors.cvillageid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.cvillageid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        {/* <Row>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="caddressline1">
              <InputLabel sx={{fontSize:12}}>
                Address line 1<span className="error">*</span>
              </InputLabel>
              <TextField  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   
              inputProps={{
style:{fontSize:14}
            }}
                type="text"
                placeholder="Door no,street"
                {...register("caddressline1", {
                  required: "Address is required",
                  validate: AddressValidationFirstField,
                })}
              />
              {errors.caddressline1 && (
                <small className="text-danger">
                  {errors.caddressline1.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="caddressline2">
              <InputLabel sx={{fontSize:12}}>
                Address line 2<span className="error">*</span>
              </InputLabel>
              <TextField  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   
              inputProps={{
style:{fontSize:14}
            }}
                type="text"
                placeholder="Locality"
                {...register("caddressline2", {
                  required: "Address is required",
                  validate: AddressValidation,
                })}
                onInput={AlphabetsandNumbersValidation}
              />
              {errors.caddressline2 && (
                <small className="text-danger">
                  {errors.caddressline2.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="caddressline3">
              <InputLabel sx={{fontSize:12}}>
                Address line 2<span className="error">*</span>
              </InputLabel>
              <TextField  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   
              inputProps={{
style:{fontSize:14}
            }}
                type="text"
                placeholder="Locality"
                {...register("caddressline3", {
                  required: "Address is required",
                  validate: AddressValidation,
                })}
                onInput={AlphabetsandNumbersValidation}
              />
              {errors.caddressline3 && (
                <small className="text-danger">
                  {errors.caddressline3.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="cpincode">
              <InputLabel sx={{fontSize:12}}>
                Pincode<span className="error">*</span>
              </InputLabel>
              <TextField  sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   
              inputProps={{
style:{fontSize:14}
            }}
                type="text"
                placeholder="Enter the pincode"
                {...register("cpincode", {
                  required: "Pincode is required",
                  validate: pincodeValidation,
                })}
                onChange={handlePincodeCorrspondance}
                onInput={NumbersValidation}
              />
              {errors.cpincode && (
                <small className="text-danger">{errors.cpincode.message}</small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="cstateid">
              <InputLabel sx={{fontSize:12}}>
                State<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                // value={getValues("pstateid")}
                aria-label="Default select example"
                {...register("cstateid", {
                  required: "state is required",
                })}
                onChange={(e) => {
                  const stateid = e.target.value;
                  setValue("cstateid", stateid);
                  dispatch({
                    type: masterActions.GET_DISTRICT,
                    payload: stateid,
                  });
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {state?.map((val, i) => (
                  <MenuItem value={val?.stateid} key={i}>
                    {val?.state}
                  </MenuItem>
                ))}
              </Select>
              {errors.cstateid && (
                <small className="text-danger">{errors.cstateid.message}</small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="cdistrictid">
              <InputLabel sx={{fontSize:12}}>
                District<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                aria-label="Default select example"
                value={getValues("cdistrictid")}
                {...register("cdistrictid", {
                  required: "District is required",
                })}
                onChange={(e) => {
                  const districtid = e.target.value;
                  setValue("cdistrictid", districtid);
                  dispatch({
                    type: masterActions.GET_TALUKA,
                    payload: districtid,
                  });
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {district?.map((val, i) => (
                  <MenuItem value={val?.districtid} key={i}>
                    {val?.district}
                  </MenuItem>
                ))}
              </Select>
              {errors.cdistrictid && (
                <small className="text-danger">
                  {errors.cdistrictid.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="ctalukaid">
              <InputLabel sx={{fontSize:12}}>
                Taluka<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                aria-label="Default select example"
                // value={getValues("ctalukaid")}
                {...register("ctalukaid", {
                  required: "Taluka is required",
                })}
                onChange={(e) => {
                  const talukaid = e.target.value;
                  setValue("ctalukaid", talukaid);
                  dispatch({
                    type: masterActions.GET_VILLAGE,
                    payload: talukaid,
                  });
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {taluka?.map((val, i) => (
                  <MenuItem value={val?.talukaid} key={i}>
                    {val?.taluka}
                  </MenuItem>
                ))}
              </Select>
              {errors.ctalukaid && (
                <small className="text-danger">
                  {errors.ctalukaid.message}
                </small>
              )}
            </FormControl>
          </Grid>
                  <Grid item xs={12} md={6}>

            <FormControl   sx={{
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#3F41D1', // Label color when focused
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3F41D1', // Border color when focused
          },fontSize:"44px"
        }}     
   className="mb-3" controlId="cvillageid">
              <InputLabel sx={{fontSize:12}}>
                Village<span className="error">*</span>
              </InputLabel>
              <Select
              sx={{fontSize:14}}
                aria-label="Default select example"
                // value={getValues("cvillageid")}
                {...register("cvillageid", {
                  required: "Village is required",
                })}
                onChange={(e) => {
                  const villageid = e.target.value;
                  setValue("cvillageid", villageid);
                }}
              >
                <MenuItem value="">Select One</MenuItem>
                {village?.map((val, i) => (
                  <MenuItem value={val?.villageid} key={i}>
                    {val?.village}
                  </MenuItem>
                ))}
              </Select>
              {errors.cvillageid && (
                <small className="text-danger">
                  {errors.cvillageid.message}
                </small>
              )}
            </FormControl>
          </Grid>
        </Row> */}
        <Grid
          item
          md={12}
          pb={2}
          pt={2}
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-end",
            flexDirection: isMobile ? "column" : null,
          }}
        >
          {/* {console.log("test-------",addressDetails?.length > 0 && addressDetails[0]?.status === 2)}
          {addressDetails?.length > 0 && addressDetails[0]?.status === 2 && (
         
                <BackButton handleBack={handleBack} />
         
         )}  */}

          {personalDetails?.length > 0 && personalDetails[0]?.status === 2 && (<BackButton handleBack={handleBack} />)}

          {/* <SaveButton
            name={draftButtonLoader ? "loader" : "Save Draft"}
            handleDraft={() => handleDraft(getValues)}
            disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
          /> */}
          <SubmitButton
            name={buttonLoader ? "loader" : "Save & Continue"}
            handleSubmit={handleSubmit(handleSave)}
            disabled={parseInt(FullCandidateDetails[0]?.ispaid) === 1 ? true : false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
