import React from "react";
import { Button } from "@mui/material";

export default function PrimaryButton({ title,onClick,disabled }) {
  return (
    <Button
      sx={{
        borderRadius: "40px",
        backgroundColor:"#3F41D1",
        color:"#fff",
        "&:hover": {
          backgroundColor: "#3F41D1",
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  );
}
