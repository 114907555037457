import React, { useEffect, useState } from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  styled,
  Icon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import candidatesActions from "../../redux/candidates/actions";
import { useDispatch, useSelector } from "react-redux";
import { BILLDESK_URL } from "../../utils/constants";


export default function Tabs(props) {
  
  const navigate = useNavigate();
  const { activeTab, RegistrationData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [success, setSuccess] = useState("");

  
const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  "&.MuiStepLabel-root": {
    cursor: success ==='1' && "pointer"
  },  
  '& .MuiStepLabel-label': {
    color: '#666666', // Unselected stepper color
  },  
  '&.Mui-active .MuiStepLabel-label': {
    color: '#3F4D1A', // Selected stepper color
  },  
  '&.Mui-completed .MuiStepLabel-label': {
    color: '#3F4D1A', // Completed stepper color
  },  
  '& .MuiStepIcon-root': {
    color: '#666666', // Default color for step icon
    '&.Mui-active': {
      color: '#3F4D1A', // Active step icon color
    },  
    '&.Mui-completed': {
      color: '#3F4D1A', // Completed step icon color
    },  
  },  
}));  
  

  
   // function to check billdesk payment status to enable navigation in tabs........................................

   const dispatch = useDispatch();
 
   const { candidateTokenDetails } = useSelector((state) => state.authReducer);
   const { personalDetails } = useSelector((state) => state.candidatesReducer);
   const candidateid = candidateTokenDetails?.candidateid;
 
   useEffect(() => {
     if (candidateid) {
       getTransactions();
       dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
       dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
     }
   }, [candidateid]);
 
   const getTransactions = async () => {
    //  console.log(
    //    "candidate id in payment status ---",
    //    candidateid,
    //    candidateTokenDetails
    //  );
     const response = await axios.post(
       `${BILLDESK_URL}/billdesk/get-transactions`,
       { candidateid: candidateid }
     );
    //  console.log("responce --->", response.data);
     const result = response.data;
     // if (!result.data) {
     //   navigate("/home?page=preview");
     // }
     if (result?.data === "1") {
       setSuccess("1");
       dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
     } else {
       setSuccess("0");
     }
   };
///.........................................................................................END
  
  return (
    <Grid
      sx={{
        height: "auto",
        backgroundColor: "#FFFFFF",
        margin: "10px 0px",
        padding: "15px 15px",
        borderRadius: "8px",
      }}
    >
      <Stepper 
        activeStep={success === "1" ? 5 : activeTab}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
       {RegistrationData.map((item, index) => (
  <Step key={index}>
    <StyledStepLabel
      onClick={() => {
        if (item.title === "Preview" || (item.to && success === '1')) {
          navigate(item.to);
        }
      }}
      className={success === '1' ? 'Mui-completed' : ''}
    >
      {item.title}
    </StyledStepLabel>
  </Step>
))}

      </Stepper>
    </Grid>
  );
}
