import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import authActions from "../../redux/auth/actions";
import commonActions from "../../redux/common/actions";
import { Typography } from "@mui/material";

export default function VerifyEmailRedirection() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        dispatch({ type: commonActions.SET_PAGE_LOADER, payload: true });
        if (token) {
            dispatch({ type: authActions.VERIFY_EMAILID_FOR_SIGNUP, payload: { data: { token: token }, navigate: navigate } })
        }
        //eslint-disable-next-line
    }, [token]);

    return (
        <> </>
        // <Typography variant="h4">Loading...</Typography>
        
    )
};