import React from "react";
import { Button } from "@mui/material";
import { PiArrowLeft } from "react-icons/pi";
import { cssProperties } from "../../utils/commonCssProperties";

export default function BackButton({ handleBack, name }) {
  return (
    <Button 
      varient="outlined"
      onClick={handleBack}
      sx={{
        color: "#A3A3A3",
        height: "40px",
        fontWeight: "bold",
        minWidth: "8rem",
        boxShadow:"none",
        backgroundColor:"#F0F3F7",
        borderRadius: "40px",
        // border: `0.5px solid ${cssProperties?.color?.primary}`,
        padding:"24px",
        "&:hover": {
          backgroundColor: "#F0F3F7",
        },
      }}
    >
      {name ? (
        name
      ) : (
        <>
          <PiArrowLeft style={{ color: "#A3A3A3" }}  size={22} /> &nbsp; Back
        </>
      )}
    </Button>
  );
}
