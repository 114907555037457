import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "../protectedRoute/publicRoute";
import Cookies from 'js-cookie';
import NotFound from "../common/notfound";
import setAuthToken from "../utils/setAuthToken";
import { store } from '../redux/store';
import authActions from "../redux/auth/actions";
import VerifyEmailRedirection from "../common/verification/verifyEmailRedirection";
import VerifiedEmailPage from "../common/verification/verifiedEmailPage";
import Login from "../pages/login";
import { ForgetPassword } from "../pages/forgetPassword";
import Signup from "../pages/signup";
import EmailVerification from "../common/verification/emailVerification";
import EmailVerificationSuccess from "../common/verification/emailVerificationStatus";
import MobileVerificationStatus from "../common/verification/mobileVerificationStatus";
import ForgotPasswordStatus from "../common/verification/forgotpasswordStatus";
import MobileVerification from "../pages/mobileVerification";
import NewPasswordMail from "../pages/newPasswordMail";
import Registration from "../pages/registration";
import PrivateRoute from "../protectedRoute/PrivateRoute";
import BilldeskStatus from "../common/billdeskStatus";
import TermsAndContition from "../pages/termsAndCondition";
import { LandingPage } from "../pages/landingPage";
import PaymentRecipt from "../pages/paymentRecipt";

if (Cookies.get('vsp_cnd_token')) {
  setAuthToken(Cookies.get('vsp_cnd_token'));
  store.dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
};

const AppRoutes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<PublicRoute><LandingPage /></PublicRoute>} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/signup" element={<PublicRoute><Signup /></PublicRoute>} />
        <Route path="/forgot-password" element={<PublicRoute><ForgetPassword /></PublicRoute>} />
        <Route path="/verify-email" element={<PublicRoute><EmailVerification /></PublicRoute>} />
        <Route path="/email-verified-status" element={<PublicRoute><EmailVerificationSuccess /></PublicRoute>} />
        <Route path="/mobileno-verification" element={<PublicRoute><MobileVerificationStatus /></PublicRoute>} />
        <Route path="/new-password" element={<PublicRoute><NewPasswordMail /></PublicRoute>} />
        <Route path="/forgot-password-status" element={<PublicRoute><ForgotPasswordStatus /></PublicRoute>} />
        <Route path="/verify-mobileno" element={<PublicRoute><MobileVerification /></PublicRoute>} />
        <Route path="/email-verification" element={<VerifyEmailRedirection />} />
        <Route path="/terms-and-condition" element={<TermsAndContition />} />
        {/* <Route path="/email-verification" element={<PublicRoute><VerifyEmailRedirection /></PublicRoute>} /> */}
        <Route path="/email-verification-status" element={<PublicRoute><VerifiedEmailPage /></PublicRoute>} />
        <Route path="/billdesk-status" element={<PrivateRoute><BilldeskStatus /></PrivateRoute>} />
        <Route path="/payment-recipt" element={<PrivateRoute><PaymentRecipt /></PrivateRoute>} />
        <Route path="/home" element={<PrivateRoute><Registration /></PrivateRoute>} />
        <Route path="/*" element={<PublicRoute><NotFound /></PublicRoute>} />
      </Routes>
    </>
  );
};

export default AppRoutes;
