import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../App";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import candidatesActions from "../redux/candidates/actions";
import Sidebar from "../layouts/SideBar";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import { Box, Grid, Typography } from "@mui/material";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaHourglassStart } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import PrimaryButton from "../common/button/primaryButton";
import { BILLDESK_URL } from "../utils/constants";

export default function PaymentRecipt() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState("");
  const [referralValue, setReferralValue] = useState("");

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const { personalDetails,FullCandidateDetails } = useSelector((state) => state.candidatesReducer);
  const candidateid = candidateTokenDetails?.candidateid;

// console.log('FullCandidateDetails---',FullCandidateDetails);

  useEffect(() => {
    if (candidateid) {
      getTransactions();
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
      dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
    }
  }, [candidateid]);

  const getTransactions = async () => {
    // console.log(
    //   "candidate id in payment status ---",
    //   candidateid,
    //   candidateTokenDetails
    // );
    const response = await axios.post(
      `${BILLDESK_URL}/billdesk/get-transactions`,
      { candidateid: candidateid }
    );
    // console.log("responce --->", response.data);
    const result = response.data;
    // if (!result.data) {
    //   navigate("/home?page=preview");
    // }
    if (result?.data === "1") {
      setSuccess("1");
      dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
    } else {
      setSuccess("0");
    }
    if(result?.referal){
      setReferralValue(result?.referal)
    }

  };

  return (
    <>
      <Layout>
        <Header />
        <Sidebar />
        <Grid
          sx={{
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 2,
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontWeight: 600,
            }}
          >
            {" "}

            <Box sx={{}}> PAYMENT RECEIPT</Box>

            {success === "1" ? (
              <IoCheckmarkCircleOutline
                size={45}
                style={{ fontSize: 90, color: "#06C270" }}
              />
            ) : success === "0" ? (
              <MdOutlineCancel
                size={45}
                style={{ fontSize: 90, color: "#E6393E" }}
              />
            ) : (
              <FaHourglassStart
                size={45}
                style={{ fontSize: 90, color: "#FFFF00" }}
              />
            )}
            <Typography variant="h6" sx={{ mt: 1, textAlign: "center" }}>
               payment status -{" "}
              {success === "1"
                ? "Success"
                : success === "0"
                ? "Failed"
                : "In Process"}
            </Typography>
            {personalDetails[0]?.applicantid && success !== "0" &&(
              <Typography
                variant="h6"
                pb={1}
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#3F41D1",
                }}
              >
                Your application no - {personalDetails[0]?.applicantid}
              </Typography>
            )}


{(referralValue !== "null" &&  referralValue !== undefined && referralValue != 'invalid') &&(
              <Typography
                variant="h6"
                pb={1}
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#3F41D1",
                }}
              >
                Referral code - {referralValue ? referralValue :"in process"}
              </Typography>
            )}



            {/* {(success === "1" || success === "0") && (
              <PrimaryButton
                title={success === "1" ? "Done" : success === "0" ? "Back" : null}
                onClick={() => {
                  // if (success === "1") {
                    // navigate("/home");
                  // } else if (success === "0") {
                    navigate("/home?page=preview");
                  // }
                }}
              />
            )} */}
          </Box>
        </Grid>
      </Layout>
    </>
  );
}
