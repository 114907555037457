import React from "react";
import Index from "../components/termsAndCondition";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";

export default function TermsAndContition () {
  return(
    <>
    <BeforeLoginHeader />
    <Index />
    </>
  )
}