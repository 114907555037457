import candidatesActions from "./actions";

const initialState = {
  showImage: null,
  showCompressedImage: null,
  showSignature: null,
  showCompressedSignature: null,
  personalDetails: [],
  addressDetails: [],
  categoryDetails: [],
  educationDetails: [],
  examDetails: [],
  certificateDetails: [],
  queryByCanidate: [],
  otherDetails: [],
  uploadDetails: [],
  pincode: [],
  pincodeCorrespondence: [],
  FullCandidateDetails:[],
  referralCode:null,
};

const candidatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case candidatesActions.SHOW_IMAGE:
      return {
        ...state,
        showImage: action.payload,
      };
    case candidatesActions.SHOW_COMPRESSED_IMAGE:
      return {
        ...state,
        showCompressedImage: action.payload,
      };
    case candidatesActions.SHOW_SIGNATURE:
      return {
        ...state,
        showSignature: action.payload,
      };
    case candidatesActions.SHOW_COMPRESSED_SIGNATURE:
      return {
        ...state,
        showCompressedSignature: action.payload,
      };

    case candidatesActions.SET_PROFILE_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: action.payload,
      };
    case candidatesActions.SET_ADDRESS_PERSONAL_DETAILS:
      return {
        ...state,
        addressDetails: action.payload,
      };
    case candidatesActions.SET_CATEGORY_PERSONAL_DETAILS:
      return {
        ...state,
        categoryDetails: action.payload,
      };
    case candidatesActions.SET_EDUCATION_PERSONAL_DETAILS:
      return {
        ...state,
        educationDetails: action.payload,
      };
    case candidatesActions.SET_EXAM_PERSONAL_DETAILS:
      return {
        ...state,
        examDetails: action.payload,
      };
    case candidatesActions.SET_CERTIFICATE_PERSONAL_DETAILS:
      return {
        ...state,
        certificateDetails: action.payload,
      };
    case candidatesActions.SET_QUERY_STATUS_BY_CANDIDATEID:
      return {
        ...state,
        queryByCanidate: action.payload,
      };
    case candidatesActions.SET_OTHER_PERSONAL_DETAILS:
      return {
        ...state,
        otherDetails: action.payload,
      };
    case candidatesActions.SET_UPLOAD_PERSONAL_DETAILS:
      return {
        ...state,
        uploadDetails: action.payload,
      };
    case candidatesActions.SET_PINCODE:
      return {
        ...state,
        pincode: action.payload,
      };
      case candidatesActions.SET_PINCODE_CORRESPONDENCE:
        return {
          ...state,
          pincodeCorrespondence: action.payload,
        };  
      case candidatesActions.SET_FULL_CANDIDATE_DETAILS:
        return {
          ...state,
          FullCandidateDetails: action.payload,
        };  
      case candidatesActions.SET_REFERRAL_CODE:
        return {
          ...state,
          referralCode: action.payload,
        };  
    default:
      return state;
  }
};

export default candidatesReducer;
