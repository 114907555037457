import React from "react";
import { Box, Modal, Typography, List, ListItem, ListItemText, IconButton } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 500,
  bgcolor: 'white',
  padding: '24px',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
};

const contentStyle = {
  overflowY: 'scroll',
  maxHeight: 'calc(100% - 48px)', // Adjusted for close icon
  scrollbarWidth: "thin",
};

const closeIconStyle = {
  
  position: 'absolute',
  top: 2,
  right: 2,
  cursor: 'pointer',
};

export default function TermsAndCond(props) {
  const { handleClose, showModal } = props;

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={closeIconStyle}
        >
          <MdOutlineClose />
        </IconButton>

        <h2 style={{paddingBottom:"30px"}}>
            Terms and Conditions for VIBGYOR Intellect Scholarship Exam Registration
        </h2>
        <div style={contentStyle}>
        
          <h3>1. Eligibility</h3>
          <List>
            <ListItem>
              <ListItemText primary="The VIBGYOR Intellect Scholarship Exam is open to students who meet the eligibility criteria specified on the official website. It is the responsibility of the candidate to ensure that they meet these criteria before registering." />
            </ListItem>
            
          </List>
          
          <h3>2. Registration Process</h3>
          <List>
            <ListItem>
              <ListItemText primary="• All candidates must complete the registration form with accurate and truthful information. Inaccurate or false information may result in disqualification." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Registration is considered complete only after the payment of the registration fee has been successfully processed." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Candidates will receive a confirmation of their registration via email and SMS once payment is completed." />
            </ListItem>
          </List>

          <h3>3. Payment</h3>
          <List>
            <ListItem>
              <ListItemText primary="• A non-refundable registration fee of ₹750 is required to participate in the VIBGYOR Intellect Scholarship Exam." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Payment can be made through the available payment methods on the registration platform. Candidates are responsible for any charges that may apply due to their chosen payment method." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• No refunds will be provided under any circumstances once the payment has been processed." />
            </ListItem>
          </List>

          <h3>4. Exam Credentials</h3>
          <List>
            <ListItem>
              <ListItemText primary="• After successful registration, exam credentials and instructions will be sent to the registered email and phone number provided by the candidate during registration." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Candidates are responsible for ensuring that their contact details are accurate and up-to-date." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The distribution of exam credentials may take some time, and candidates are advised to regularly check their email and SMS for updates." />
            </ListItem>
          </List>

          <h3>5. Exam Conduct</h3>
          <List>
            <ListItem>
              <ListItemText primary="• The exam will be conducted online as per the schedule shared with the candidates. Candidates are expected to adhere to the rules and instructions provided therein and updated from time to time." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Any form of malpractice, including but not limited to cheating, impersonation, or unauthorized sharing of exam content, will result in immediate disqualification." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Candidates are responsible for ensuring a stable internet connection and the required hardware and software setup for the exam. The organizing body will not be responsible for any technical difficulties experienced by the candidate during the exam." />
            </ListItem>
          </List>

          <h3>6. Referral Code Usage</h3>
          <List>
            <ListItem>
              <ListItemText primary="• If a referral code is used during registration, it must be valid and provided by an authorized agent or participant." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Misuse of referral codes, including sharing codes publicly or using unauthorized codes, may result in the registration being voided." />
            </ListItem>
          </List>

          <h3>7. Transportation and Attendance for Final Round</h3>
          <List>
            <ListItem>
              <ListItemText primary="• Transportation: Candidates who are selected for the final round will make their own arrangements for stay and transport to and from the venue. Transportation and accommodation expenses are to be borne by the candidate." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Attendance: Not reporting to the venue on time or not reporting to the venue at all on the dates mentioned for the final round, under any circumstances, will be considered as a ‘No Show.’ In such cases, the candidate’s participation will not be considered, and they will forfeit their chance to compete." />
            </ListItem>
          </List>

          <h3>8. Medium of Examination</h3>
          <List>
            <ListItem>
              <ListItemText primary="• The medium of examination, group discussion (GD), and personal interview (PI) will be in English only. Candidates are expected to have a proficiency in English to participate effectively in all stages of the exam." />
            </ListItem>
            </List>

          <h3>9. Additional Rounds of Assessment</h3>
          <List>
            <ListItem>
              <ListItemText primary="• Due to the nature of the exam, there is a possibility of additional rounds of interaction during the group discussion (GD) and personal interview (PI). Candidates should be prepared for more than one round of assessment at the venue." />
            </ListItem>
            </List>
          
          <h3>10. Disqualification</h3>
          <List>
            <ListItem>
              <ListItemText primary="• The organizing body reserves the right to disqualify any candidate at any stage of the examination process if they are found to be in violation of the terms and conditions or if they engage in any unethical behavior. The decision of the organizing body shall be final and binding on all concerned." />
            </ListItem>
            </List>
          

          <h3>11. Data Protection</h3>
          <List>
            <ListItem>
              <ListItemText primary="• By registering for the exam, you agree to the collection, use, and processing of your personal data by the organizing body for the purpose of administering the exam." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Your data will be handled in accordance with the privacy policy outlined on the official website." />
            </ListItem>
          </List>

          <h3>12. Amendments</h3>
          <List>
            <ListItem>
              <ListItemText primary="• The organizing body reserves the right to amend or update these terms and conditions at any time without prior notice. Candidates are encouraged to review the terms and conditions periodically." />
            </ListItem>
            </List>
          <h3>13. Limitation of Liability</h3>
          <List>
            <ListItem>
              <ListItemText primary="• The organizing body shall not be liable for any direct, indirect, incidental, or consequential damages arising from participation in the VIBGYOR Intellect Scholarship Exam." />
            </ListItem>
            <ListItem>
              <ListItemText primary="• The organizing body is not responsible for any technical failures or disruptions that may affect the exam process." />
            </ListItem>
          </List>

          <h3>14. Governing Law</h3>
          <List>
            <ListItem>
              <ListItemText primary="• These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Mumbai, Maharashtra." />
            </ListItem>
            </List>
        </div>
      </Box>
    </Modal>
  );
}
