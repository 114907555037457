const   candidatesActions = {
    SHOW_IMAGE:'SHOW_IMAGE',
    SHOW_COMPRESSED_IMAGE :'SHOW_COMPRESSED_IMAGE',
    SHOW_SIGNATURE:'SHOW_SIGNATURE',
    SHOW_COMPRESSED_SIGNATURE:'SHOW_COMPRESSED_SIGNATURE',
    PROFILE_PERSONAL_DETAILS_CREATION: 'PROFILE_PERSONAL_DETAILS_CREATION',
    GET_PROFILE_PERSONAL_DETAILS : 'GET_PROFILE_PERSONAL_DETAILS',
    SET_PROFILE_PERSONAL_DETAILS : 'SET_PROFILE_PERSONAL_DETAILS',
    PROFILE_ADDRESS_DETAILS_CREATION: 'PROFILE_ADDRESS_DETAILS_CREATION',
    PROFILE_CATEGORY_DETAILS_CREATION: 'PROFILE_CATEGORY_DETAILS_CREATION',
    PROFILE_EDUCATION_DETAILS_CREATION: 'PROFILE_EDUCATION_DETAILS_CREATION',
    PROFILE_EXAM_DETAILS_CREATION: 'PROFILE_EXAM_DETAILS_CREATION',
    PROFILE_CERTIFICATES_DETAILS_CREATION: 'PROFILE_CERTIFICATES_DETAILS_CREATION',
    PROFILE_UPLOADS_DETAILS_CREATION: 'PROFILE_UPLOADS_DETAILS_CREATION',
    PROFILE_UPLOADS_DETAILS_DRAFT_CREATION: 'PROFILE_UPLOADS_DETAILS_DRAFT_CREATION',
    GET_ADDRESS_PERSONAL_DETAILS : 'GET_ADDRESS_PERSONAL_DETAILS',
    SET_ADDRESS_PERSONAL_DETAILS : 'SET_ADDRESS_PERSONAL_DETAILS',
    GET_CATEGORY_PERSONAL_DETAILS : 'GET_CATEGORY_PERSONAL_DETAILS',
    SET_CATEGORY_PERSONAL_DETAILS : 'SET_CATEGORY_PERSONAL_DETAILS',
    GET_EDUCATION_PERSONAL_DETAILS : 'GET_EDUCATION_PERSONAL_DETAILS',
    SET_EDUCATION_PERSONAL_DETAILS : 'SET_EDUCATION_PERSONAL_DETAILS',
    GET_EXAM_PERSONAL_DETAILS : 'GET_EXAM_PERSONAL_DETAILS',
    SET_EXAM_PERSONAL_DETAILS : 'SET_EXAM_PERSONAL_DETAILS',
    GET_CERTIFICATE_PERSONAL_DETAILS : 'GET_CERTIFICATE_PERSONAL_DETAILS',
    SET_CERTIFICATE_PERSONAL_DETAILS : 'SET_CERTIFICATE_PERSONAL_DETAILS',
    PROFILE_PERSONAL_DETAILS_DRAFT_CREATION: 'PROFILE_PERSONAL_DETAILS_DRAFT_CREATION',
    PROFILE_ADDRESS_DETAILS_DRAFT_CREATION: 'PROFILE_ADDRESS_DETAILS_DRAFT_CREATION',
    PROFILE_CATEGORY_DETAILS_DRAFT_CREATION: 'PROFILE_CATEGORY_DETAILS_DRAFT_CREATION',
    PROFILE_EDUCATION_DETAILS_DRAFT_CREATION: 'PROFILE_EDUCATION_DETAILS_DRAFT_CREATION',
    PROFILE_EXAM_DETAILS_DRAFT_CREATION: 'PROFILE_EXAM_DETAILS_DRAFT_CREATION',
    PROFILE_CERTIFICATE_DETAILS_DRAFT_CREATION: 'PROFILE_CERTIFICATE_DETAILS_DRAFT_CREATION',
    GRIEVANCE_QUERY_CREATION : 'GRIEVANCE_QUERY_CREATION',
    GET_QUERY_STATUS_BY_CANDIDATEID : 'GET_QUERY_STATUS_BY_CANDIDATEID',
    SET_QUERY_STATUS_BY_CANDIDATEID : 'SET_QUERY_STATUS_BY_CANDIDATEID',
    GET_OTHER_PERSONAL_DETAILS : 'GET_OTHER_PERSONAL_DETAILS',
    SET_OTHER_PERSONAL_DETAILS : 'SET_OTHER_PERSONAL_DETAILS',
    PROFILE_OTHER_DETAILS_DRAFT_CREATION: 'PROFILE_OTHER_DETAILS_DRAFT_CREATION',
    PROFILE_OTHER_DETAILS_CREATION: 'PROFILE_OTHER_DETAILS_CREATION',
    GET_UPLOAD_PERSONAL_DETAILS : 'GET_UPLOAD_PERSONAL_DETAILS',
    SET_UPLOAD_PERSONAL_DETAILS : 'SET_UPLOAD_PERSONAL_DETAILS',
    SET_PINCODE:'SET_PINCODE',
    GET_PINCODE :'GET_PINCODE',
    SET_PINCODE_CORRESPONDENCE:'SET_PINCODE_CORRESPONDENCE',
    GET_PINCODE_CORRESPONDENCE :'GET_PINCODE_CORRESPONDENCE',
    GET_FULL_CANDIDATE_DETAILS:'GET_FULL_CANDIDATE_DETAILS',
    SET_FULL_CANDIDATE_DETAILS:'SET_FULL_CANDIDATE_DETAILS',
    GET_REFERRAL_CODE : 'GET_REFERRAL_CODE',
    SET_REFERRAL_CODE : 'SET_REFERRAL_CODE',
    REMOVE_REFERRAL_CODE : 'REMOVE_REFERRAL_CODE',

};

export default candidatesActions;